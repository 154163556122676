import { API, Plans, PlansDiscountedAmount } from '@/constants/global-config';
import { FETCH_RENDERING_CONFIG_SUCCESS, FETCH_RENDERING_CONFIG_NOT_LOGIN, FETCH_RENDERING_CONFIG_FAILURE, SET_BLACK_FRIDAY_BAR_RENDERING_CONFIG } from '@/redux/actions/renderingConfigActions';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: RenderingConfigSliceDataType = {
  loading: true,
  error: false,
  userType: 'not_login',
  buttons: {
    indexTopBarBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
    indexHeaderBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
    indexPlansBtn: { text_home: 'TRY NOW', text_internal: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL + '?plan=' },
    indexReviewBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
    indexVideoBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
    indexCommunityBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
    tryNowBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
  },
  topBarDropdown: {
    account: false,
    orders: false,
    fedex: false,
    fav: false,
    credits: false,
    rewards: false,
    mycode: false,
    profile: true,
    logout: true,
  },
  show: {
    tanBar: false,
    topBar: true,
    favBtn: false,
    searchBar: true,
    navBar: true,
    indexHeader: true,
    indexHeaderImage: false,
    indexAsSeenOn: true,
    indexBrands: true,
    indexRentBox: true,
    indexClothingSlider: true,
    indexHIW: true,
    benifits: true,
    plans: true,
    googleReviews: true,
    indexCommunityImage: true,
    indexFAQs: true,
    indexInsta: true,
    blackfridayBar: false,

    indexRentOccasion: true,
    indexMemberLoved: true,
    indexNewArrival: true,
    indexMemberQoutes: true,
    indexMemberMeetup: true,
  },
};

export default function renderingConfigData(state: RenderingConfigSliceDataType = initialState, action: AnyAction): RenderingConfigSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.renderingConfig };
    case SET_BLACK_FRIDAY_BAR_RENDERING_CONFIG:
      return { ...state, show: { ...state.show, blackfridayBar: action.payload } };
    case FETCH_RENDERING_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        userType: action.payload.userType,
        show: action.payload.show,
        buttons: action.payload.buttons,
        topBarDropdown: action.payload.topBarDropdown,
      };
    case FETCH_RENDERING_CONFIG_NOT_LOGIN:
      return {
        ...state,
        loading: false,
        userType: 'not_login',
        show: {
          tanBar: true,
          topBar: true,
          favBtn: false,
          searchBar: true,
          navBar: true,
          indexHeader: true,
          indexHeaderImage: false,
          indexAsSeenOn: true,
          indexBrands: true,
          indexRentBox: true,
          indexClothingSlider: true,
          indexHIW: true,
          benifits: true,
          plans: true,
          googleReviews: true,
          indexCommunityImage: true,
          indexFAQs: true,
          indexInsta: true,
          blackfridayBar: false,

          indexRentOccasion: true,
          indexMemberLoved: true,
          indexNewArrival: true,
          indexMemberQoutes: true,
          indexMemberMeetup: true,
        },
        buttons: {
          indexTopBarBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
          indexHeaderBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
          indexPlansBtn: { text_home: 'TRY NOW', text_internal: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL + '?plan=' },
          indexReviewBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
          indexVideoBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
          indexCommunityBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
          tryNowBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
        },
        topBarDropdown: {
          account: false,
          orders: false,
          fedex: false,
          fav: false,
          credits: false,
          rewards: false,
          mycode: false,
          profile: true,
          logout: true,
        },
      };
    case FETCH_RENDERING_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        userType: action.payload.userType,
        show: {
          tanBar: true,
          topBar: true,
          favBtn: false,
          searchBar: true,
          navBar: true,
          indexHeader: true,
          indexHeaderImage: false,
          indexAsSeenOn: true,
          indexBrands: true,
          indexRentBox: true,
          indexClothingSlider: true,
          indexHIW: true,
          benifits: true,
          plans: true,
          googleReviews: true,
          indexCommunityImage: true,
          indexFAQs: true,
          indexInsta: true,
          blackfridayBar: false,

          indexRentOccasion: true,
          indexMemberLoved: true,
          indexNewArrival: true,
          indexMemberQoutes: true,
          indexMemberMeetup: true,
        },
        buttons: {
          indexTopBarBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
          indexHeaderBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
          indexPlansBtn: { text_home: 'TRY NOW', text_internal: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL + '?plan=' },
          indexReviewBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
          indexVideoBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
          indexCommunityBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
          tryNowBtn: { text: `TRY FOR $` + (Plans.PLAN_01.price - PlansDiscountedAmount), link: API.BASE_URL_WAITLIST_URL },
        },
        topBarDropdown: {
          account: false,
          orders: false,
          fedex: false,
          fav: false,
          credits: false,
          rewards: false,
          mycode: false,
          profile: true,
          logout: true,
        },
      };
    default:
      return state;
  }
}

export type RenderingConfigSlice_UserType = 'not_login' | 'subcribed_user' | 'unsubscribed_user' | 'two_step_user' | 'paused_user' | 'return_paused_user';
export type RenderingConfigSliceDataType = {
  loading: boolean;
  error: boolean;
  userType: RenderingConfigSlice_UserType;
  buttons: {
    indexTopBarBtn: { text: string; link: string | undefined };
    indexHeaderBtn: { text: string; link: string | undefined };
    indexPlansBtn: { text_home: string; text_internal: string; link: string | undefined };
    indexReviewBtn: { text: string; link: string | undefined };
    indexVideoBtn: { text: string; link: string | undefined };
    indexCommunityBtn: { text: string; link: string | undefined };
    tryNowBtn: { text: string; link: string | undefined };
  };
  topBarDropdown: {
    account: boolean;
    orders: boolean;
    fedex: boolean;
    fav: boolean;
    credits: boolean;
    rewards: boolean;
    mycode: boolean;
    profile: boolean;
    logout: boolean;
    hiw: boolean;
  };
  show: {
    tanBar: boolean;
    topBar: boolean;
    favBtn: boolean;
    searchBar: boolean;
    navBar: boolean;
    indexHeader: boolean;
    indexHeaderImage: boolean;
    indexAsSeenOn: boolean;
    indexBrands: boolean;
    indexRentBox: boolean;
    indexClothingSlider: boolean;
    indexHIW: boolean;
    benifits: boolean;
    plans: boolean;
    googleReviews: boolean;
    indexCommunityImage: boolean;
    indexFAQs: boolean;
    indexInsta: boolean;
    blackfridayBar: boolean;
    indexRentOccasion: boolean;
    indexMemberLoved: boolean;
    indexNewArrival: boolean;
    indexMemberQoutes: boolean;
    indexMemberMeetup: boolean;
  };
};
