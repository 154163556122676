import { FETCH_PLAN_SUCCESS, FETCH_PLAN_FAILURE, FETCH_PLAN_STARTED, FETCH_PLAN_ENDED } from '@/redux/actions/plansActions';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: PlansSliceDataType = {
  plans: [],
  loading: true,
  error: false,
};

export default function plansData(state: PlansSliceDataType = initialState, action: AnyAction): PlansSliceDataType {
  switch (action.type) {
    case HYDRATE:
      if (action.payload.currentPlans.plans.length == initialState.plans.length && action.payload.currentPlans.loading == initialState.loading) return { ...state };
      else return { ...state, ...action.payload.currentPlans };
    case FETCH_PLAN_STARTED:
      return { ...state, loading: true };
    case FETCH_PLAN_ENDED:
      return { ...state, loading: false };
    case FETCH_PLAN_SUCCESS:
      return { ...state, plans: action.payload, loading: false };
    case FETCH_PLAN_FAILURE:
      return { ...state, loading: false, plans: action.payload, error: true };
    default:
      return state;
  }
}

export type PlansSliceDataType = {
  loading: boolean;
  error: boolean;
  plans: PlansSlice_PlansDataType[];
};
export type PlansSlice_PlansDataType = {
  planWebName: string;
  items: string;
  price: string;
  firstMonthDiscount: string;
  itemSwapRatio: string;
  earlyCheckin: string;
  swapItems: string;
  monthlyPurchaseCredit: string;
  planName: string;
  stripeName: string;
  clothing: string;
  accessory: string;
  planStripeName: string;
  planStripeId: string;
  planStripePrice: string;
  planStripeIdNew: string;
  planStripePriceNew: string;
};
