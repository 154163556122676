import { ProductReviewSliceDataType } from '@/redux/reducers/productReviewReducer';

export const FETCH_PRODUCT_REVIEWS = 'FETCH_PRODUCT_REVIEWS';
export const FETCH_PRODUCT_REVIEWS_SUCCESS = 'FETCH_PRODUCT_REVIEWS_SUCCESS';

export const fetchProductReviews = () => ({
  type: FETCH_PRODUCT_REVIEWS,
});

export const fetchProductReviewsSucess = (_reviews: ProductReviewSliceDataType['reviews'], _page: ProductReviewSliceDataType['pages'], _productID: ProductReviewSliceDataType['productID']) => ({
  type: FETCH_PRODUCT_REVIEWS_SUCCESS,
  payload: { reviews: _reviews, page: _page, productID: _productID },
});
