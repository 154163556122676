import jsCookie from 'js-cookie';
import fetch from 'isomorphic-unfetch';
import { getAuthTokenfromcookie } from '@/functions/auth-token';

import getCanvasWebgl, { webglHTML } from "webGL/webgl/index"
import getWebRTCData from 'webGL/webrtc/index'
import getTimezone from 'webGL/timezone/index'
import getOfflineAudioContext from 'webGL/audio/index'
import { getBestWorkerScope } from 'webGL/worker/index'
import { hashify, hashMini} from 'webGL/utils/crypto'
import getFonts from 'webGL/fonts/index'
import getNavigator from 'webGL/navigator/index'
import getPrediction from 'webGL/prediction/index'
import  { getSamples } from 'webGL/samples/index'
import getWindowFeatures from 'webGL/window/index'
import { IS_BLINK , getBraveUnprotectedParameters, getBraveMode, braveBrowser, hashSlice } from 'webGL/utils/helpers'
import Gconfig from "globalconfig"

function sentWebGLHash(obj) {
    let customer_token = getAuthTokenfromcookie();
    return fetch(Gconfig.fpAPI + `Customer/AddCustomerFingerprint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${customer_token}`
        },
        body: JSON.stringify(obj)
      }).then(response => response.json()).then(res => {
        if(res.success) return true;
        else return false;
      }).catch((error) => {
        console.log('AddCustomerFingerprint err', error)
        return false;
    })
}

const getBestGPUModel = ({ canvasWebgl, workerScope }) => {
    const gpuHasGoodConfidence = (data) => {
        return (
            (data.gpu || {}).confidence &&
            (data.gpu.confidence != 'low')
        )
    }
    if (!canvasWebgl || canvasWebgl.parameterOrExtensionLie) {
        return 'undefined'
    } else if (workerScope && gpuHasGoodConfidence(workerScope)) {
        return workerScope.webglRenderer
    } else if (canvasWebgl && !canvasWebgl.parameterOrExtensionLie && gpuHasGoodConfidence(canvasWebgl)) {
        return ''+((canvasWebgl.parameters || {}).UNMASKED_RENDERER_WEBGL)
    }
    return 'undefined'
}

async function sentWebGLDataofUser(cusdata) {


  let  _country_region_name = "";
  let _city = "";
  let _postal_code = "";
  let _metro_code = "";
  let _latitude = "";
  let _longitude = "";
  let _address = "";
  let _asn = ""

  setTimeout(async() => {
    let allHeader = sessionStorage.getItem('web_headers')
    if(allHeader == undefined || allHeader == null || allHeader == 'undefined' || allHeader == 'null') return
    let headerObjj  = JSON.parse(allHeader)
   
    _country_region_name = headerObjj['cloudfront-viewer-country-region-name']  ?  headerObjj['cloudfront-viewer-country-region-name'] : "";
    _city = headerObjj['cloudfront-viewer-city'] ? headerObjj['cloudfront-viewer-city']  : "";
    _postal_code = headerObjj['cloudfront-viewer-postal-code'] ? headerObjj['cloudfront-viewer-postal-code']  : "";
    _metro_code = headerObjj['cloudfront-viewer-metro-code'] ? headerObjj['cloudfront-viewer-metro-code'] : "";
    _latitude = headerObjj['cloudfront-viewer-latitude'] ? headerObjj['cloudfront-viewer-latitude']  : "";
    _longitude = headerObjj['cloudfront-viewer-longitude'] ? headerObjj['cloudfront-viewer-longitude'] : "";
    _address = headerObjj['cloudfront-viewer-address'] ? headerObjj['cloudfront-viewer-address'] : "";
    _asn = headerObjj['cloudfront-viewer-asn'] ? headerObjj['cloudfront-viewer-asn'] : "";
   
    try {

      // ===============================  WebGL ========================== 
      const canvasWebglComputed = await getCanvasWebgl();
  
      const isBrave = IS_BLINK ? await braveBrowser() : false
      const braveMode = isBrave ? getBraveMode() : {}
      const braveFingerprintingBlocking = isBrave && (braveMode.standard || braveMode.strict)
  
      const { parameters: gpuParameter } = canvasWebglComputed || {}
      const reducedGPUParameters = {
        ...(
          braveFingerprintingBlocking ? getBraveUnprotectedParameters(gpuParameter) :
          gpuParameter
        ),
        RENDERER: undefined,
        SHADING_LANGUAGE_VERSION : undefined,
        UNMASKED_RENDERER_WEBGL : undefined,
        UNMASKED_VENDOR_WEBGL : undefined,
        VERSION : undefined,
        VENDOR : undefined,
      }
        
      const [ canvasWebglHash, canvasWebglImageHash, canvasWebglParametersHash, pixelsHash, pixels2Hash ] = await Promise.all([
        hashify(canvasWebglComputed),
        hashify((canvasWebglComputed || {}).dataURI),
        hashify(reducedGPUParameters),
        ((canvasWebglComputed || {}).pixels || []).length ? hashify(canvasWebglComputed.pixels) : undefined,
        ((canvasWebglComputed || {}).pixels2 || []).length ? hashify(canvasWebglComputed.pixels2) : undefined,
      ])
  
      const fingerprint = {
        canvasWebgl: !canvasWebglComputed ? undefined : {...canvasWebglComputed, pixels: pixelsHash, pixels2: pixels2Hash, $hash: canvasWebglHash}
      }
      const { canvasWebgl: data } = fingerprint;
      const { $hash, dataURI, dataURI2, pixels, pixels2, lied, extensions, parameters, gpu } = data || {}
      const { parts, warnings, gibbers, confidence, grade: confidenceGrade, compressedGPU } = gpu || {}
      //   const paramKeys = parameters ? Object.keys(parameters).sort() : []
      //   const extensionkey = parameters ? Object.keys(parameters).sort() : []
  
      let WebGLHash = $hash ? hashSlice($hash) : ""
      let imagesHash = dataURI ? (hashMini(dataURI) + (dataURI2 && dataURI != dataURI2 ? hashMini(dataURI2): "")) : ""
      let pixelHash = pixels ? (hashSlice(pixels) + (pixels2 && pixels != pixels2 ? hashSlice(pixels2): "")) : ""
      let paramHash = parameters ? hashMini(parameters) : ""
      let extensionHash = extensions ? hashMini(extensions) : ""


      // ===================================  WebRTC SDP Capabilites ===============================
      var sdpCapabilites = ""
      let webRTCdata = await Promise.all([ getWebRTCData() ])
      const [ webRTC ] = webRTCdata || []
      const { codecsSdp } = webRTC || {}
      const { audio, video } = codecsSdp || {}
      if(audio && video && extensions ) {
          sdpCapabilites = hashMini({ audio, video, extensions })
      }
      
     
      // ===================================  Timzone ===============================================
      var timezoneHash = ""
      const [ timezoneComputed ] = await Promise.all([ getTimezone() ])
      const [ timezoneHashify ] = await Promise.all([ hashify(timezoneComputed) ])
      if(timezoneHashify) {
          timezoneHash = hashSlice(timezoneHashify)
      }

      // ================================== Fonts Hash ==============================================
      var fontsHash = ""
      const [ fontsComputed ] = await Promise.all([ getFonts() ])
      const [ fontsHashify ] = await Promise.all([ hashify(fontsComputed) ])
      if(fontsHashify) {
          fontsHash = hashSlice(fontsHashify)
      }
     

      // ================================== Audio Hash ==============================================
      var audioHash = ""
      const [ offlineAudioContextComputed ] = await Promise.all([ getOfflineAudioContext() ])
      const [ audioHashify ] = await Promise.all([ hashify(offlineAudioContextComputed) ])
      if(audioHashify) {
          audioHash = hashSlice(audioHashify)
      }
     
      

     // ================================== Navigator Device info ==============================================
     let fromApp = jsCookie.get('fromMobileApp')
     let browserVersion = jsCookie.get('browserversion')
     let isHardwareInfoGet = true
      console.log('info --------->>>>>>',''+browserVersion)
     if(fromApp != undefined && fromApp && browserVersion != undefined && ''+browserVersion.indexOf('16.1') > -1) {
        isHardwareInfoGet = false
     }

     let hardwareInfo = ""
     var gpuModel = ""
     if(isHardwareInfoGet == true) {
        const [ workerScopeComputed ] = await Promise.all([ getBestWorkerScope() ]).catch((error) => console.error(error.message))
        const [ workerScopeHashify ] = await Promise.all([ hashify(workerScopeComputed) ])
        const navigatorComputed = await getNavigator(workerScopeComputed).catch((error) => console.error(error.message))
        fingerprint.workerScope = !workerScopeComputed ? undefined : { ...workerScopeComputed, $hash: workerScopeHashify}
        const {
            bluetoothAvailability,
            device,
            deviceMemory,
            hardwareConcurrency,
            maxTouchPoints,
            oscpu,
            system,
            userAgentData,
        } = navigatorComputed || {}
        let cores = hardwareConcurrency ? hardwareConcurrency : "";
        let ram = deviceMemory ? deviceMemory : ""
        let touch = typeof maxTouchPoints != 'undefined' ? maxTouchPoints : ""
        let bluetooth = bluetoothAvailability ? "bluetooth" : ""
         hardwareInfo = `cores:${cores} , ram:${ram}, touch:${touch}, bluetooth:${bluetooth}`
    
    
        // ================================== Prediction Device info ==============================================

        gpuModel = getBestGPUModel({ canvasWebgl: fingerprint.canvasWebgl, workerScope: fingerprint.workerScope })
        
        // console.log('gpu', gpuModel)
        // console.log('window', fingerprint.workerScope?.device)
        // console.log('fingerprint.workerScope', fingerprint.workerScope)

        // const [ windowFeaturesComputed ] = await Promise.all([ getWindowFeatures() ])
        // const [ windowHashify ] = await Promise.all([ hashify(windowFeaturesComputed) ])
        // let windowFeatures = !windowFeaturesComputed ? undefined : {...windowFeaturesComputed, $hash: windowHashify}
        // var {samples} = await getSamples() || {}
        // const cleanGPUString = (x) => !x ? x : (''+x).replace(/\//g, '')
        // var { window: winSamples, gpuModel: gpuModelSamples } = samples || {}
        // var  gpuModelSystem = await getPrediction({ hash: cleanGPUString(gpuModel), data: samples.gpuModel})
        // var windowVersion = await getPrediction({ hash: (windowFeatures || {}).$hash, data: samples.window})
        
        // console.log('gpuModelSystem', gpuModelSystem)
        // console.log('windowVersion', windowVersion)

        // console.log('decryptionSamples', samples)
        // console.log('winSamples', window)
        // console.log('windowFeatures', windowFeatures)
     }

      let customerId = cusdata?.customer_id
      let customerEmail = cusdata?.email
      

       const obj = {
        "CustId" :  customerId,
        "Email" : customerEmail,
        "WebGL" : WebGLHash,
        "Images" : imagesHash,
        "Pixels" : pixelHash,
        "Params" : paramHash,
        "Exts" : extensionHash,
        "SdpCapabilities": sdpCapabilites,
        "Timezone": timezoneHash ,
        "Fonts": fontsHash,
        "Audio": audioHash,
        "Os": fingerprint.workerScope ? fingerprint.workerScope?.device : "",
        "DeviceProps": hardwareInfo,
        "Gpu": gpuModel,

        "country_region_name": _country_region_name,
        "city": _city,
        "postal_code": _postal_code,
        "metro_code": _metro_code,
        "latitude": _latitude,
        "longitude": _longitude,
        "address": _address,
        "asn": _asn
      }
    

      console.log('obj ====> ', obj)

      if((WebGLHash == "" && imagesHash == "" && pixelHash == "" && extensionHash == "") || (WebGLHash == undefined && imagesHash == undefined && pixelHash == undefined && extensionHash == undefined)) {
        let consoleString = "WebGLHash====="+WebGLHash+">>>>>"+"imagesHash====="+imagesHash+">>>>>"+"pixelHash====="+pixelHash+">>>>>"+"extensionHash====="+extensionHash+">>>>>"
        console.log(consoleString)
        return
      }
      else {
        let res = await sentWebGLHash(obj)
        if(res == true) {
          console.log('WebGL Sent ==============>>>')
          jsCookie.set('isWebGLhashSubmitted', 'yes')
        }else{
          jsCookie.set('isWebGLhashSubmitted', 'yes')
        }
      }
  
    //   let res = await sentWebGLHash(obj)
    //   if(res == true) {
    //     console.log('WebGL Sent ==============>>>')
    //     jsCookie.set('isWebGLhashSubmitted', 'yes')
    //   }
    }
    catch(e) {
      console.log('error', e)
      jsCookie.set('isWebGLhashSubmitted', 'yes')
    }


  },2000)
  // return
  
   
  }

  export {
    sentWebGLDataofUser
  }