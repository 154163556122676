import { AxiosGet, GenericFetchGet } from '@/libraries/request';
import { BASE_URL_FP_API, configDataType } from '@/services/config';
import { GetAddRecentProduct_ResponseType, GetAllBrands_ResponseType, GetMultipleProductsInfo_ResponseType, GetProductInfo_ResponseType, GetRecentProducts_ResponseType } from '@/services/product/types';

const setErrorMessage = (message: string) => ({ title: 'Product Service', message: message });

// API's
const GetMultipleProductsInfo_api = (product_ids: number[]) => `${BASE_URL_FP_API}Product/GetProductsInfo/${product_ids.join(',')}`;
const GetProductInfo_api = (productID: string) => `${BASE_URL_FP_API}Product/GetProductInfo/${productID}`;
const GetRecentProducts_api = (customer_id: string) => `${BASE_URL_FP_API}Product/GetRecentProducts/customer/${customer_id}`;
const AddRecentProduct_api = (product_id: number, customer_id: number) => `${BASE_URL_FP_API}Product/AddRecentProduct/product/${product_id}/customer/${customer_id}`;
const GetAllBrands_api = (configData) =>{ 
    if(configData.customerId){
        return `${BASE_URL_FP_API}Product/GetAllBrands?sortByName=true&customerId=${configData?.customerId}`
    }else{
        return `${BASE_URL_FP_API}Product/GetAllBrands?sortByName=true`
    }
}
    // return `${BASE_URL_FP_API}Product/GetAllBrands?sortByName=true`}

// Methods
export const getMultipleProductsInfo = (product_ids: number[], configData: configDataType) => AxiosGet<GetMultipleProductsInfo_ResponseType>(GetMultipleProductsInfo_api(product_ids), configData, setErrorMessage('getMultipleProductsInfo Method'));
export const getProductInfo = (productID: string, configData: configDataType) => AxiosGet<GetProductInfo_ResponseType>(GetProductInfo_api(productID), configData, setErrorMessage('getProductInfo Method'));
export const getRecentProducts = (customer_id: string, configData: configDataType) => AxiosGet<GetRecentProducts_ResponseType>(GetRecentProducts_api(customer_id), configData, setErrorMessage('getRecentProducts Method'));
export const getAddRecentProduct = (product_id: number, customer_id: number, configData: configDataType) => AxiosGet<GetAddRecentProduct_ResponseType>(AddRecentProduct_api(product_id, customer_id), configData, setErrorMessage('getAddRecentProduct Method'));
export const getAllBrands = (configData: configDataType) => AxiosGet<GetAllBrands_ResponseType>(GetAllBrands_api(configData), configData, setErrorMessage('getAllBrands Method'));
