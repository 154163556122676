import rootReducer from '@/redux/reducers/rootReducers';
import { createStore, applyMiddleware, AnyAction, Store as ReduxStore } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import logger from 'redux-logger';
import { createWrapper, Context, HYDRATE } from 'next-redux-wrapper';

const makeStore = (context: Context) => createStore(rootReducer, applyMiddleware(thunk));

// export type Store = ReturnType<typeof makeStore>;
// export type StoreState = ReturnType<Store['getState']>;
// export type StoreDispatch = Store['dispatch'];

export type Store = Omit<ReduxStore<StoreState, AnyAction>, 'dispatch'> & { dispatch: StoreDispatch };
export type StoreState = ReturnType<typeof rootReducer>;
export type StoreDispatch = ThunkDispatch<StoreState, any, AnyAction>;
export type StoreThunk<ReturnType = void> = ThunkAction<ReturnType, StoreState, unknown, AnyAction>;

const wrapper = createWrapper<Store>(makeStore, { debug: false });
export default wrapper;
