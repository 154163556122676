import { FETCH_CUSTOMER_ADDRESSES_SUCCESS, FETCH_CUSTOMER_ADDRESSES_FAILURE, CUSTOMER_ADDRESSES_MARK_LOADING, CUSTOMER_ADDRESSES_MARK_LOADING_DONE } from '@/redux/actions/customerActions';
import { HYDRATE } from 'next-redux-wrapper';
import { AddressDataType } from '@/services/customer';
import { AnyAction } from 'redux';

const initialState: CustomerAddressesSliceDataType = {
  addresses: [],
  loading: false,
  error: false,
};

export default function customerAddresses(state: CustomerAddressesSliceDataType = initialState, action: AnyAction): CustomerAddressesSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.customerAddresses };
    case CUSTOMER_ADDRESSES_MARK_LOADING:
      return { ...state, loading: true };
    case CUSTOMER_ADDRESSES_MARK_LOADING_DONE:
      return { ...state, loading: false };
    case FETCH_CUSTOMER_ADDRESSES_SUCCESS:
      return { ...state, loading: false, addresses: action.payload };
    case FETCH_CUSTOMER_ADDRESSES_FAILURE:
      return { ...state, loading: false, addresses: [], error: true };
    default:
      return state;
  }
}

export type CustomerAddressesSliceDataType = {
  loading: boolean;
  error: boolean;
  addresses: AddressDataType[];
};
