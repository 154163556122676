import { CategoriesNamespace, CategorySliceDataType } from '@/redux/reducers/categoryReducer';

export const FETCH_SUBCATEGORIES_SUCCESS = 'FETCH_SUBCATEGORIES_SUCCESS';
export const FETCH_SUBCATEGORIES_STARTED = 'FETCH_SUBCATEGORIES_STARTED';
export const FETCH_SUBCATEGORIES_FAILD = 'FETCH_SUBCATEGORIES_FAILD';
export const FETCH_MAINCAT_SUCCESS = 'FETCH_MAINCAT_SUCCESS';
export const FETCH_MAINCAT_STARTED = 'FETCH_MAINCAT_STARTED';
export const LOAD_ALL_COMPLETED = 'LOAD_ALL_COMPLETED';
export const CLEARERROR = 'CLEARERROR';

export const fetchSubcategoriesStarted = (namespace: CategoriesNamespace, currentcat: CategorySliceDataType['currentCat']) => ({
  type: namespace + '-' + FETCH_SUBCATEGORIES_STARTED,
  payload: currentcat,
});

export const fetchSubcategoriesSuccess = (namespace: CategoriesNamespace, currentSubCat: CategorySliceDataType['currentSubCats']) => ({
  type: namespace + '-' + FETCH_SUBCATEGORIES_SUCCESS,
  payload: currentSubCat,
});

export const fetchSubcategoriesfaild = (namespace: CategoriesNamespace, catFatchError: CategorySliceDataType['error']) => ({
  type: namespace + '-' + FETCH_SUBCATEGORIES_FAILD,
  payload: catFatchError,
});

export const clearErrot = (namespace: CategoriesNamespace) => ({
  type: namespace + '-' + CLEARERROR,
});

export const fetchMainCatStarted = (namespace: CategoriesNamespace) => ({
  type: namespace + '-' + FETCH_MAINCAT_STARTED,
});

export const fetchMainCatSuccess = (namespace: CategoriesNamespace, mainCat: CategorySliceDataType['currentCat']) => ({
  type: namespace + '-' + FETCH_MAINCAT_SUCCESS,
  payload: mainCat,
});

export const loadAllCompleted = (namespace: CategoriesNamespace) => ({
  type: namespace + '-' + LOAD_ALL_COMPLETED,
});
