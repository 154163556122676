export const isValueEmpty = (val: any) => Boolean(val === undefined || val === null || val === '');
export const TextExistsInString = (wholeText: string, textToFind: string) => Boolean(wholeText.indexOf(textToFind) > -1);

// capitializeString
export const CapitializeString = (text: string) => {
  let processedText = text.toLowerCase().replace(/(?:^|\s)\S/g, (c) => c.toUpperCase());
  if (TextExistsInString(processedText, ' Of ')) processedText = processedText.replace(' Of ', ' of ');
  if (TextExistsInString(processedText, ' And ')) processedText = processedText.replace(' And ', ' and ');
  return processedText;
};

export const CapitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const MakeTextSingularOrPlural = (text: string, count: number) => (count > 1 ? `${text}s` : text);

// generateRandomString
export const handleGenerateRandomString = (length: number = 6, options?: { includeSmallCaps: boolean; includeNumerals: boolean }) => {
  let result = '';

  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (options?.includeSmallCaps) characters += 'abcdefghijklmnopqrstuvwxyz';
  if (options?.includeNumerals) characters += '0123456789';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};
