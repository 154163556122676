import { FETCH_BRAND_SUCCESS } from '@/redux/actions/brandActions';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';
import { BrandDataType } from '@/services/product';

const initialState: BrandSliceDataType = {
  brand: [],
  loading: true,
  error: false,
  isLogin: false
};

export default function brandData(state: BrandSliceDataType = initialState, action: AnyAction): BrandSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.brand };
    case FETCH_BRAND_SUCCESS:
      if(action.isLogin){
        return { ...state, brand: action.payload, loading: false, isLogin: true };
      }else{
        return { ...state, brand: action.payload, loading: false, isLogin: false};
      }
      
    default:
      return state;
  }
}

export type BrandSliceDataType = {
  loading: boolean;
  error: boolean;
  brand: BrandDataType[] | Record<string, undefined>;
  isLogin: boolean
};