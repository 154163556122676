import { homedir } from 'os';
import { json } from 'stream/consumers';
import { getFraudValidationCheckIP } from '../services/fraud-validation/services';

export const checkIpForBadCustomer = (ip: string) => {
  try {
    getFraudValidationCheckIP(ip, { contentType: 'application/json' });
  }catch(e){
    console.log('getFraudValidationCheckIP', e)
  }
};
export const checkNewUserWebsite = (setting: any, customer: string) => {
  let target = JSON.parse(setting);
  setting = target.homepage.versions;
  let visitor = target.homepage.visitors;
  target = {};

  if (visitor[customer]) {
    const randomNum = Math.floor(Math.random() * 100); // Returns a random integer from 0 to 99
    const range = setting.map((item, index) => setting.slice(0, index).reduce((total, val) => total + Number(val.waitage), 0));

    for (let i = 0; i < range.length; i++) {
      if (randomNum >= range[i] && (randomNum < range[i + 1] || i === range.length - 1)) {
        target = setting[i];
        break;
      }
    }
  }
  return target;
};

export const coinFlipLogic = (range: number) => {
let target = false
    const randomNum = Math.floor(Math.random() * 100); // Returns a random integer from 0 to 99
    console.log('going to verify user',randomNum)
    if(randomNum <= range){
      target = true
    }
  
  return target;
};