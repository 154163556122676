export const UPDATE_DESKTOP_GRID_SUCCESS = 'UPDATE_DESKTOP_GRID_SUCCESS';
export const UPDATE_MOBILE_GRID_SUCCESS = 'UPDATE_MOBILE_GRID_SUCCESS';

export const updateDesktopGridView = (showProductEachRow:number) => ({
  type: UPDATE_DESKTOP_GRID_SUCCESS,
  payload: showProductEachRow,
});

export const updateMobileGridView = (showProductEachRow:number) => ({
  type: UPDATE_MOBILE_GRID_SUCCESS,
  payload: showProductEachRow,
});
