import { FETCH_SIGNUP_CUSTOMER_SUCCESS, FETCH_SIGNUP_CUSTOMER_FAILURE } from '@/redux/actions/signupCustomerActions';
import { PostWaitlistCustomerPricingDataCustomerType } from '@/services/customer/types';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: SignupCustomerSliceDataType = {
  signupCustomer: 'not_login',
  loading: false,
  error: false,
};

export default function customerData(state: SignupCustomerSliceDataType = initialState, action: AnyAction): SignupCustomerSliceDataType {
  switch (action.type) {
    case HYDRATE:
      if (state.signupCustomer != action.payload.signupCustomer.signupCustomer) return { ...state, ...action.payload.signupCustomer };
      else return { ...state };
    case FETCH_SIGNUP_CUSTOMER_SUCCESS:
      return { ...state, signupCustomer: action.payload, loading: false };
    case FETCH_SIGNUP_CUSTOMER_FAILURE:
      return { ...state, loading: false, signupCustomer: 'not_login', error: true };
    default:
      return state;
  }
}

export type SignupCustomerSliceDataType = {
  signupCustomer: PostWaitlistCustomerPricingDataCustomerType | 'not_login';
  loading: boolean;
  error: boolean;
};
