import { SystemSettings } from '@/constants/global-config';
import { StoreThunk } from '@/redux/store';
import { getNotificationSettingsbykey, SystemSettingsType } from '@/services/website-settings';
import { fetchNotificationSuccess } from '@/redux/actions/notificationActions';
import { IncomingMessage } from 'http';
import { ParseHttpCookie } from '@/libraries/browser-storage';
import { ConvertJsonToObject } from '@/libraries/request';
import { NotificationsSliceDataType } from '@/redux/reducers/notificationReducer';

export const getNotificationMessage = (isMobile: boolean, serverRequest?: IncomingMessage): StoreThunk => {
  return async (dispatch, getState) => {
    const systemSettingsData = getState().systemSettings;
    const notificationData = getState().notification;
    const customerData = getState().customer;
    let customerPreferencesData = getState().customerPreferences.customerPreferences;

    const pinkbarMessage_toggle_id = SystemSettings.PINKBAR_MESSAGE_TOGGLE;

    let barOff = true;
    if (systemSettingsData && systemSettingsData.systemSettings && systemSettingsData.systemSettings.length > 0) {
      systemSettingsData.systemSettings.map((item: SystemSettingsType) => {
        if (item.setting_id.toString() === pinkbarMessage_toggle_id && item.setting_value.trim() === 'on') barOff = false;
      });
    }
    if (barOff) {
      dispatch(fetchNotificationSuccess({ ...notificationData, showNotificationBar: false }));
      return;
    }

    let notification_for = '';
    notification_for += isMobile ? 'mobile_' : 'desktop_';
    notification_for += customerData.customer === 'not_login' || customerData.customer.has_subscription === false ? 'non_subscribed_' : 'subscribed_';
    notification_for += customerData.customer !== 'not_login' && customerData.customer.has_subscription && customerData.customer.zone !== null && ['east', 'central'].includes(customerData.customer.zone) ? 'east' : customerData.customer !== 'not_login' && customerData.customer.has_subscription && customerData.customer.zone !== null ? customerData.customer.zone : 'default';

    const response = await getNotificationSettingsbykey(notification_for, {});

    if (response?.notification_settings && response.notification_settings.length > 0) {
      const notificationMessageObj = response.notification_settings[0];
      if (notificationMessageObj) {
        let action = notificationMessageObj.notification_action;
        let noteFor = notificationMessageObj.notification_key;
        let noteObj = JSON.parse(notificationMessageObj.notification_title);
        let last_update = notificationMessageObj.updated_at;
        let cookies = '';
        let cookie_preferences: Record<string, unknown> | string = '';
        let appCookie = '';
        // let fromMobileApp = true;

        if (customerData.customer === 'not_login') {
          if (serverRequest && serverRequest.headers) cookies = serverRequest.headers.cookie || '';
          else {
            try {
              cookies = document.cookie;
            } catch (ex) {
              cookies = '';
            }
          }

          if (cookies !== null && cookies !== undefined && cookies !== '') {
            const cookiesJSON = ParseHttpCookie(cookies);
            if (cookiesJSON[noteFor] !== undefined) cookie_preferences = ConvertJsonToObject(cookiesJSON[noteFor]);
          }
        }

        if (customerPreferencesData.preferences !== undefined && customerPreferencesData.preferences !== null && customerPreferencesData.preferences?.hasOwnProperty(noteFor) !== false && typeof customerPreferencesData.preferences?.[noteFor] === 'string') {
          customerPreferencesData.preferences[noteFor] = JSON.parse(customerPreferencesData.preferences[noteFor]);
        }

        if (serverRequest && serverRequest.headers) appCookie = serverRequest.headers.cookie || '';
        // if(serverRequest && serverRequest.url && serverRequest.url.indexOf('fromapp=true') == -1 && appCookie.indexOf('fromMobileApp') == -1){
        //   fromMobileApp = false
        // }
        if (action === false) {
          // don't show notification ---> notification closed from system
          if(customerData.customer !== 'not_login' && customerData.customer.has_subscription === true && customerData.customer.last_order_id == 0){
            dispatch(fetchNotificationSuccess({ ...notificationData, showNotificationBar: true }));
          }else{
            dispatch(fetchNotificationSuccess({ ...notificationData, showNotificationBar: false }));
          }
        } else if (customerData.customer === 'not_login' && (cookie_preferences === undefined || customerPreferencesData.preferences === null || cookie_preferences === '' || (typeof cookie_preferences !== 'string' && cookie_preferences.message !== noteObj.text) || (typeof cookie_preferences !== 'string' && cookie_preferences.updated_at !== last_update))) {
          // show notification nonlogin user --->  cookies not found OR  message Changed OR message's Last updated change
          dispatch(fetchNotificationSuccess({ ...notificationData, notification: notificationMessageObj, showNotificationBar: true }));
        } else if (customerData.customer !== 'not_login' && (customerPreferencesData.preferences === undefined || customerPreferencesData.preferences === null || customerPreferencesData.preferences?.hasOwnProperty(noteFor) === false || (customerPreferencesData.preferences?.hasOwnProperty(noteFor) && (customerPreferencesData.preferences[noteFor].isSubscribed !== customerData.customer.has_subscription || customerPreferencesData.preferences[noteFor].message !== noteObj.text || customerPreferencesData.preferences[noteFor].updated_at !== last_update)))) {
          // show notification --->  prefrence not found OR  user changed his subscription OR message Changed OR message's Last updated change
          dispatch(fetchNotificationSuccess({ ...notificationData, notification: notificationMessageObj, showNotificationBar: true }));
        } else {
          // don't show notification
          dispatch(fetchNotificationSuccess({ ...notificationData, showNotificationBar: false }));
        }
      }
    } else {
      if(customerData.customer !== 'not_login' && customerData.customer.has_subscription === true && customerData.customer.last_order_id == 0){
        dispatch(fetchNotificationSuccess({ ...notificationData, showNotificationBar: true }));
      }else{
        dispatch(fetchNotificationSuccess({ ...notificationData, showNotificationBar: false }));
      }
    }
  };
};

export const hideNotificationBar = (notificationData: NotificationsSliceDataType): StoreThunk => {
  return async (dispatch, getState) => {
    dispatch(fetchNotificationSuccess(notificationData));
  };
};
