import { FETCH_SERVERSIDE_RELOAD_SUCCESS } from '@/redux/actions/checkServersideReloadActions';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: CheckServerSideReloadSliceDataType = {
  serversideReload: { reload: false },
  loading: true,
  error: false,
};

export default function serversideReloadData(state: CheckServerSideReloadSliceDataType = initialState, action: AnyAction): CheckServerSideReloadSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.serversideReload };
    case FETCH_SERVERSIDE_RELOAD_SUCCESS:
      return { ...state, serversideReload: action.payload, loading: false };
    default:
      return state;
  }
}

export type CheckServerSideReloadSliceDataType = {
  serversideReload: { reload: boolean };
  loading: boolean;
  error: boolean;
};
