import { RenderingConfigSliceDataType } from '@/redux/reducers/renderingConfigReducer';

export const FETCH_RENDERING_CONFIG_SUCCESS = 'FETCH_RENDERING_CONFIG_SUCCESS';
export const FETCH_RENDERING_CONFIG_NOT_LOGIN = 'FETCH_RENDERING_CONFIG_NOT_LOGIN';
export const FETCH_RENDERING_CONFIG_FAILURE = 'FETCH_RENDERING_CONFIG_FAILURE';
export const SET_BLACK_FRIDAY_BAR_RENDERING_CONFIG = 'SET_BLACK_FRIDAY_BAR_RENDERING_CONFIG';

export const fetchRenderingConfigSuccess = (renderingConfig: RenderingConfigSliceDataType) => ({
  type: FETCH_RENDERING_CONFIG_SUCCESS,
  payload: renderingConfig,
});

export const fetchRenderingConfigNotLogin = () => ({
  type: FETCH_RENDERING_CONFIG_NOT_LOGIN,
  // payload: {},
});

export const fetchRenderingConfigFailure = (error: RenderingConfigSliceDataType['error']) => ({
  type: FETCH_RENDERING_CONFIG_FAILURE,
  payload: error,
});

export const setBlackFridayBarRenderingConfig = (visibility: 'show' | 'hide') => ({
  type: SET_BLACK_FRIDAY_BAR_RENDERING_CONFIG,
  payload: visibility === 'show' ? true : false,
});
