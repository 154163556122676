import { CookieKeys } from '@/constants/cookie-keys';
import { ParseHttpCookie, RemoveCookie, SetCookie } from '@/libraries/browser-storage';
import { StoreThunk } from '@/redux/store';
import { getCustomerSummary } from '@/services/customer';
import { LogInfo } from '@/utils/dev-logs';
import { validateOnServerSideIsItMobileView } from '@/utils/misc-methods';
import { fetchCustomerFailure, fetchCustomerSuccess } from '@/redux/actions/customerActions';
import { setBlackFridayBarRenderingConfig } from '@/redux/actions/renderingConfigActions';
import { getNotificationMessage } from '@/redux/thunks/notificationThunks';
import { IncomingMessage } from 'http';
import { updateRenderingConfig } from '@/redux/thunks/renderingConfigThunks';
import { subscibeDeviceForMobile } from 'components/shared/globalFunctions';
import jsCookie from 'js-cookie';
import { getSystemSettings } from '@/redux/thunks/systemSettingsThunks';
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';

export const checkUserAthentication = (serverRequest?: IncomingMessage, getSetting?: boolean, pathQuery?: string): StoreThunk => {
  return async (dispatch, getState) => {
    const renderingConfigData = getState().renderingConfig;

    let cookies = '';
    let isMobileView = false;
    const isServer = Boolean(serverRequest !== undefined);

    if (serverRequest && serverRequest.headers) {
      cookies = serverRequest.headers.cookie || '';
      isMobileView = validateOnServerSideIsItMobileView(serverRequest);
    } else {
      try {
        cookies = document.cookie;
      } catch (error) {
        cookies = '';
      }
    }

    if (cookies != '' && typeof cookies === 'string') {
      const cookiesJSON = ParseHttpCookie(cookies);

      if (cookiesJSON.customer_token !== undefined) {
        try {
          if(pathQuery !== undefined && pathQuery?.indexOf('fromapp') > -1){
            console.log('>>>>>>>AppToken======'+cookiesJSON.customer_token)
          }
          const response = await getCustomerSummary(true, { token: cookiesJSON.customer_token, customerIP: true, guid: true });
          if (response && response?.success) {
            LogInfo('checkUserAthentication method', `Validation success, Customer logged in. Email = ${response.customer_summary.email}`);
            // LogInfo('checkUserAthentication method', `Validation success, Customer logged in. Data = ${response.customer_summary}`);
            LogInfo('checkUserAthentication method', `Validation success, Customer logged in. Data = ${JSON.stringify(response.customer_summary)}`);

            const fetchedCustomerData = response.customer_summary;

            if (!isServer && renderingConfigData.show.blackfridayBar && fetchedCustomerData.has_subscription) {
              dispatch(setBlackFridayBarRenderingConfig('hide'));
              RemoveCookie(CookieKeys.BLACK_FRIDAY_POPUP_CLOSE);
            }
            if (!isServer && getSetting && fetchedCustomerData.has_subscription) {
              dispatch(getSystemSettings(false, false, true));
            }
            if (cookiesJSON.face_login !== undefined && cookiesJSON.fcm_token !== undefined) {
              subscibeDeviceForMobile(cookiesJSON.fcm_token, fetchedCustomerData.customer_id, serverRequest);
              jsCookie.remove('face_login');
            }

            // SetCookie(CookieKeys.LAST_CUSTOMER_SUMMARY_FETCHED, '23123213')
            SetCookie(CookieKeys.CUSTOMER_DATA, fetchedCustomerData);
            dispatch(fetchCustomerSuccess(fetchedCustomerData));
            dispatch(updateRenderingConfig('from checkUserAthentication - login'));
            await dispatch(getNotificationMessage(isMobileView, serverRequest));
          } else {
            LogInfo('checkUserAthentication method', 'Validation failed, Customer logged out');
            RemoveCookie(CookieKeys.CUSTOMER_TOKEN);
            dispatch(fetchCustomerFailure(true));
            dispatch(updateRenderingConfig('from checkUserAthentication - not_login - 1'));
          }
        } catch (error) {
          dispatch(fetchCustomerFailure(true));
        }
      } else {
        LogInfo('checkUserAthentication method', 'Customer token doesnt exist, Login failed');
        dispatch(fetchCustomerFailure(true));
        dispatch(updateRenderingConfig('from checkUserAthentication - not_login - 2'));
      }
    } else {
      dispatch(updateRenderingConfig('from checkUserAthentication - not_login - 3'));
    }
  };
};
