import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/main.scss';
import 'styles/style.css';
import 'styles/mobile/mobileHeader.scss';
import 'styles/_resetPassword.scss';
import '@/styles/theme-colors.css';
import '@/styles/_fonts.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CookieKeys } from '@/constants/cookie-keys';
import { ContentSettings, SystemSettings } from '@/constants/global-config';
import { LocalStorageKeys } from '@/constants/local-storage-keys';
import { GetFromLocalStorage, SerializeHttpCookie, SetCookie, SetInLocalStorage } from '@/libraries/browser-storage';
import { ConvertJsonToObject } from '@/libraries/request';
import { fetchServersideReloadSuccess } from '@/redux/actions/checkServersideReloadActions';
import { fetchSystemSetttingsSuccess } from '@/redux/actions/systemSettingsActions';
import { SystemSettingsSliceDataType } from '@/redux/reducers/systemSettingsReducer';
import wrapper from '@/redux/store';
import { getCustomerPreferences, updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import { checkUserAthentication } from '@/redux/thunks/customerThunks';
import { getNotificationMessage } from '@/redux/thunks/notificationThunks';
import { updateRenderingConfig } from '@/redux/thunks/renderingConfigThunks';
import { getSystemSettings, getMaintenanceSystemSettings } from '@/redux/thunks/systemSettingsThunks';
import { createRequestGuid, validateOnServerSideIsItMobileView } from '@/utils/misc-methods';
import { TextExistsInString } from '@/utils/string-methods';
import { DoesWindowExists } from '@/utils/window-methods';
import { getMainCategory } from '@/redux/thunks/categoryThunks';
import CurrentlyDownComp from 'components/shared/currentlyDownComp';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import { useEffect, useMemo } from 'react';
import FullStory from 'react-fullstory';
import { Provider } from 'react-redux';
import { checkIpForBadCustomer, checkNewUserWebsite } from '@/functions/customer-functions';
import { sentWebGLDataofUser } from 'webGL/webGLIndex.js';
import { fetchAllBrandsData } from 'components/shared/globalFunctions';
// import Gconfig from 'globalconfig';
if (process.env.NEXT_PUBLIC_ENVIRONMENT == 'development') {
  require('styles/blur.css');
}
import jsCookie from 'js-cookie';

interface IProps extends AppProps {
  requestFailed: boolean;
  pd?: string;
  isDomainClientOrServer?: string;
  curl?: string;
  isMobileView?: boolean;
  systemSettingsData?: SystemSettingsSliceDataType;
  url?: string;
  customer: any;
  heeader: any;
  urlQuery: any;
}

// const ab_obj =  {
//   "homepage": {
//     "versions": [
//       {
//         "id": "A",
//         "waitage": "50"
//       },
//       {
//         "id": "B",
//         "waitage": "50"
//       }
//     ],
//     "visitors": {
//       "NL": true,
//       "LN": true,
//       "LS": true
//     },
//     "action": "open"
//   }
// }

const ORG_ID = '3SMNC';
const fpPixel = '1481434321942616';

function MyApp({ Component, ...rest }: IProps) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps, isMobileView, pd, url, requestFailed, systemSettingsData, curl, customer, heeader, urlQuery }: IProps = props;

  const maintenance_enabled = useMemo(() => systemSettingsData?.systemSettings.find((item) => Boolean(typeof item !== undefined && item !== undefined && item.setting_id.toString() === SystemSettings.MAINTENANCE_ENABLED)), [systemSettingsData]);
  const maintenance_message = useMemo(() => systemSettingsData?.systemSettings.find((item) => Boolean(typeof item !== undefined && item !== undefined && item.setting_id.toString() === SystemSettings.MAINTENANCE_MESSAGE)), [systemSettingsData]);

  useEffect(() => {
    if (heeader != null) {
      sessionStorage.setItem('web_headers', JSON.stringify(heeader));
    }

    let webGLcookie = jsCookie.get('isWebGLhashSubmitted');
    if (customer?.customer != 'not_login' && webGLcookie == undefined && webGLcookie != 'yes') {
      // if(screen.width > 767)
      sentWebGLDataofUser(customer?.customer);
    }

    //   console.log('urlQuery data >>>>>>>>>>>>>>>>>>>>>>>>------------->>>>>>>>>>',urlQuery)
    //  console.log("pdveriable>>>>>>>>>>>>>>>>>>",pd)
    if (pd !== undefined && !TextExistsInString(pd, 'local') && !TextExistsInString(pd, 'new') && !TextExistsInString(pd, 'dev') && !TextExistsInString(pd, 'stage')) {
      let cus_email = jsCookie.get('fp_px_email')
      let cus_name = jsCookie.get('fp_px_name')
      let cus_phone = jsCookie.get('fp_px_phone')
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(fpPixel, undefined, { debug: true, autoConfig: false });
          ReactPixel.pageView();
          // console.log('fire events url>>>>>>>>>>>>>>>>>>>>>>___________>>>>>>>1',url)
          if (TextExistsInString(urlQuery || '', 'selectedPlan') && (!GetFromLocalStorage(LocalStorageKeys.SIGNUP_FIRE) || GetFromLocalStorage(LocalStorageKeys.SIGNUP_FIRE) == undefined)) {
            console.log('fire eevent >>>>>>SIGNUP_FIRE', LocalStorageKeys.SIGNUP_FIRE);
            // console.log('fire events>>>>>>>>>>>>>>>>>>>>>>___________>>>>>>>2')
            console.log('fp pixel fired >>>>>1');
            if (TextExistsInString(urlQuery || '', 'Socialite')) {
              ReactPixel.trackCustom('successful-sign-up-socialite', '');
              ReactPixel.trackCustom('Successful-sign-up', { Plan: 'Socialite' });
              gtag({ event: 'Successful-sign-up', ecommerce: { transaction_id: Date.now(), value: 89, tax: 0, shipping: 0, currency: 'USD', items: { item_type: 'subscription', item_name: 'Socialite' }, email: cus_email, name: cus_name, phone: cus_phone  } });
              gtag({ event: 'successful-sign-up-socialite', ecommerce: { transaction_id: Date.now(), value: 89, tax: 0, shipping: 0, currency: 'USD', items: { item_type: 'subscription', item_name: 'Socialite' },email: cus_email, name: cus_name, phone: cus_phone } });
              console.log('fp pixel fired ----------->> successful-sign-up-socialite');
              // ttq.track('Successful-sign-up');
            }
            if (TextExistsInString(urlQuery || '', 'Trendsetter')) {
              ReactPixel.trackCustom('successful-sign-up-trendsetter', '');
              ReactPixel.trackCustom('Successful-sign-up', { Plan: 'TrendSetter' });
              gtag({ event: 'Successful-sign-up', ecommerce: { transaction_id: Date.now(), value: 119, tax: 0, shipping: 0, currency: 'USD', items: { item_type: 'subscription', item_name: 'TrendSetter'}, email: cus_email, name: cus_name, phone: cus_phone } });
              gtag({ event: 'successful-sign-up-trendsetter', ecommerce: { transaction_id: Date.now(), value: 119, tax: 0, shipping: 0, currency: 'USD', items: { item_type: 'subscription', item_name: 'TrendSetter'}, email: cus_email, name: cus_name, phone: cus_phone } });
              console.log('fp pixel fired ----------->> successful-sign-up-trendsetter');
              // ttq.track('Successful-sign-up');
            }
            if (TextExistsInString(urlQuery || '', 'Wanderlust')) {
              ReactPixel.trackCustom('successful-sign-up-wanderlust', '');
              ReactPixel.trackCustom('Successful-sign-up', { Plan: 'WanderLust' });
              gtag({ event: 'Successful-sign-up', ecommerce: { transaction_id: Date.now(), value: 149, tax: 0, shipping: 0, currency: 'USD', items: { item_type: 'subscription', item_name: 'Wanderlust'}, email: cus_email, name: cus_name, phone: cus_phone } });
              gtag({ event: 'successful-sign-up-wanderlust', ecommerce: { transaction_id: Date.now(), value: 149, tax: 0, shipping: 0, currency: 'USD', items: { item_type: 'subscription', item_name: 'Wanderlust'}, email: cus_email, name: cus_name, phone: cus_phone } });
              console.log('fp pixel fired ----------->> successful-sign-up-wanderlust');
              // ttq.track('Successful-sign-up');
            }
            console.log('fp pixel fired >>>>>2 suicces');
            // gtag({event:'Successful-sign-up'});
            SetInLocalStorage(LocalStorageKeys.SIGNUP_FIRE, true);
            jsCookie.remove('fp_px_email')
            jsCookie.remove('fp_px_name')
            jsCookie.remove('fp_px_phone')
            // gtag('event', 'successful-sign-up', { transaction_id: '', type: 'Plan_signup' });
            // gtag({event:'successful_sign_up'})
            // ttq.track('Purchase-Submit-Email-Popup');
          }

          Router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
            if (ttq) ttq.page();
          });
        });
    }

    // if (TextExistsInString(url || '', 'complete-profile') || TextExistsInString(url || '', 'purchase-plan') || TextExistsInString(url || '', 'signup-plans')) {
    //window.location.href =  window.location.origin+'/signup';
    // }

    let gUid = createRequestGuid();
    if (gUid) SetCookie(CookieKeys.REQUEST_GUID, gUid);
  });

  useEffect(() => {
    let appVersion = jsCookie.get('appversion');
    if (appVersion != undefined) console.log('appversion', appVersion);

    const handleBeforeUnload = () => {
      return undefined;
    };
    if (typeof window != undefined) {
      window.addEventListener('beforeunload', function (event) {
        event.stopImmediatePropagation();
        console.log('window listner running');
      });
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        console.log('window listner remove');
      };
    }
  }, []);

  pageProps.local_URL = curl;

  return (
    <>
      <Head>
        <meta charSet="utf-8" key="charSet" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" key="viewport" />
        {/* <script src='https://www.dwin1.com/65982.js' type='text/javascript' defer='defer'></script> */}
        {pd !== undefined && pd.indexOf('confirmation') == -1 && pd.indexOf('purchase-plan') == -1 && pd.indexOf('checkout') == -1 ? <script src='https://www.dwin1.com/65982.js' id='SASElement'  type='text/javascript' defer='defer'></script>:''}     
      </Head>
      <Provider store={store}>
      {/* <CurrentlyDownComp content={'<p>We have taken the site down for some</p> <p>brief maintenance but we will be back</p>'} /> */}
        {requestFailed && <CurrentlyDownComp content={'<p>We have taken the site down for some</p> <p>brief maintenance but we will be back</p>'} />}
        {!requestFailed && (
          <>
            {pd !== undefined && pd.indexOf('local') !== 1 ? <FullStory org={ORG_ID} namespace="FullS" /> : console.log('FULL STORY IS NOT BEING LOADED AS THE DOMAIN has ' + pd + ' in it PD WAS' + pd)}
            {maintenance_enabled && maintenance_enabled.setting_value == '1' && jsCookie.get('maintenanceFree') != '1'   && (typeof pd == undefined || pd == undefined || pd.indexOf('new') == -1) ? <CurrentlyDownComp content={maintenance_message && maintenance_message.setting_value} /> : <Component {...pageProps} isMobileView={isMobileView} />}
          </>
        )}
      </Provider>
    </>
  );
}

MyApp.getInitialProps = wrapper.getInitialAppProps((store) => async (context) => {
  const { ctx, Component } = context;

  const customerData = store.getState().customer;
  const systemSettingsData = store.getState().systemSettings;

  let domain = ctx.req !== undefined ? ctx.req.headers.host : 'client';
  let domain_real = domain;
  let q_url = ctx.req !== undefined ? ctx.req.url : '';
  let isMobileView = false;
  let local_URL = '';
  let TOc = 'server';
  let pd = domain;
  let isServerSide = false;
  let urlQuery = context.router !== undefined ? context.router.asPath : '';
  let customerIp: any = '';
  // console.log('------------>>>>>>>>>>>>>>>>>',context.router.asPath)
  if (domain && !TextExistsInString(domain, 'fashionpass') && !TextExistsInString(domain, 'localhost') && TextExistsInString(domain, '172')) {
    return { pageProps: {}, requestFeild: true, url: q_url, pd: pd };
  }

  if (DoesWindowExists() && pd === 'client') {
    pd = window.location.href;
    TOc = pd;
  }

  if (ctx.req !== undefined) {
    if (ctx.req !== undefined && ctx.req.headers['user-agent'] !== undefined) isMobileView = validateOnServerSideIsItMobileView(ctx.req);
    if (ctx.req.url !== undefined && TextExistsInString(ctx.req.url, 'fromapp=true')) isMobileView = true;
    if (domain !== undefined && domain !== 'client') {
    
      try {
        await store.dispatch(getMaintenanceSystemSettings());
        const ip = ctx.req.headers['x-forwarded-for'] || ctx.req.connection.remoteAddress;
        console.log('Full IP Address ', ip);
        if (Object.keys(ctx.req.cookies).length === 0 && ip) checkIpForBadCustomer(ip.toString());
        let webSiteVersion: any = {};
        if (ip && ip?.toString().indexOf(',') > -1) {
          ctx.res?.setHeader('Set-Cookie', SerializeHttpCookie(CookieKeys.CUSTOMER_IP, ip?.toString().split(',')[0] || ''));
          customerIp = ip?.toString().split(',')[0];
        } else {
          ctx.res?.setHeader('Set-Cookie', SerializeHttpCookie(CookieKeys.CUSTOMER_IP, ip?.toString() || ''));
          customerIp = ip?.toString();
        }
        await store.dispatch(getSystemSettings(false, true));
        await store.dispatch(getCustomerPreferences(ctx.req));
        local_URL = ctx.req.url || '';
        await store.dispatch(checkUserAthentication(ctx.req, false, urlQuery));
        await store.dispatch(getNotificationMessage(isMobileView, ctx.req));
        let cust = store.getState().customer?.customer;
        await store.dispatch(fetchAllBrandsData(cust.customer_id))
        if (customerData.customer !== 'not_login' && customerData.customer.has_subscription === true) {
          if (isMobileView) await store.dispatch(getMainCategory(ContentSettings.SUBSCRIBED_MOBILE_MENU, 'Mobilemenu', local_URL, ctx.req));
          else await store.dispatch(getMainCategory(ContentSettings.SUBSCRIBED_DESKTOP_MENU, 'Desktopmenu', local_URL, ctx.req));
        } else {
          if (isMobileView) await store.dispatch(getMainCategory(ContentSettings.MOBILE_MENU, 'Mobilemenu', local_URL, ctx.req));
          else await store.dispatch(getMainCategory(ContentSettings.DESKTOP_MENU, 'Desktopmenu', local_URL, ctx.req));
        }
        let systemSettingsData = store.getState().systemSettings;
        let isCustomer = 'NL';
        if (cust != 'not_login' && cust.has_subscription == true) {
          isCustomer = 'LS';
        } else if (cust != 'not_login' && cust.has_subscription == false) {
          isCustomer = 'LN';
        }
        // ---AB Testing Work---

        // let abTestingData = systemSettingsData.systemSettings.find(function (sett) {
        //   return typeof sett != undefined && sett != undefined && sett.setting_id == SystemSettings.AB_DATA_ID;
        // });

        // let customerPreferences: {} = store.getState().customerPreferences.customerPreferences;
        // if (abTestingData) {
        //   let abData = JSON.parse(abTestingData.setting_value);
        //   // let abData = ab_obj;
        //   // console.log('abData ======>', abData)
        //   let abAction = abData.homepage.action;
        //   if (isCustomer != 'NL' && customerPreferences.preferences && customerPreferences.preferences.homePageABversion && abAction == 'closed') {
        //     let homePageABversion = JSON.parse(customerPreferences.preferences.homePageABversion);
        //     ctx.res.setHeader('Set-Cookie', 'customerHomeAB=' + homePageABversion.id);
        //   } else {
        //     if (Object.keys(ctx.req.cookies).length > 0 && abAction == 'closed') {
        //       if (ctx.req.headers.cookie.indexOf('customerHomeAB') == -1) {
        //         webSiteVersion = checkNewUserWebsite(abTestingData.setting_value, isCustomer);
        //         if (Object.keys(webSiteVersion).length > 1) {
        //           ctx.res.setHeader('Set-Cookie', 'customerHomeAB=' + webSiteVersion.id);
        //           if (isCustomer != 'NL') {
        //             store.dispatch(updateCustomerPreferences(webSiteVersion, 'homePageABversion', ctx['req']));
        //           }
        //         }
        //       }
        //     }
        //     else if (Object.keys(ctx.req.cookies).length > 0 && abAction == 'inverse') {
        //       if (ctx.req.headers.cookie.indexOf('customerHomeAB') == -1) {
        //         webSiteVersion = checkNewUserWebsite(abTestingData.setting_value, isCustomer);
        //         if (Object.keys(webSiteVersion).length > 1) {
        //           ctx.res.setHeader('Set-Cookie', 'customerHomeAB=' + webSiteVersion.id);
        //           if (isCustomer != 'NL') {
        //             store.dispatch(updateCustomerPreferences(webSiteVersion, 'homePageABversion', ctx['req']));
        //           }
        //         }
        //       }
        //       else {
        //         let target = JSON.parse(abTestingData.setting_value);
        //         let setting = target.homepage.versions;
        //         let visitor = target.homepage.visitors;
        //         target = {};

        //         if (visitor[isCustomer]) {
        //         if(ctx.req.headers.cookie.indexOf('customerHomeAB='+setting[0].id) != -1) {
        //             ctx.res.setHeader('Set-Cookie', 'customerHomeAB=' + setting[1].id);
        //             if (isCustomer != 'NL') {
        //               store.dispatch(updateCustomerPreferences(setting[1], 'homePageABversion', ctx['req']));
        //             }
        //         }
        //         else {
        //           ctx.res.setHeader('Set-Cookie', 'customerHomeAB=' + setting[0].id);
        //           if (isCustomer != 'NL') {
        //             store.dispatch(updateCustomerPreferences(setting[0], 'homePageABversion', ctx['req']));
        //           }
        //         }
        //       }
        //      }
        //     }
        //     else {
        //       webSiteVersion = checkNewUserWebsite(abTestingData.setting_value, isCustomer);
        //       // console.log(webSiteVersion, '------------------>>>>>>>>>>>>>>>>');
        //       if (Object.keys(webSiteVersion).length > 1) {
        //         ctx.res.setHeader('Set-Cookie', 'customerHomeAB=' + webSiteVersion.id);
        //         if (isCustomer != 'NL') {
        //           store.dispatch(updateCustomerPreferences(webSiteVersion, 'homePageABversion', ctx['req']));
        //         }
        //       }
        //     }
        //   }
        // }

        // ---AB Testing Work---
        
        await store.dispatch(updateRenderingConfig('from _app.js'));
      } catch (e) {
        console.log('------ here is feil requests >>>>>>>>>>>>>>>>', e);
        await store.dispatch(fetchSystemSetttingsSuccess([]));
        return { pageProps: {}, requestFailed: true, url: q_url };
      }

      isServerSide = true;
      await store.dispatch(fetchServersideReloadSuccess({ reload: true }));
    } else {
      isServerSide = false;
      //await store.dispatch(getSystemSettings());
    }
  }

  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

  let urlKey = '';
  if (ctx.req) urlKey = ctx.req.url || '';
  else urlKey = ctx.asPath || '';

  if (urlKey !== undefined && TextExistsInString(urlKey, 'fromapp=true')) {
    if (ctx && ctx.res) ctx.res.setHeader('Set-Cookie', `fromMobileApp=true`);
    if (urlKey !== undefined && TextExistsInString(urlKey, 'fromEmail')) {
      console.log('>>>>>>>AppEmail======' + urlKey?.split('fromEmail=')[1] + '======>>>>>>>IP======' + customerIp);
    }
  }
  if (urlKey !== undefined && TextExistsInString(urlKey, 'freethirdshipment=true')) {
    if (ctx && ctx.res) ctx.res.setHeader('Set-Cookie', `freethirdshipment=true`);
  }
  if (TextExistsInString(urlKey, 'newpromotion')) {
    if (ctx && ctx.res) ctx.res.setHeader('Set-Cookie', `${CookieKeys.REACTIVATE_PRICING_OFFER}=true; path=/;`);
    else if (ctx && ctx.asPath) document.cookie = `${CookieKeys.REACTIVATE_PRICING_OFFER}=true; path=/;`;
  }
  if (urlKey !== undefined && TextExistsInString(urlKey, 'maintenanceFree')) {
    if (ctx && ctx.res) ctx.res.setHeader('Set-Cookie', `maintenanceFree=1; expires=${new Date(new Date().getTime() + 180 * 60 * 1000)}`);
  }

  if (systemSettingsData && systemSettingsData.systemSettings && urlKey) {
    const dynamicUrlSettings = systemSettingsData.systemSettings.find((item) => Boolean(typeof item !== undefined && item !== undefined && item.setting_id.toString() === SystemSettings.DYNAMIC_URL));
    const dynamicUrlSettingsValue: any[] = ConvertJsonToObject(dynamicUrlSettings?.setting_value || '[]');

    const customerUrlEvent = dynamicUrlSettingsValue.find((item) => urlKey.toLowerCase().indexOf(item.key && item.key.toLowerCase()) > -1);
    if (customerUrlEvent) {
      let now = new Date();
      let minutes = customerUrlEvent.time;
      now.setTime(now.getTime() + minutes * 60 * 1000);
      let expires = now.toGMTString();
      if (ctx && ctx.res) ctx.res.setHeader('Set-Cookie', `${CookieKeys.CUSTOMER_URL_EVENT}=${customerUrlEvent.type}; path=/; expires=${expires}`);
      else if (ctx && ctx.asPath) document.cookie = `${CookieKeys.CUSTOMER_URL_EVENT}=${customerUrlEvent.type}; expires=${expires}; path=/;`;
    }
  }

  return {
    pageProps: pageProps,
    requestFailed: false,
    pd: pd,
    isDomainClientOrServer: domain,
    curl: local_URL,
    isMobileView: isMobileView,
    systemSettingsData: systemSettingsData,
    url: q_url,
    customer: customerData,
    heeader: ctx.req ? ctx.req.headers : null,
    urlQuery: urlQuery,
  };
});

export default MyApp;
