import { FETCH_LASTRENTALRETURNORDER_SUCCESS } from "@/redux/actions/lastRentalReturnOrderActions";
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: LastRentalReturnOrderSliceDataType = {
    message: "",
    success: false,
    result: null,
};

export default function lastRentalReturnOrder(state: LastRentalReturnOrderSliceDataType = initialState, action: AnyAction): LastRentalReturnOrderSliceDataType {
    switch (action.type) {
      case HYDRATE:
        return { ...state, ...action.payload.result };
      case FETCH_LASTRENTALRETURNORDER_SUCCESS:
        return { ...state, result: action.payload.result, success: true, message: action.payload.message  };
      default:
        return state;
    }
}

export type LastRentalReturnOrderSliceDataType = {
  message: string;
  success: boolean;
  result: any;
};