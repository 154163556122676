import { AxiosGet, AxiosPost, GenericFetchGet } from '@/libraries/request';
import { BASE_URL_FP_API, configDataType } from '@/services/config';
import { GetFaqByPage_ResponseType, GetPageContentByHandle_ResponseType, GetStaticPageById_ResponseType, GetSubscriptionPlans_ResponseType, PostUploadImages_ArgsType, PostUploadImages_ResponseType } from '@/services/content/types';

const setErrorMessage = (message: string) => ({ title: 'Content Service', message: message });

// API's
const GetFaqByPage_api = (page_id: string) => `${BASE_URL_FP_API}Content/GetFaqByPage/${page_id}`;
const GetSubscriptionPlans_api = () => `${BASE_URL_FP_API}Content/GetSubscriptionPlans`;
const UploadImages_api = () => `${BASE_URL_FP_API}Content/UploadImages`;
const GetPageContentByHandle_api = (pageName: string) => `${BASE_URL_FP_API}Content/GetPageContentByHandle/${pageName}`;
const GetStaticPageById_api = (pageID: string) => `${BASE_URL_FP_API}Content/GetStaticPageById/${pageID}`;

// Methods
export const getFaqByPage = (page_id: string, configData: configDataType) => AxiosGet<GetFaqByPage_ResponseType>(GetFaqByPage_api(page_id), configData, setErrorMessage('getFaqByPage Method'));
export const getSubscriptionPlans = (configData: configDataType) => AxiosGet<GetSubscriptionPlans_ResponseType>(GetSubscriptionPlans_api(), configData, setErrorMessage('getSubscriptionPlans Method'));
export const postUploadImages = (data: PostUploadImages_ArgsType, configData: configDataType) => AxiosPost<PostUploadImages_ResponseType>(UploadImages_api(), data, configData, setErrorMessage('postUploadImages Method'));
export const getPageContentByHandle = (pageName: 'contact-us' | 'press', configData: configDataType) => GenericFetchGet<GetPageContentByHandle_ResponseType>(GetPageContentByHandle_api(pageName), configData, setErrorMessage(`getPageContentByHandle Method - ${pageName}`));
export const getStaticPageById = (pageID: string, configData: configDataType) => GenericFetchGet<GetStaticPageById_ResponseType>(GetStaticPageById_api(pageID), configData, setErrorMessage(`getStaticPageById Method - ${pageID}`));
