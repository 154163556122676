import React from 'react';
// import ReactDOM from 'react-dom';
import {fetchCartSuccess,fetchCartFailure,fetchCartStarted,fetchCartEnded,fetchCartSaveLaterSuccess} from 'redux/actions/cartActions'
import { remainingQuantityOfItemsInCart } from 'components/shared/globalFunctions';
import jsHttpCookie from 'cookie';
import jsCookie from 'js-cookie';
import Gconfig from "globalconfig";
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import cookie from 'js-cookie';
const fpPixel = "1481434321942616";

function checkCartItemsAgainstPlan(cart,plan,addedItem,newPlan,customerData) {
   
    let clothingItemDiff = 0
    let accesoriesItemDiff = 0
    let remainingAddedItems = addedItem 
    let accesoriesItems = 0;
    let clothingItems = 0;

    // current items in cart
    cart.items.forEach((item,index) => { 
        if(item.cartItemType == 2)
        return
        if(item.cartItemType == 1 && (item.sku.product.product_catagory == 2 || item.sku.product.product_catagory == 3)){
            accesoriesItems++;
        }
        else if(item.cartItemType == 1 && item.sku.product.product_catagory == 1){
            clothingItems++
        }
    })

    // last rental order info
    let lastRentalOrderId = (customerData != 'not_login' && customerData.lastRentalOrder && customerData.lastRentalOrder.orderid) ? customerData.lastRentalOrder.orderid : -1;
    let lastRentalOrderClothingTransferCount = 0
    let lastRentalOrderAccessoryTransferCount = 0
    let rentalOrderArr = customerData != 'not_login' && customerData.lastRentalOrderItems && customerData.lastRentalOrderItems.length > 0 ? customerData.lastRentalOrderItems : [];

    rentalOrderArr.length > 0 && rentalOrderArr.map((item) => {
        if (lastRentalOrderId > -1 && item.tags != "" && item.tags.indexOf(`transferfrom:`) && (item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) > -1)) {
            // count of transfered colthing item in last order
            if(item.product_category == 1) {
                lastRentalOrderClothingTransferCount += 1
            }
            // count of transfered acc item in last order
            else {
                lastRentalOrderAccessoryTransferCount += 1
            }
        }
    })

    let remaingPlanClothing =  plan?.clothing - lastRentalOrderClothingTransferCount
    let remaingPlanAccessory =  plan?.accessory - lastRentalOrderAccessoryTransferCount

    let isSwapingIsDoneinLastRentalOrder = false
    if(remaingPlanClothing < 0 || remaingPlanAccessory < 0) {
        
        if(remaingPlanClothing < 0 && remaingPlanClothing == -1 && remaingPlanAccessory > 1) {
            isSwapingIsDoneinLastRentalOrder = true
            remaingPlanClothing = 0
            remaingPlanAccessory = remaingPlanAccessory - 2
        }

        if(remaingPlanAccessory < 0 && remaingPlanClothing >= 1) { 
            if(remaingPlanAccessory == -1) {
                isSwapingIsDoneinLastRentalOrder = true
                remaingPlanAccessory = 1
                remaingPlanClothing = remaingPlanClothing - 1
            }
            else if(remaingPlanAccessory == -2) {
                isSwapingIsDoneinLastRentalOrder = true
                remaingPlanAccessory = 0
                remaingPlanClothing = remaingPlanClothing - 1
            }
        }
    }

    clothingItemDiff = clothingItems - remaingPlanClothing;
    accesoriesItemDiff = accesoriesItems - remaingPlanAccessory
   
    let isSwapClothing = false
    let isSwapAccessory = false
    let isSwapAccessoryBeforeRentalLimitExceed = false
   
   

    if(newPlan != undefined && (clothingItemDiff > 0 || accesoriesItemDiff > 0) && isSwapingIsDoneinLastRentalOrder == false) {
        let clothAdd = 0;
        let accAdd = 0;
        if(clothingItemDiff > 0 && accesoriesItemDiff < -1) {
            clothAdd = Math.floor(Math.abs(accesoriesItemDiff)/2)
            clothingItemDiff = clothingItemDiff-clothAdd
            accesoriesItemDiff = accesoriesItemDiff + (clothAdd*2)
            isSwapClothing = true
        }

        if(accesoriesItemDiff > 0  && clothingItemDiff < 0) { 
            accAdd = 2
            accesoriesItemDiff = accesoriesItemDiff-accAdd
            clothingItemDiff = clothingItemDiff + 1
            isSwapAccessory = true
        }
        // else if(accesoriesItemDiff > 0 && clothingItemDiff >= 0 && clothingItemDiff <= addedItem && remaingPlanClothing > 0) {
        //     accAdd = 2
        //     accesoriesItemDiff = accesoriesItemDiff-accAdd
        //     clothingItemDiff = clothingItemDiff + 1
        //     remaingPlanClothing = remaingPlanClothing - 1
        //     remaingPlanAccessory = remaingPlanAccessory + 2
        //     isSwapAccessoryBeforeRentalLimitExceed = true
        // }
    }

    let extraClothingCount = 0
    let extraAccessoryCount = 0
    for(let i = 0;i<addedItem;i++) {
        if(clothingItemDiff > 0 && addedItem) { 
            remainingAddedItems--
            clothingItemDiff = clothingItemDiff  - 1
            extraClothingCount += 1
        }
        else if (accesoriesItemDiff > 0 && addedItem ) {
            remainingAddedItems--
            accesoriesItemDiff =  accesoriesItemDiff - 1
            extraAccessoryCount += 1
        }
    }

    let addionalItmeUsed = (addedItem-remainingAddedItems)
    let diffobject = {"clothingItemDiff":clothingItemDiff,"accesoriesItemDiff":accesoriesItemDiff,"remainingAddedItems":parseInt(remainingAddedItems),"addionalItmeUsed":addionalItmeUsed, "extraClothingCount":extraClothingCount, "extraAccessoryCount": extraAccessoryCount, "remaingPlanClothing":remaingPlanClothing, "remaingPlanAccessory":remaingPlanAccessory, "isSwapClothing":isSwapClothing, "isSwapAccessory": isSwapAccessory, "isSwapAccessoryBeforeRentalLimitExceed":isSwapAccessoryBeforeRentalLimitExceed, "isSwapingIsDoneinLastRentalOrder": isSwapingIsDoneinLastRentalOrder };
    
    return diffobject
}
const addProductToCart = (triggerHeaderCartPopper, productData,self)=>{
    let _this = self;
    let cartObj = productData
    let customer_token = jsCookie.get('customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch,getstate) => {
    await fetch(Gconfig.fraudAPI+'Cart/AddCart', {
     method:'POST',
     headers: {'Authorization': 'Bearer '+customer_token,'Content-Type': 'application/json','X-Request-For': customer_ip, 'guid': request_guid},
     body: JSON.stringify(cartObj)
     }) .then(response => response.json()).then(data => {
        let cartData =  remainingQuantityOfItemsInCart(data.cart)
        dispatch(fetchCartSuccess(cartData))
        //  dispatch(fetchCartSuccess(data.cart))
         if(data.success == false){
             if(data.message.indexOf('already exists in cart') > -1){
                  _this.setState({alreadyExistProduct: 'This size has already been added to your shopping bag.'});
             }else{
                _this.setState({alreadyExistProduct: 'Oh no! This size just got snagged :('});
             }
            setTimeout(() => _this.setState({alreadyExistProduct: ''}) , 3000);
         }else{
            _this.setState({alreadyExistProduct: false});
            if(cartObj.cartItemType == 1){
                _this.setState({ saveFroLaterSize: false })
            }
            if(data.cart.customer_guid)
            jsCookie.set('Customer_guid', data.cart.customer_guid); 

            triggerHeaderCartPopper();

            // document.getElementsByClassName("fp-cart-menu")[0].style.display = "block";
            // setTimeout(function () { if(document.getElementsByClassName("fp-cart-menu")[0])document.getElementsByClassName("fp-cart-menu")[0].removeAttribute('style') }, 2000);
            if(Gconfig.environment == 'production' && productData.cartItemType == 2 && location.host == 'www.fashionpass.com'){
                // console.log("--------------- fb helper AddToCart")
                import('react-facebook-pixel').then((x) => x.default).then((ReactPixel) => {
                    ReactPixel.init(fpPixel);
                    ReactPixel.trackCustom("AddToCart","");
                });
            }
            let product = getstate().product
            if(Gconfig.environment == 'production' && location.host == 'www.fashionpass.com'){
                if(ttq){
                    ttq.track('AddToCart',{ content_id: productData.sku_id,quantity: 1,currency: 'USD'})
                }
                if(gtag){
                    gtag({
                        event: "add_to_cart",
                        ecommerce: {
                            // currency: "USD",
                            // value: product && product.product_detail && product.product_detail.product_info &&product.product_detail.product_info.retail,
                            items: [
                                {
                                    item_id: productData.sku_id,
                                    item_name: product && product.product_detail && product.product_detail.product_info&&product.product_detail.product_info.product_title,
                                    quantity: 1,
                                    item_type:productData.cartItemType==2?'purchase':'rent'
                                }
                            ]
                        }
                    });
                }
            }
            let ourPicksNewIdCookie = cookie.get('ourPicksNewId')
            if(ourPicksNewIdCookie != undefined){
                if(ourPicksNewIdCookie == productData?.product_id){
                    if(window && window["FullS"]){
                        window["FullS"].event('added_to_cart', {
                            productId: ourPicksNewIdCookie
                        });
                        cookie.remove('ourPicksNewId')
                    }
                }else{
                    cookie.remove('ourPicksNewId')
                }
            }
         }
     })
    }
}

const addProductToCartFromFunctionComp = (triggerHeaderCartPopper, productData, setMsg, fromFunctionalComp, from, removeSaveForLater, isLoading) => {
    let _this = self;
    let cartObj = productData
    let customer_token = jsCookie.get('customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch,getstate) => {
    await fetch(Gconfig.fraudAPI+'Cart/AddCart', {
     method:'POST',
     headers: {'Authorization': 'Bearer '+customer_token,'Content-Type': 'application/json','X-Request-For': customer_ip, 'guid': request_guid},
     body: JSON.stringify(cartObj)
     }) .then(response => response.json()).then(data => {
        let cartData =  remainingQuantityOfItemsInCart(data.cart)
        dispatch(fetchCartSuccess(cartData))
         if(data.success == false) {
             if(data.message.indexOf('already exists in cart') > -1){
                  if(fromFunctionalComp) { 
                    if(isLoading) {isLoading(false)}
                    if(removeSaveForLater != undefined) {
                        setMsg({type:from, msg:"This item is already in your cart."});
                    }
                    else {
                        setMsg({type:from, msg:"This size has already been added to your shopping bag."});
                    }
                    setTimeout(() => setMsg({type:"", msg:""}) , 3000);
                    return 
                  }
             }else{
                if(fromFunctionalComp) { 
                    if(isLoading) {isLoading(false)}
                    setMsg({type:from, msg:"Oh no! This size just got snagged :("}); 
                    setTimeout(() => setMsg({type:"", msg:""}) , 3000);
                    return 
                  }
             }
         }else{
            if(data.cart.customer_guid)
            jsCookie.set('Customer_guid', data.cart.customer_guid); 

            if(removeSaveForLater != undefined) {
                removeSaveForLater(productData.product_id)
            }

            triggerHeaderCartPopper();

            if(Gconfig.environment == 'production' && productData.cartItemType == 2 && location.host == 'www.fashionpass.com'){
                import('react-facebook-pixel').then((x) => x.default).then((ReactPixel) => {
                    ReactPixel.init(fpPixel);
                    ReactPixel.trackCustom("AddToCart","");
                });
            }
            let product = getstate().product
            if(Gconfig.environment == 'production' && location.host == 'www.fashionpass.com'){
                if(ttq){
                    ttq.track('AddToCart',{ content_id: productData.sku_id,quantity: 1,currency: 'USD'})
                }
                if(gtag){
                    gtag({
                        event: "add_to_cart",
                        ecommerce: {
                            items: [
                                {
                                    item_id: productData.sku_id,
                                    item_name: product && product.product_detail && product.product_detail.product_info&&product.product_detail.product_info.product_title,
                                    quantity: 1,
                                    item_type:productData.cartItemType==2?'purchase':'rent'
                                }
                            ]
                        }
                    });
                }
            }
         }
     })
    }
}


const addMultipleProductToCart = (triggerHeaderCartPopper, productDataArr, self, fromStylishPick, from) => {
    let cartObj = productDataArr
    let customer_token = jsCookie.get('customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch,getstate) => {
    await fetch(Gconfig.fraudAPI+'Cart/AddMultipleCart', {
     method:'POST',
     headers: {'Authorization': 'Bearer '+customer_token,'Content-Type': 'application/json','X-Request-For': customer_ip, 'guid': request_guid},
     body: JSON.stringify(cartObj)
     }) .then(response => response.json()).then(data => {
        let cartData =  remainingQuantityOfItemsInCart(data.cart)
        dispatch(fetchCartSuccess(cartData))
        //  dispatch(fetchCartSuccess(data.cart))
         if(data.success == false){
             if(data.message.indexOf('already exists in cart') > -1){
                  if(fromStylishPick) { 
                    self({type:from, msg:"This size has already been added to your shopping bag."}); 
                    setTimeout(() => self({type:"", msg:""}) , 3000);
                    return 
                  }
             }else{
                if(fromStylishPick) { 
                    self({type:from, msg:"Oh no! This size just got snagged :("}); 
                    setTimeout(() => self({type:"", msg:""}) , 3000);
                    return 
                }
             }
         }else{
            if(data.cart.customer_guid)
            jsCookie.set('Customer_guid', data.cart.customer_guid); 

            triggerHeaderCartPopper();

            let product = getstate().product
            if(Gconfig.environment == 'production' && location.host == 'www.fashionpass.com'){
                if(ttq) {
                    cartObj.length > 0 && cartObj.map((productData) => {
                        ttq.track('AddToCart',{ content_id: productData.sku_id,quantity: 1,currency: 'USD'})
                    })
                }
                if(gtag){
                    gtag({
                        event: "add_to_cart",
                        ecommerce: {
                            // currency: "USD",
                            // value: product && product.product_detail && product.product_detail.product_info &&product.product_detail.product_info.retail,
                            items: [
                                {
                                    item_id: productData.sku_id,
                                    item_name: product && product.product_detail && product.product_detail.product_info&&product.product_detail.product_info.product_title,
                                    quantity: 1,
                                    item_type:productData.cartItemType==2?'purchase':'rent'
                                }
                            ]
                        }
                    });
                }
            }
         }
     })
  }
}

const removeProductFromCart = (productData,markLoading,currentState)=>{
    if(markLoading == undefined){
        markLoading = true;
    }
    let cartObj = productData
    let customer_token = jsCookie.get('customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch) => {
        if(markLoading){
            dispatch(fetchCartStarted());
        }
    
    fetch(Gconfig.fraudAPI+'Cart/RemoveItemFromCart', {
     method:'POST',
     headers: {'Authorization': 'Bearer '+customer_token,'Content-Type': 'application/json','X-Request-For': customer_ip, 'guid': request_guid},
     body: JSON.stringify(cartObj)
     }) .then(response => response.json()).then(data => {
        // console.log(data)
        if(Gconfig.environment == 'production' && location.host == 'www.fashionpass.com'){
            if(gtag){
                gtag({
                    event: "remove_from_cart",
                    ecommerce: {
                    items: [
                        {
                            item_id: productData.sku_id,
                            quantity: 1,
                        }
                    ]
                    }
                });
            }
        }
        let cartData =  remainingQuantityOfItemsInCart(data.cart)
        dispatch(fetchCartSuccess(cartData))
        //  dispatch(fetchCartSuccess(data.cart))
        dispatch(fetchCartEnded)
        if(currentState)
        currentState.setState({deleteProduct:true})
     })
    }
}
const markCartLoading=()=>{
    return async (dispatch) =>{
        dispatch(fetchCartStarted())
    }
}
const markCartLoaded=()=>{
    return async (dispatch) =>{
        dispatch(fetchCartEnded())
    }
}
const getCustomerCart = () =>{
    let customer_token =  getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let Customer_guid = ''
    if(customer_token == 'not_login' && cookie.get('Customer_guid') != undefined){
        Customer_guid = '?Customer_guid='+cookie.get('Customer_guid');
    }else{
        if(cookie.get('Customer_guid') && cookie.get('Customer_guid') != undefined){
            Customer_guid = '?Customer_guid='+cookie.get('Customer_guid');
            cookie.remove('Customer_guid');
        }
    }
    
    // if(customer_token != undefined){
        return async (dispatch) => {
            let resData = {}
            await fetch(Gconfig.fraudAPI+`Cart/GetFromCart${Customer_guid}`, {
                method:'GET',
                headers: {'Authorization': 'Bearer '+customer_token,'X-Request-For':customer_ip, 'guid': request_guid}
                }) .then(response => response.json()).then(data => {
                if(data.success){
                    let cartData =  remainingQuantityOfItemsInCart(data.cart)
                    dispatch(fetchCartSuccess(cartData))
                    resData = cartData
                    //  dispatch(fetchCartSuccess(data.cart))
                }else{
                    dispatch(fetchCartEnded())
                }
            })
            return resData
        }
    // }
}
const getProductInfoByIds = async (ids,isPlan) => {
    let customer_token =  getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if(customer_token != undefined){
            return fetch(Gconfig.fpAPI+`Product/GetProductsInfo/${ids}/0/${isPlan}`, {
                method:'GET',
                headers: {'Authorization': 'Bearer '+customer_token,'X-Request-For':customer_ip, 'guid': request_guid}
                }) .then(response => response.json()).then(data => {
                return data
            })
    }
}
const updateItemsCartFromCart = (data) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch) => {
        let resData = {}
        dispatch(fetchCartStarted());
        await fetch(Gconfig.fraudAPI + 'Cart/AddOrRemoveItemsFromCart  ', {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json', 'X-Request-For': customer_ip, 'guid': request_guid },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            if(data){
                dispatch(fetchCartEnded());
                if(data.success){
                    let cartData =  remainingQuantityOfItemsInCart(data.cart)
                    dispatch(fetchCartSuccess(cartData))
                    //  dispatch(fetchCartSuccess(data.cart))
                }
            }
             resData = data
        })
        return resData
    }
} 
const updatePasswordForTwoStep = (data) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Cart/UpdatePasswordForTwoStep', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json', 'X-Request-For': customer_ip, 'guid': request_guid },    
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        return data
    })
} 
const addSaveLaterProduct = (data) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Cart/AddSaveForLater', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json', 'X-Request-For': customer_ip, 'guid': request_guid },    
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        return data
    })
} 

const updateSaveLaterProduct = (data) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    
    return fetch(Gconfig.fpAPI + 'Cart/UpdateSaveForLater', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json', 'X-Request-For': customer_ip, 'guid': request_guid },    
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        return data
    })
} 

const getSaveForLater = () =>{
    let customer_token =  getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if(customer_token != undefined){
            return fetch(Gconfig.fpAPI+`cart/GetSaveForLater`, {
                method:'GET',
                headers: {'Authorization': 'Bearer '+customer_token,'X-Request-For':customer_ip, 'guid': request_guid}
                }) .then(response => response.json()).then(data => {
                return data
            })
    }
}

const getSaveForLaterItemCount = () => {
    let customer_token =  getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    
    if(customer_token != undefined) {
        return async (dispatch) => {
            await fetch(Gconfig.fpAPI+`cart/GetSaveForLaterCount`, {
                    method:'GET',
                    headers: {'Authorization': 'Bearer '+customer_token,'X-Request-For':customer_ip, 'guid': request_guid}
                }).then(response => response.json()).then(res => {
                    if(res.success == true) {
                        let obj = {totalCount: res.result}
                        dispatch(fetchCartSaveLaterSuccess(obj))
                    }
                    else {
                        let obj = {totalCount: 0}
                        dispatch(fetchCartSaveLaterSuccess(obj))
                    }
            })  
      }
   }
}

const updateSaveLaterSortOrder = (data) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    // let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Cart/UpdateSaveForLaterOrder', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json', 'X-Request-For': customer_ip,},    
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        return data
    })
} 

const removeMultipleItemFromCart = (data, cart) => {
    let customer_token = jsCookie.get('customer_token');
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch) => {
    let resData = {}
    await fetch(Gconfig.fpAPI + 'Cart/RemoveAllItemFromCart', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json', 'X-Request-For': customer_ip, 'guid': request_guid},    
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        if(data.success && data.cart){
            let cartData =  remainingQuantityOfItemsInCart(data.cart)
            dispatch(fetchCartSuccess(cartData))
            resData = data
        }
    })
    return resData
 }
} 
const replaceItemInCart = (data, cart) => {
    let customer_token = jsCookie.get('customer_token');
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch) => {
    let resData = {}
    await fetch(Gconfig.fraudAPI + 'Cart/UpdateCartItem', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json', 'X-Request-For': customer_ip, 'guid': request_guid},    
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        if(data.success && data.cart) {
            let cartData =  remainingQuantityOfItemsInCart(data.cart)
            dispatch(fetchCartSuccess(cartData))
            resData = data
        }
    })
    return resData
 }
} 

function addingHoldItemIntoCart(obj) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch) => {
        let resData = {}
        await fetch(Gconfig.fraudAPI + `Cart/AddHoldItemToCart`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid,  },
            body: JSON.stringify(obj)
        }).then(response => response.json()).then(data => {
            if(data.success && data.cart) {
                let cartData =  remainingQuantityOfItemsInCart(data.cart)
                dispatch(fetchCartSuccess(cartData))
                //  dispatch(fetchCartSuccess(data.cart))   
            }
            resData = data;
        });
        return resData
    }
}

 export {addProductToCart,getCustomerCart,removeProductFromCart,
        markCartLoading,markCartLoaded,checkCartItemsAgainstPlan,getProductInfoByIds,updateItemsCartFromCart,updatePasswordForTwoStep,addSaveLaterProduct,updateSaveLaterProduct, getSaveForLater, updateSaveLaterSortOrder,
        getSaveForLaterItemCount,addMultipleProductToCart,addProductToCartFromFunctionComp,removeMultipleItemFromCart, replaceItemInCart, addingHoldItemIntoCart}
