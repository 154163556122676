import { FETCH_PRODUCT_REVIEWS, FETCH_PRODUCT_REVIEWS_SUCCESS } from '@/redux/actions/productReviewActions';
import { HYDRATE } from 'next-redux-wrapper';
import { productReviewsDataType } from '@/services/review';
import { AnyAction } from 'redux';

const initialState: ProductReviewSliceDataType = {
  loading: true,
  error: false,
  productID: null,
  reviews: null,
  pages: 0,
  loadingCalled: false,
};

function productReviewData(state: ProductReviewSliceDataType = initialState, action: AnyAction): ProductReviewSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.productReviews };
    case FETCH_PRODUCT_REVIEWS:
      return { ...state, loading: true, loadingCalled: true };
    case FETCH_PRODUCT_REVIEWS_SUCCESS:
      return { ...state, loading: false, reviews: action.payload.reviews, productID: action.payload.productID, pages: action.payload.page };
    default:
      return state;
  }
}

export default productReviewData;

export type ProductReviewSliceDataType = {
  loading: boolean;
  error: boolean;
  productID: string | null;
  reviews: productReviewsDataType | null;
  pages: number;
  loadingCalled: boolean;
};
