import { SessionStorageKeys, SessionStorageKeysType } from '@/constants/session-storage-keys';

export const SetInSessionStorage = <T extends SessionStorageKeys>(key: T, val: SessionStorageKeysType[T]) => sessionStorage.setItem(key, typeof val === 'string' ? val : JSON.stringify(val));
export const GetFromSessionStorage = <T extends SessionStorageKeys>(key: T, defaultVal?: SessionStorageKeysType[T]) => {
  const val = sessionStorage.getItem(key);
  if (val === null) return defaultVal;
  else {
    try {
      return JSON.parse(val) as SessionStorageKeysType[T];
    } catch (e) {
      return val as SessionStorageKeysType[T];
    }
  }
};
