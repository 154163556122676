export enum LocalStorageKeys {
  ORDER_TAX = 'orderTax',
  GUEST_DATA = 'guestData',
  IS_PRODUCT_PAGE = 'isProductPage',
  FYI_CARD_HIDDEN = 'fyi_card_hidden',
  Y_SCROLL_POSITION = 'YscrollPosition',
  FAV_CHANGE = 'fav_change',
  SIGNUP_FIRE = 'signUpFire',
}

export type LocalStorageKeysType = {
  [LocalStorageKeys.ORDER_TAX]: number;
  [LocalStorageKeys.GUEST_DATA]: string;
  [LocalStorageKeys.IS_PRODUCT_PAGE]: string;
  [LocalStorageKeys.FYI_CARD_HIDDEN]: string;
  [LocalStorageKeys.Y_SCROLL_POSITION]: string;
  [LocalStorageKeys.FAV_CHANGE]: string;
  [LocalStorageKeys.SIGNUP_FIRE]: boolean;
};
