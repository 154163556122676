import { SystemSettingsSliceDataType } from '@/redux/reducers/systemSettingsReducer';
import { GetSystemSettingByIdList_ResponseType, SystemSettingsType } from '@/services/website-settings';

export const AddNewSystemSettingsAndRemoveDuplicateOnes = (systemSettingsData: SystemSettingsSliceDataType, systemSettingsApiResponse: GetSystemSettingByIdList_ResponseType): SystemSettingsType[] => {
  const processedSystemSettings = systemSettingsData.systemSettings || [];
  if (systemSettingsApiResponse) {
    systemSettingsApiResponse.system_settings.system_settings.forEach((item) => {
      const alreadyExistIndex = processedSystemSettings.findIndex((innerItem) => innerItem.setting_id === item.setting_id);
      if (alreadyExistIndex !== -1) processedSystemSettings[alreadyExistIndex] = item;
      else processedSystemSettings.push(item);
    });
  }
  return processedSystemSettings;
};
