import { configDataType, REQUEST_CONFIG } from '@/services/config';
import { LogError, LogInfo } from '@/utils/dev-logs';
import NodeCache from 'node-cache';
import fetch from 'isomorphic-unfetch';
import { Environment } from '@/constants/global-config';
import { GetCookie, GetFromLocalStorage, RemoveFromLocalStorage, SetCookie } from '@/libraries/browser-storage';
import { CookieKeys } from '@/constants/cookie-keys';
import { LocalStorageKeys } from '@/constants/local-storage-keys';
import { TextExistsInString } from '@/utils/string-methods';
import Gconfig from 'globalconfig';
const pako = require('pako');
export const GenericFetchGet = async <T>(url: string, config: configDataType, errorHandling: { title: string; message: string }, additionalConfig?: { isGetProductsByHandle: boolean; noCache: boolean }) => {
  try {
    const headers = REQUEST_CONFIG(config).headers;
    if (additionalConfig?.isGetProductsByHandle) {
      const response = await GenericFetch_GetProductsByHandle(url, { method: 'GET', headers }, additionalConfig.noCache);
      return response as T;
    } else {
      const response = await GenericFetch(url, { method: 'GET', headers });
      return response as T;
    }
  } catch (error) {
    // return response as T;
    // LogError(errorHandling.title, errorHandling.message, error);
    return {success:false}
  }
};

//////////////////// CacheService Class \\\\\\\\\\\\\\\\\\\\

class CacheService {
  ttlinternal: number = 0;
  memoryCache: boolean = true;
  cache: NodeCache;

  constructor(ttlSeconds: number) {
    this.ttlinternal = 1200;
    this.cache = new NodeCache({ stdTTL: ttlSeconds, checkperiod: 2, useClones: true });
  }

  async get(key: NodeCache.Key, RunQuery: string, storeFunction: Function, noCache?: boolean) {
    const startDate = new Date();

    const env = Environment === 'production' ? 'live' : Environment === 'stage' ? 'stage' : 'dev';
    const processedKey = `${key}_${env}_${'azeem'}`;

    const isFav = Boolean(TextExistsInString(RunQuery, 'only_customer_favs') || TextExistsInString(RunQuery, 'other_filter=only_board_id'));
    let value: any = isFav ? null : this.cache.get(processedKey);

    const authToken = GetCookie(CookieKeys.CUSTOMER_TOKEN);
    if (authToken && TextExistsInString(RunQuery, 'filter_tags_any_list')) {
      SetCookie(CookieKeys.CACHE_COLLECTION_KEY, processedKey);
      if (GetFromLocalStorage(LocalStorageKeys.FAV_CHANGE) && GetFromLocalStorage(LocalStorageKeys.FAV_CHANGE) === processedKey) {
        value = null;
        this.cache.del(processedKey);
        RemoveFromLocalStorage(LocalStorageKeys.FAV_CHANGE);
      }
    } else if (!value && this.memoryCache && !isFav) {
      value = await this.getLambda({ key: processedKey, expirationInHours: this.ttlinternal, operationType: 'get', environment: env });
      // if (typeof window === 'undefined' && RunQuery.indexOf('GetSystemSetting') == -1) {
        // console.log('azeem data here ------------------>>>>>>>>>>>>>>>',value)
        if(RunQuery.indexOf('collections/SearchByHandle') > -1 && value && value?.dataZip){
          let decom = decompressPako(value.product_list)
          value.product_list = JSON.parse(decom)
          // console.log('azeem data here Pako ------------------>>>>>>>>>>>>>>>',value)
        }
      // }
      if (typeof window === 'undefined' && RunQuery.indexOf('GetSystemSettingByIdList') > -1) {
        // console.log('azeem data here2 ------------------>>>>>>>>>>>>>>>',decompressed)
        this.cache.set(processedKey, value);
      }    
      if(value && value.type == "Buffer"){
        const compressed = await gzip('Hello World');
        const decompressedi = await ungzip(compressed);
        console.log(decompressedi)
        let decompressed = await ungzip(value)
        console.log(decompressed.toString())
      }
    }
    const endDate = new Date();
    const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds > 6 || (value && !value.success)) {
      if (value && !value.success) {
        value = this.cache.get(processedKey);
      }
      this.memoryCache = false;
    }
    if (value && !authToken) return Promise.resolve(value);
    else {
      return storeFunction().then(async(result: any) => {
        if (!this.memoryCache || isFav){ this.cache.set(processedKey, result);}
        else {
          if (!authToken){

            let compressed = result
            if(RunQuery.indexOf('collections/SearchByHandle') > -1){
              let compressData = compressPako(JSON.stringify(compressed.product_list))
              // let decompo = decompressPako(compressData)
              // console.log(decompo,'value pako decompress here ---------------?>>>>>>>>>>')
              compressed.product_list = compressData
              compressed.dataZip = true
              // console.log(result,'value pako here ---------------?>>>>>>>>>>')
              // compressed =  JSON.stringify(compressed)
            }
            this.setLambda({ key: processedKey, expirationInHours: this.ttlinternal, operationType: 'set', environment: env, value:  JSON.stringify(compressed)});
          } 
          if (typeof window === 'undefined' && RunQuery.indexOf('GetSystemSettingByIdList') > -1) {
            this.cache.set(processedKey, result);
          }
        }
        if(result?.dataZip){
          result.product_list = JSON.parse(decompressPako(result.product_list))

        }
        return result;
      });
    }
  }

  async getLambda(data: accessLambdaFunctionGetArgs) {
    return await accessLambdaFunction(data);
  }
  async setLambda(data: accessLambdaFunctionSetArgs) {
    accessLambdaFunction(data);
  }

  del(keys: NodeCache.Key) {
    this.cache.del(keys);
  }
  delStartWith(startStr: string = '') {
    if (startStr === '') return;

    const keys = this.cache.keys();
    for (const key of keys) {
      if (key.indexOf(startStr) === 0) this.del(key);
    }
  }

  flush() {
    this.cache.flushAll();
  }
}

//////////////////// GenericFetch & GetProductsByHandle Methods \\\\\\\\\\\\\\\\\\\\

const GenericFetch_ttl = 1 * 30 * 5; // 1 * 60 * 10; = 10 mins , 1*1*4 = 4 second ... do not make it less then 4 sec
const GenericFetch_CacheService = new CacheService(GenericFetch_ttl); // Create a new cache service instance
const GenericFetch = (RunQuery: string, options?: any) => {
  const forceCall = false;

  let key: number | string = RunQuery.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

  if (forceCall === false && options !== undefined && options.headers !== undefined && options.headers.Authorization !== undefined) {
    key = key + 'auth_data';
  }

  return GenericFetch_CacheService.get(key, RunQuery, async () => {
    return await fetch(RunQuery, options)
      .then(async (response) => await response.json())
      .then(async (data) => {
        return data;
      })
      .catch((error) => {
        LogError('Generic Fetch Failed', `Request was ${RunQuery}`, error);
      });
  });
};

const GenericFetch_GetProductsByHandle_ttl = 1 * 60 * 1; // cache for 1 min
const GenericFetch_GetProductsByHandle_CacheService = new CacheService(GenericFetch_GetProductsByHandle_ttl); // Create a new cache service instance
const GenericFetch_GetProductsByHandle = async (RunQuery: string, options: any | undefined, noCache: boolean) => {
  const key = RunQuery.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

  return GenericFetch_GetProductsByHandle_CacheService.get(
    key,
    RunQuery,
    async () => {
      return GenericFetch_GetProductsByHandle_CacheService.get(key, RunQuery, async () => {
        return await fetch(RunQuery, options)
          .then((response) => response.json())
          .then(async (data) => {
            return data;
          });
      });
    },
    noCache
  );
};

//////////////////// AccessLambdaFunction Method \\\\\\\\\\\\\\\\\\\\

// Helper method for Cache Service's get method
type accessLambdaFunctionGetArgs = { key: string; expirationInHours: number; operationType: 'get'; environment: string };
type accessLambdaFunctionSetArgs = { key: string; expirationInHours: number; operationType: 'set'; environment: string; value: string,orignalResault:object };
export const accessLambdaFunction = async (data: accessLambdaFunctionGetArgs | accessLambdaFunctionSetArgs) => {
  try {
    // var formData = new FormData();
    // Object.entries(data).map((value, key) => formData.append(value[0], value[1]));
    return await fetch('https://pyoag27005.execute-api.us-west-1.amazonaws.com/MemCacheFunctionFrontEnd', {
      method: 'POST',
      headers: { "Access-Control-Allow-Origin":"https://www.fashionpass.com/",'Content-Type': 'application/octet-stream'},
      body: data,
    })
      .then((response) => response.json())  
      .then((data) => data)
      .catch((err) => {
        LogInfo('accessLambdaFunction inside method', 'error occured inside lambda functions POST request');
        return {};
      });
  } catch (e) {
    LogInfo('accessLambdaFunction method', "error occured during accessLambdaFunction's fetch - POST request");
    return {};
  }
};
 function compressPako (input:string) {
    const inputBuffer = new TextEncoder().encode(input);
    const outputBuffer = pako.deflate(inputBuffer);
    return Buffer.from(outputBuffer).toString('base64');
  };
  
  function decompressPako (input:string)  {
    const inputBuffer = Buffer.from(input, 'base64');
    const outputBuffer = pako.inflate(inputBuffer);
    return new TextDecoder().decode(outputBuffer);
  };