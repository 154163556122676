import { API, Plans, PlansDiscountedAmount } from '@/constants/global-config';
import { fetchRenderingConfigNotLogin, fetchRenderingConfigSuccess } from '@/redux/actions/renderingConfigActions';
import { StoreThunk } from '@/redux/store';
import { RenderingConfigSliceDataType } from '@/redux/reducers/renderingConfigReducer';
import { BTOA } from '@/utils/misc-methods';
import { TextExistsInString } from '@/utils/string-methods';
import { LogInfo } from '@/utils/dev-logs';

export const updateRenderingConfig = (updatingFrom: string): StoreThunk => {
  return async (dispatch, getState) => {
    const customerData = getState().customer;
    const renderingConfigData = getState().renderingConfig;
    if (customerData.loading) return;
    if (customerData.customer === 'not_login') {
      dispatch(fetchRenderingConfigNotLogin());
      return;
    }

    let userType: RenderingConfigSliceDataType['userType'] = 'subcribed_user';
    const show: RenderingConfigSliceDataType['show'] = {
      tanBar: false,
      topBar: true,
      favBtn: false,
      searchBar: true,
      navBar: true,
      indexHeader: true,
      indexHeaderImage: false,
      indexAsSeenOn: true,
      indexBrands: true,
      indexRentBox: true,
      indexClothingSlider: true,
      indexHIW: true,
      benifits: true,
      plans: true,
      googleReviews: true,
      indexCommunityImage: true,
      indexFAQs: true,
      indexInsta: true,
      blackfridayBar: false,

      indexRentOccasion: true,
      indexMemberLoved: true,
      indexNewArrival: true,
      indexMemberQoutes: true,
      indexMemberMeetup: true,
    };
    const buttons: RenderingConfigSliceDataType['buttons'] = {
      indexTopBarBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
      indexHeaderBtn: { text: 'TRY NOW', link: API.BASE_URL_WAITLIST_URL },
      indexPlansBtn: { text_home: 'Select plan', text_internal: 'Select plan', link: `${API.BASE_URL_WAITLIST_URL}?plan=` },
      indexReviewBtn: { text: `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}`, link: API.BASE_URL_WAITLIST_URL },
      indexVideoBtn: { text: `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}`, link: API.BASE_URL_WAITLIST_URL },
      indexCommunityBtn: { text: `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}`, link: API.BASE_URL_WAITLIST_URL },
      tryNowBtn: { text: '', link: API.BASE_URL_WAITLIST_URL },
    };
    const topBarDropdown: RenderingConfigSliceDataType['topBarDropdown'] = {
      account: false,
      orders: false,
      fedex: false,
      fav: false,
      credits: false,
      rewards: false,
      mycode: false,
      profile: false,
      logout: false,
      hiw:false,
    };

    // full subscribed
    if (customerData.customer.has_subscription) {
      show.favBtn = true;
      show.indexHeader = false;
      show.indexHeaderImage = true;
      show.indexAsSeenOn = false;
      show.indexBrands = false;
      show.indexRentBox = false;
      show.indexClothingSlider = false;
      show.indexHIW = false;
      show.benifits = false;
      show.plans = false;
      show.googleReviews = false;
      show.indexCommunityImage = false;
      show.indexFAQs = false;

      show.indexRentOccasion = true;
      show.indexMemberLoved = false; 
      show.indexNewArrival = false; 
      show.indexMemberQoutes = false;
      show.indexMemberMeetup = false;

      topBarDropdown.account = true;
      topBarDropdown.orders = true;
      topBarDropdown.fedex = true;
      topBarDropdown.fav = true;
      topBarDropdown.credits = true;
      topBarDropdown.rewards = true;
      topBarDropdown.mycode = true;
      topBarDropdown.logout = true;
      topBarDropdown.hiw = true;
    } else {
      const customerTag = customerData.customer.tag_list.join().toLowerCase();
      const waitlistURL = `${API.BASE_URL_WAITLIST_URL}?email=${BTOA(customerData.customer.email)}&loggedin=true`;

      userType = 'unsubscribed_user';
      topBarDropdown.profile = false;
      topBarDropdown.account = true;
      topBarDropdown.orders = true;
      topBarDropdown.fedex = true;
      topBarDropdown.fav = true;
      topBarDropdown.credits = true;
      topBarDropdown.logout = true;
      topBarDropdown.hiw = true;
      show.favBtn = true;

      // two step
      if (TextExistsInString(customerTag, 'waitlistjoined')) {
        show.tanBar = true;
        userType = 'two_step_user';
        topBarDropdown.profile = false;
        topBarDropdown.logout = true;
        buttons.indexVideoBtn.text = `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}`;
        buttons.indexHeaderBtn.text = 'TRY NOW';
        buttons.indexPlansBtn.text_home = 'TRY NOW';
        buttons.indexPlansBtn.text_internal = 'TRY NOW';
        // buttons.tryNowBtn.text = `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}`;
      }

      //paused
      if (TextExistsInString(customerTag, 'paused') && customerData.customer.last_order_id <= 0) {
        userType = 'paused_user';
        show.tanBar = false;
        buttons.indexVideoBtn.text = `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}`;
        buttons.indexPlansBtn.text_home = 'REACTIVATE';
        buttons.indexPlansBtn.text_internal = 'REACTIVATE';
        // buttons.tryNowBtn.text = `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}`;
      }

      //returned paused
      if (TextExistsInString(customerTag, 'paused') && customerData.customer.last_order_id > 0) {
        userType = 'return_paused_user';
        show.tanBar = false;
        buttons.indexReviewBtn.text = 'REACTIVATE';
        buttons.indexVideoBtn.text = 'REACTIVATE';
        buttons.indexCommunityBtn.text = 'REACTIVATE';
        buttons.indexPlansBtn.text_home = 'SELECT';
        buttons.indexPlansBtn.text_internal = 'REACTIVATE';
        // buttons.tryNowBtn.text = 'REACTIVATE';
      }

      buttons.indexHeaderBtn.link = waitlistURL;
      buttons.indexPlansBtn.link = `${waitlistURL}&loggedin_plan=`;
      buttons.indexReviewBtn.link = waitlistURL;
      buttons.indexCommunityBtn.link = waitlistURL;
      buttons.tryNowBtn.link = waitlistURL;
    }

    LogInfo('updateRenderingConfig method', `👤 ======> ${userType}`);
    dispatch(fetchRenderingConfigSuccess({ ...renderingConfigData, show, buttons, topBarDropdown, userType }));
  };
};

