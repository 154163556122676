// #region - Login & Authentication
export { postAuthenticate } from '@/services/customer/services';
export type { PostAuthenticate_ResponseType } from '@/services/customer/types';
export type { PostAuthenticate_ArgsType } from '@/services/customer/types';

export { getAuthenticateCustomerLogin } from '@/services/customer/services';
export type { GetAuthenticateCustomerLogin_ResponseType } from '@/services/customer/types';

export { getValidatePasswordResetToken } from '@/services/customer/services';
export type { GetValidatePasswordResetToken_ResponseType } from '@/services/customer/types';

export { postResetPassword } from '@/services/customer/services';
export type { PostResetPassword_ResponseType } from '@/services/customer/types';
export type { PostResetPassword_ArgsType } from '@/services/customer/types';

export { getRequestPasswordReset } from '@/services/customer/services';
export type { GetRequestPasswordReset_ResponseType } from '@/services/customer/types';

export { postUpdatePassword } from '@/services/customer/services';
export type { PostUpdatePassword_ResponseType } from '@/services/customer/types';
export type { PostUpdatePassword_ArgsType } from '@/services/customer/types';
// #endregion

// #region - Shipping Methods & Addresses
export { postAddCustomerAddress } from '@/services/customer/services';
export type { PostAddCustomerAddress_ResponseType } from '@/services/customer/types';
export type { PostAddCustomerAddress_ArgsType } from '@/services/customer/types';

export { getCustomerAddresses } from '@/services/customer/services';
export type { GetCustomerAddresses_ResponseType } from '@/services/customer/types';
export type { AddressDataType } from '@/services/customer/types';

export { postEditShippingAddress } from '@/services/customer/services';
export type { PostEditShippingAddress_ResponseType } from '@/services/customer/types';
export type { PostEditShippingAddress_ArgsType } from '@/services/customer/types';

export { getRemoveShippingAddress } from '@/services/customer/services';
export type { GetRemoveShippingAddress_ResponseType } from '@/services/customer/types';

export { postGetMatchedShippingMethods } from '@/services/customer/services';
export type { PostGetMatchedShippingMethods_ResponseType } from '@/services/customer/types';
export type { PostGetMatchedShippingMethods_ArgsType } from '@/services/customer/types';
export type { ShippingMethodsDataType } from '@/services/customer/types';

export { getSetDefaultShippingAddress } from '@/services/customer/services';
export type { GetSetDefaultShippingAddress_ResponseType } from '@/services/customer/types';
// #endregion

// #region - Orders
export { getOrder } from '@/services/customer/services';
export type { GetOrder_ResponseType } from '@/services/customer/types';

export { getOrdersWithPagination } from '@/services/customer/services';
export type { GetOrdersWithPagination_ResponseType } from '@/services/customer/types';

export { postGetOrderByOrdernameAndEmail } from '@/services/customer/services';
export type { PostGetOrderByOrdernameAndEmail_ResponseType } from '@/services/customer/types';
export type { PostGetOrderByOrdernameAndEmail_ArgsType } from '@/services/customer/types';
export type { PostGetOrderByOrdernameAndEmail_OrderType } from '@/services/customer/types';

export { getLastRentalOrder } from '@/services/customer/services';
export type { GetLastRentalOrder_ResponseType } from '@/services/customer/types';

export { postChargeRefundOrder } from '@/services/customer/services';
export type { PostChargeRefundOrder_ResponseType } from '@/services/customer/types';
export type { PostChargeRefundOrder_ArgsType } from '@/services/customer/types';
// #endregion

// #region - Payment-Billing Methods & Addresses
export { postAddPaymentMethodWithAddress } from '@/services/customer/services';
export type { PostAddPaymentMethodWithAddress_ResponseType } from '@/services/customer/types';
export type { PostAddPaymentMethodWithAddress_ArgsType } from '@/services/customer/types';

export { postUpdateBillingAddress } from '@/services/customer/services';
export type { PostUpdateBillingAddress_ResponseType } from '@/services/customer/types';
export type { PostUpdateBillingAddress_ArgsType } from '@/services/customer/types';

export { getDefaultPaymentMethod } from '@/services/customer/services';
export type { GetDefaultPaymentMethod_ResponseType } from '@/services/customer/types';
export type { PaymentMethodDataType } from '@/services/customer/types';

export { postRemovePaymentMethod } from '@/services/customer/services';
export type { PostRemovePaymentMethod_ResponseType } from '@/services/customer/types';
export type { PostRemovePaymentMethod_ArgsType } from '@/services/customer/types';

export { postEditPaymentMethod } from '@/services/customer/services';
export type { PostEditPaymentMethod_ResponseType } from '@/services/customer/types';
export type { PostEditPaymentMethod_ArgsType } from '@/services/customer/types';

export { postSetDefaultPaymentMethod } from '@/services/customer/services';
export type { PostSetDefaultPaymentMethod_ResponseType } from '@/services/customer/types';
export type { PostSetDefaultPaymentMethod_ArgsType } from '@/services/customer/types';

export { getAllPaymentMethods } from '@/services/customer/services';
export type { GetAllPaymentMethods_ResponseType } from '@/services/customer/types';
export type { GetAllPaymentMethods_PaymentMethodDataType } from '@/services/customer/types';

export { postAddPaymentMethod } from '@/services/customer/services';
export type { PostAddPaymentMethod_ResponseType } from '@/services/customer/types';
export type { PostAddPaymentMethod_ArgsType } from '@/services/customer/types';
// #endregion

// #region - Favorite Boards & Items
export { getFavoriteBoardsByProduct } from '@/services/customer/services';
export type { GetFavoriteBoardsByProduct_ResponseType } from '@/services/customer/types';

export { postRenameFavoriteBoard } from '@/services/customer/services';
export type { PostRenameFavoriteBoard_ResponseType } from '@/services/customer/types';
export type { PostRenameFavoriteBoard_ArgsType } from '@/services/customer/types';

export { getRemoveFavoriteBoard } from '@/services/customer/services';
export type { GetRemoveFavoriteBoard_ResponseType } from '@/services/customer/types';

export { getFavoriteBoardItems } from '@/services/customer/services';
export type { GetFavoriteBoardItems_ResponseType } from '@/services/customer/types';

export { getFavoriteItems } from '@/services/customer/services';
export type { GetFavoriteItems_ResponseType } from '@/services/customer/types';

export { postAddOrRemoveFavoriteBoardItem } from '@/services/customer/services';
export type { PostAddOrRemoveFavoriteBoardItem_ResponseType } from '@/services/customer/types';
export type { PostAddOrRemoveFavoriteBoardItem_ArgsType } from '@/services/customer/types';

export { getFavoriteBoards } from '@/services/customer/services';
export type { GetFavoriteBoards_ResponseType } from '@/services/customer/types';
export type { BoardDataType } from '@/services/customer/types';

export { postAddFavoriteBoard } from '@/services/customer/services';
export type { PostAddFavoriteBoard_ResponseType } from '@/services/customer/types';
export type { PostAddFavoriteBoard_ArgsType } from '@/services/customer/types';

export { postAddOrRemoveFavoriteItem } from '@/services/customer/services';
export type { PostAddOrRemoveFavoriteItem_ResponseType } from '@/services/customer/types';
export type { PostAddOrRemoveFavoriteItem_ArgsType } from '@/services/customer/types';
// #endregion

// #region - Customer Tags and Info
export { getCustomerTagsByEmail, getCustomerInfoByEmail } from '@/services/customer/services';
export type { GetCustomerTagsByEmail_ResponseType, getCustomerInfoByEmail_ResponseType } from '@/services/customer/types';

export { getCustomerTags } from '@/services/customer/services';
export type { GetCustomerTags_ResponseType } from '@/services/customer/types';

export { postUpdateCustomerTags } from '@/services/customer/services';
export type { PostUpdateCustomerTags_ArgsType } from '@/services/customer/types';
export type { PostUpdateCustomerTags_ResponseType } from '@/services/customer/types';
// #endregion

// #region - Customer Info
export { getAnyCustomerInfo } from '@/services/customer/services';
export type { GetAnyCustomerInfo_ResponseType } from '@/services/customer/types';

export { getCustomerInfo } from '@/services/customer/services';
export type { GetCustomerInfo_ResponseType } from '@/services/customer/types';

export { postUpdateProfileFields } from '@/services/customer/services';
export type { PostUpdateProfileFields_ResponseType } from '@/services/customer/types';
export type { PostUpdateProfileFields_ArgsType } from '@/services/customer/types';

export { postUpdateProfile } from '@/services/customer/services';
export type { PostUpdateProfile_ResponseType } from '@/services/customer/types';
export type { PostUpdateProfile_ArgsType } from '@/services/customer/types';

export { getCustomerReferCode } from '@/services/customer/services';
export type { GetCustomerReferCode_ResponseType } from '@/services/customer/types';
export type { ReferCodeDataType } from '@/services/customer/types';

export { getCreditPoints } from '@/services/customer/services';
export type { GetCreditPoints_ResponseType } from '@/services/customer/types';

export { postWaitlistcustomer } from '@/services/customer/services';
export type { PostWaitlistcustomer_ResponseType } from '@/services/customer/types';
export type { PostWaitlistcustomer_ArgsType } from '@/services/customer/types';

export { postInsertCustomerRelatedInformation } from '@/services/customer/services';
export type { PostInsertCustomerRelatedInformation_ResponseType } from '@/services/customer/types';
export type { PostInsertCustomerRelatedInformation_ArgsType } from '@/services/customer/types';

export { getCustomerSummary } from '@/services/customer/services';
export type { GetCustomerSummary_ResponseType } from '@/services/customer/types';
export type { CustomerSummaryDataType } from '@/services/customer/types';
// #endregion

// #region - Customer Preferences
export { getPreferences } from '@/services/customer/services';
export type { GetPreferences_ResponseType } from '@/services/customer/types';
export type { CustomerPreferences } from '@/services/customer/types';

export { postUpdatePreferences } from '@/services/customer/services';
export type { PostUpdatePreferences_ArgsType } from '@/services/customer/types';
export type { PostUpdatePreferences_ResponseType } from '@/services/customer/types';
// #endregion

// #region - Pause Information
export { getPauseInformation } from '@/services/customer/services';
export type { GetPauseInformation_ResponseType } from '@/services/customer/types';

export { getInsertPauseInformation } from '@/services/customer/services';
export type { GetInsertPauseInformation_ResponseType } from '@/services/customer/types';
// #endregion

// #region - Survey
export { getPausedServey } from '@/services/customer/services';
export type { GetPausedServey_ResponseType } from '@/services/customer/types';
// #endregion

// #region - Miscellaneous
export { getDropPoints } from '@/services/customer/services';
export type { GetDropPoints_ResponseType } from '@/services/customer/types';

export { postGetSubscriptionId } from '@/services/customer/services';
export type { PostGetSubscriptionId_ResponseType } from '@/services/customer/types';
export type { PostGetSubscriptionId_ArgsType } from '@/services/customer/types';

export { getHolidayMessagesByType } from '@/services/customer/services';
export type { GetHolidayMessagesByType_ResponseType } from '@/services/customer/types';

export { getCreateEventZendDesk } from '@/services/customer/services';
export type { GetCreateEventZendDesk_ResponseType } from '@/services/customer/types';

export { postSendEmailUserCredit } from '@/services/customer/services';
export type { PostSendEmailUserCredit_ResponseType } from '@/services/customer/types';
export type { PostSendEmailUserCredit_ArgsType } from '@/services/customer/types';
// #endregion

// #region - Gift Card Credit
export { postAddGiftCardCredit } from '@/services/customer/services';
export type { PostAddGiftCardCredit_ResponseType } from '@/services/customer/types';
export type { PostAddGiftCardCredit_ArgsType } from '@/services/customer/types';
// #endregion

// #region - Coupon
export { getEditCoupon } from '@/services/customer/services';
export type { GetEditCoupon_ResponseType } from '@/services/customer/types';

export { postCheckByPassCoupon } from '@/services/customer/services';
export type { PostCheckByPassCoupon_ResponseType } from '@/services/customer/types';
export type { PostCheckByPassCoupon_ArgsType } from '@/services/customer/types';
// #endregion

// #region - Signup
export { postCheckCustomerEmail } from '@/services/customer/services';
export type { postCheckCustomerEmail_ArgsType, postCheckCustomerEmail_ResponseType } from '@/services/customer/types';
// #endregion

// get customer order
export { customerallGetOrders } from '@/services/customer/services';
export type { GetCustomerOrder_type } from '@/services/customer/types';