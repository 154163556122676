import { StoreThunk } from '@/redux/store';
import { CategoriesNamespace } from '@/redux/reducers/categoryReducer';
import { IncomingMessage } from 'http';
import { fetchMainCatStarted, fetchMainCatSuccess, fetchSubcategoriesfaild, fetchSubcategoriesSuccess } from '@/redux/actions/categoryActions';
import { TextExistsInString } from '@/utils/string-methods';
import { getCategory } from '@/services/collections';
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import { DoesWindowExists } from '@/utils/window-methods';

export const getMainCategory = (categoryID: string, namespace: CategoriesNamespace = 'Def', url?: string, serverRequest?: IncomingMessage): StoreThunk => {
  return async (dispatch, getState) => {
    const categoryData = getState()[`cats${namespace}`];
    // if (categoryID === undefined || categoryID === 'main') categoryID = Gconfig.Parent;

    if (categoryData.currentCat !== null && categoryData.currentCat.sub_categories.length < 1) dispatch(fetchMainCatStarted(namespace));
    const depth = TextExistsInString(namespace, 'menu') ? 2 : 1;

    const response = await getCategory(categoryID, depth, { customerIP: true, guid: true, token: getAuthTokenfromcookie(serverRequest), contentType: 'application/json' });
    if (response?.success) {
      let processedUrl = url;

      try {
        if(processedUrl && processedUrl[0] == '/' && processedUrl[1] == '?'){
          processedUrl = '/'
        }
        if (DoesWindowExists()) {
          processedUrl = window.location.pathname;
          // let cc = processedUrl.split('/')[3];
          // if (cc === undefined || cc === '') processedUrl = '/';
        }
      } catch (error) {
        // this.setState({ addToCartClicked: false });
      }

      console.log('filteredSubCategories 1',  response.data.sub_categories)

      const filterSubCategoriesAccordingToThisVal = processedUrl !== undefined && processedUrl === '/' ? 'internal' : 'main';
      const filteredSubCategories = response.data.sub_categories.filter((item) => {
        if (TextExistsInString(item.name, filterSubCategoriesAccordingToThisVal)) return false;
        else return true;
      });

      // console.log('filteredSubCategories', filteredSubCategories)
      // filteredSubCategories.length > 0 && filteredSubCategories.map((item, ind) => {
      //   if(item.category_link == "how-it-works") {
      //     item.subcategory_count = 3
      //     let arr = [
      //       {
      //         name : "How it Works",
      //         category_link : "how-it-works",
      //         handle : "",
      //         linked_category : {
      //           handle : "how-it-works"
      //         }
      //       },
      //       {
      //         name : "About Us",
      //         category_link : "about-us",
      //         handle : "",
      //         linked_category : {
      //           handle : "about-us"
      //         }
      //       },
      //       {
      //         name : "FAQs",
      //         category_link : "faq",
      //         handle : "",
      //         linked_category : {
      //           handle : "faq"
      //         }
      //       }
      //     ]
      //     item.sub_categories = arr
      //   }
      // })

      response.data.sub_categories = filteredSubCategories;

      dispatch(fetchSubcategoriesSuccess(namespace, response.data.sub_categories));
      dispatch(fetchMainCatSuccess(namespace, response.data));
    } else {
      dispatch(fetchSubcategoriesfaild(namespace, true));
    }
  };
};
