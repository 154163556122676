import { CookieKeys, CookieKeysType } from '@/constants/cookie-keys';
import Cookies from 'js-cookie';

type cookieOptions = {
  expires?: number;
  path?: string;
  domain?: string;
};

export const GetAllCookies = () => Cookies.get();
export const HasCookie = <T extends CookieKeys>(cookieName: T) => !!Cookies.get(cookieName);

export const SetCookie = <T extends CookieKeys>(cookieName: T, cookieVal: CookieKeysType[T], options?: cookieOptions) => Cookies.set(cookieName, typeof cookieVal === 'string' ? cookieVal : JSON.stringify(cookieVal), options);

export const GetCookie = <T extends CookieKeys>(cookieName: T, defaultVal?: CookieKeysType[T]) => {
  const response = Cookies.get(cookieName);
  if (response === undefined || response === null) return defaultVal;
  else {
    try {
      return JSON.parse(response) as CookieKeysType[T];
    } catch (e) {
      return response as CookieKeysType[T];
    }
  }
};

export const RemoveCookie = <T extends CookieKeys>(cookieName: T, options?: cookieOptions) => {
  Cookies.remove(cookieName, options);
  return HasCookie(cookieName);
};

export const SetDynamicKeyCookie = <T extends string, K>(cookieName: T, cookieVal: K, options?: cookieOptions) => Cookies.set(cookieName, JSON.stringify(cookieVal), options);
export const GetDynamicKeyCookie = <T extends string, K>(cookieName: T, defaultVal?: K) => {
  const response = Cookies.get(cookieName);
  if (response === undefined || response === null) return defaultVal;
  else {
    try {
      return JSON.parse(response) as K;
    } catch (e) {
      return response as K;
    }
  }
};
