import { configDataType, REQUEST_CONFIG } from '@/services/config';
import { LogError } from '@/utils/dev-logs';
import axios from 'axios';
import { ConvertObjectToFormData, ConvertObjectToJson } from '@/libraries/request/functions/utils';

export const AxiosPost = async <T>(url: string, data: object, config: configDataType, errorHandling: { title: string; message: string }) => {
  try {
    const bodyData = config?.contentType === 'multipart/form-data' || config?.contentType === 'application/x-www-form-urlencoded' ? ConvertObjectToFormData(data) : ConvertObjectToJson(data);
    const response = await axios.post(url, bodyData, REQUEST_CONFIG(config));
    return response.data as T;
  } catch (error) {
    LogError(errorHandling.title, errorHandling.message, error);
  }
};

export const AxiosGet = async <T>(url: string, config: configDataType, errorHandling: { title: string; message: string }) => {
  try {
    const response = await axios.get(url, REQUEST_CONFIG(config));
    return response.data as T;
  } catch (error) {
    LogError(errorHandling.title, errorHandling.message, error);
  }
};
