import { CookieKeys } from '@/constants/cookie-keys';
import { API } from '@/constants/global-config';
import { GetCookie } from '@/libraries/browser-storage';

export const BASE_URL_FP_API: string | undefined = API.BASE_URL_FP_API;
export const BASE_URL_BANJO_API: string | undefined = API.BASE_URL_BANJO_API;
export const BASE_URL_BANJO_URL: string | undefined = API.BASE_URL_BANJO_URL;
export const BASE_URL_BANJO_REVIEW: string | undefined = API.BASE_URL_BANJO_REVIEW;
export const BASE_URL_UTILITY_API: string | undefined = API.BASE_URL_UTILITY_API;
export const BASE_URL_COLLECTION_API: string | undefined = API.BASE_URL_COLLECTION_API;
export const BASE_URL_REVIEW_API: string | undefined = API.BASE_URL_REVIEW_API;
export const BASE_URL_HAUTE_API: string | undefined = API.BASE_URL_HAUTE_API;
export const BASE_URL_WAITLIST_URL: string | undefined = API.BASE_URL_WAITLIST_URL;
export const BASE_URL_FRAUD_API: string | undefined = API.BASE_URL_FRAUD_API;

export type configDataType = {
  token?: string;
  customerIP?: boolean;
  contentType?: 'application/json' | 'application/x-www-form-urlencoded' | 'multipart/form-data';
  FashionPassAuth?: string;
  guid?: boolean;
  customerId?:string;
};

export const REQUEST_CONFIG = (configData: configDataType) => {
  const headers: Record<string, string> = {};

  if (!!configData.token) headers['Authorization'] = `Bearer ${configData.token}`;
  if (!!configData.customerIP) headers['X-Request-For'] = GetCookie(CookieKeys.IP_ADDRESS) || '';
  if (!!configData.contentType) headers['Content-Type'] = configData.contentType;
  if (!!configData.FashionPassAuth) headers['FashionPassAuth'] = configData.FashionPassAuth;
  if (!!configData.guid) headers['guid'] = GetCookie(CookieKeys.REQUEST_GUID) || '';

  return { headers };
};
