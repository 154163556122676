import Gconfig from 'globalconfig';

const isAppEnv_X = Boolean(process.env.NEXT_PUBLIC_APP_ENV === 'X');
const isAppEnv_Y = Boolean(process.env.NEXT_PUBLIC_APP_ENV === 'Y');

export const NoProductAlertRedirectTime = Gconfig.noProductAlertRedirectTime;
export const CC_Parent = Gconfig.ccParent as string | undefined;
export const Environment = Gconfig.environment as string | undefined;
export const FP_Pixel = Gconfig.fpPixel as string | undefined;

export const SystemSettings = {
  CUSTOMER_CREDIT: Gconfig.system_settings.customerCreadit as string | undefined,
  EARLY_CHECKIN: Gconfig.system_settings.earlyCheckin as string | undefined,
  ADDED_ITEM: Gconfig.system_settings.addedItem as string | undefined,
  ADDED_ITEM_2: Gconfig.system_settings.addedItem2 as string | undefined,
  ADDITIONAL_ITEM: Gconfig.system_settings.AdditionalItem as string | undefined,
  WAITLIST_ENABLE_DISABLE: Gconfig.system_settings.WaitlistEnableDisable as string | undefined,
  SIZE_TOGGLE_ID: Gconfig.system_settings.sizeTogleId as string | undefined,
  STYLIST_PICK: Gconfig.system_settings.stylistPick as string | undefined,
  BEST_SELLER: Gconfig.system_settings.bestSeller as string | undefined,
  MAINTENANCE_ENABLED: Gconfig.system_settings.maintenanceEnabled as string | undefined,
  MAINTENANCE_MESSAGE: Gconfig.system_settings.maintenanceMessage as string | undefined,
  PINKBAR_MESSAGE_TOGGLE: Gconfig.system_settings.pinkbarMessageToggle as string | undefined,
  CUTOFF_TIME: Gconfig.system_settings.cutoffTime as string | undefined,
  CHECKIN_TIME: Gconfig.system_settings.checkinTime as string | undefined,
  WINBACK_OFFER_MONTHS: Gconfig.system_settings.winbackOfferMonths as string | undefined,
  HOME_IMAGE_LINK: Gconfig.system_settings.homeImageLink as string | undefined,
  NON_SUB_DISCOUNT_DAYS: Gconfig.system_settings.nonsub_discount_days as string | undefined,
  FP_EXCLUSIVE: Gconfig.system_settings.fp_exclusive as string | undefined,
  FLASH_SALE: Gconfig.system_settings.flash_sale as string | undefined,
  DYNAMIC_URL: Gconfig.system_settings.dynamic_url as string | undefined,
  REQ_CARRIER_NOTES_ZIPCODE: Gconfig.system_settings.req_carrier_notes_zipcode as string | undefined,
  HOMEPAGE_IMAGE_SETTING: Gconfig.system_settings.homepageImageSetting as string | undefined,
  RENT_OCCASION: Gconfig.system_settings.rent_occasion as string | undefined,
  LOGIN_CAPTCHA_RETRIES: Gconfig.system_settings.loginCaptchaRetries as string | undefined,
  UTM_MARKETING_COUPENID: Gconfig.system_settings.utmMarketingCoupenId as string | undefined,
  AB_DATA_ID: Gconfig.system_settings.abTestingData as string | undefined,
  FAV_LEFT_BAR_DATA_ID:  Gconfig.system_settings.favLeftBarDataId as string | undefined,
  DYNAMIC_PROMO_CODES_SETTING: Gconfig.system_settings.dynamicPromoCodeSetting as string | undefined,
  CLEAR_CACHE_MOBILE_APP: Gconfig.system_settings.clearCacheMobileApp,
  HOME_FAV_SLIDER_TAG_ID:Gconfig.system_settings.homeFavSliderTagId,
  BACK_IN_STOCK_SLIDER_TAG_ID: Gconfig.system_settings.backInStockSliderTagId as string | undefined,
  TRENDING_SEARCHS_ID: Gconfig.system_settings.trendingSearchId as string | undefined,
  DISABLE_SMS_VERIFICATION_ID: Gconfig.system_settings.disableSMSVerificationId as string | undefined,
  AUTO_HIDE_THRESHOLD_ID: Gconfig.system_settings.autoHideThresholdID as string | undefined,
};

export const StaticPages = {
  TERMS_OF_SERVICE: Gconfig.static_pages.termsOfService as string | undefined,
  PRIVACY_POLICY: Gconfig.static_pages.privacyPolicy as string | undefined,
};

export const CollectionCategory = {
  ACCESSORIES_ID: Gconfig.collectionCategoryAccessoriesID as string | undefined,
  OCCASSIONS_ID: Gconfig.collectionCategoryOccasionsID as string | undefined,
};

export const ContentSettings = {
  DESKTOP_MENU: Gconfig.contentSettings.desktopMenu as string | undefined,
  MOBILE_MENU: Gconfig.contentSettings.mobileMenu as string | undefined,
  SUBSCRIBED_DESKTOP_MENU: Gconfig.contentSettings.subscribedDesktopMenu as string | undefined,
  SUBSCRIBED_MOBILE_MENU: Gconfig.contentSettings.subscribedMobileMenu as string | undefined,
  PRODUCT_CATEGORY_PARENT: Gconfig.contentSettings.productCategoryParent as string | undefined,
  INDEX_SLIDERS: Gconfig.contentSettings.indexSliders as string | undefined,
  ADDED_ITEM_1: Gconfig.contentSettings.addedItem1 as string | undefined,
  ADDED_ITEM_2: Gconfig.contentSettings.addedItem2 as string | undefined,
  ADDITIONAL_ITEM: Gconfig.contentSettings.additionalItem as string | undefined,
  PAGE_ID: {
    HOME: Gconfig.contentSettings.homePageId as string | undefined,
    HOW_IT_WORKS: Gconfig.contentSettings.howItWorksPageId as string | undefined,
    FREE_STUFF: Gconfig.contentSettings.freeStuffPageId as string | undefined,
    FAQ: Gconfig.contentSettings.faqPageID as string | undefined,
  },
};

export const API = {
  BASE_URL_FP_API: Gconfig.fpAPI as string | undefined,
  BASE_URL_BANJO_API: Gconfig.banjoAPI as string | undefined,
  BASE_URL_BANJO_URL: Gconfig.banjoURL as string | undefined,
  BASE_URL_UTILITY_API: Gconfig.utilityAPI as string | undefined,
  BASE_URL_COLLECTION_API: Gconfig.collectionAPI as string | undefined,
  BASE_URL_REVIEW_API: Gconfig.reviewApi as string | undefined,
  BASE_URL_HAUTE_API: Gconfig.hautApi as string | undefined,
  BASE_URL_WAITLIST_URL: Gconfig.waitlistURL as string | undefined,
  BASE_URL_BANJO_REVIEW: Gconfig.banjoReview,
  FASHION_PASS_S3_STORAGE_URL: Gconfig.fashionpassS3Storage,
  FASHION_PASS_IMAGE_STORAGE_URL: Gconfig.fashionpassImageStorage,
  SMB_API: Gconfig.smbAPI as string | undefined,
  BASE_URL_FRAUD_API: Gconfig.fraudAPI as string | undefined,
  FASHION_PASS_PRODUCT_IMAGE_URL: Gconfig.productImageUrl
};

export const DerivedAPI = {
  PATTERN_PRINTS_URL: Gconfig.paterns_printsURL as string | undefined,
  COLLECTION_API_URL: Gconfig.collectionAPIURL as string | undefined,
  COLLECTION_API_HANDLE_URL: Gconfig.collectionAPIHandleURL as string | undefined,
  COLLECTION_API_HANDLE_DYNAMIC_URL: Gconfig.collectionAPIHandleURLDynamic as string | undefined,
  COLLECTION_API_DYNAMIC_URL: Gconfig.collectionAPIURLDynamic as string | undefined,
  VENDOR_IMAGE_URI: Gconfig.vendor_imageURI as string | undefined,
};

export const SocialLinks = {
  FACEBOOK: Gconfig.facebook,
  INSTAGRAM: Gconfig.instagram,
  PINTEREST: Gconfig.pinterest,
  TWITTER: Gconfig.twitter,
  Tiktok: Gconfig.tiktok,
};

export const CollectionHandle = {
  CLOTHING: Gconfig.collection_handle.clothing,
  ACCESSORIES: Gconfig.collection_handle.accessories,
};

export const Fp_Plans = Gconfig.fp_plans as number;
export const PlansDiscountedAmount = Gconfig.plansDiscountedAmount;
export const Plans = {
  PLAN_01: Gconfig.plan[0],
  PLAN_02: Gconfig.plan[1],
  PLAN_03: Gconfig.plan[2],
};

export const GiftFixedAmounts = {
  GIFT_01: Gconfig.giftFixedAmounts[0],
  GIFT_02: Gconfig.giftFixedAmounts[1],
  GIFT_03: Gconfig.giftFixedAmounts[2],
  GIFT_04: Gconfig.giftFixedAmounts[3],
};

export const NonRegisteredGiftCardUser = {
  NAME: Gconfig.nonRegisteredGiftCardUser.name,
  STRIPE_ID: Gconfig.nonRegisteredGiftCardUser.stripe_id,
  CUSTOMER_ID: Gconfig.nonRegisteredGiftCardUser.customer_id as string | undefined,
  EMAIL_ID: Gconfig.nonRegisteredGiftCardUser.email_id as string | undefined,
};

export const WebsiteTagsList = Gconfig.webSiteTagList;

export const ColorConfig = Gconfig.colorConfig;

export const PatternsPrint = Gconfig.paterns_prints;

export const ProductGrayImageId = Gconfig.ProductGrayImageId as string | undefined;

export const NewArrivalTagId = Gconfig.newArrivalTagId as string | undefined;

export const FinalSaleTag = Gconfig.finalSaleTag as string | undefined;
