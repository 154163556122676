import { IncomingMessage } from 'http';
import { TextExistsInString } from '@/utils/string-methods';

export const isLocalhost = () => TextExistsInString('localhost', location.host) || TextExistsInString(location.host, '192.168.');

/** This method validates on server side weather we have opened app on mobile or not */
export const validateOnServerSideIsItMobileView = (serverRequest: IncomingMessage): boolean => {
  if (serverRequest.headers['user-agent'] === undefined) return false;

  const val = serverRequest.headers['user-agent'].match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);
  return Boolean(val !== null && val !== undefined && val.length !== 0);
};

/** Convert Data from Binary to ASCII */
export const BTOA = (data: string): string => {
  try {
    return btoa(data);
  } catch (err) {
    return Buffer.from(data).toString('base64');
  }
};

export const createRequestGuid = (): string => {
  const _p8 = (s: boolean = false) => {
    let p = (Math.random().toString(16) + '000000000').substr(2, 8);
    return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
  };

  return _p8() + _p8(true) + _p8(true) + _p8();
};
