import { SignupCustomerSliceDataType } from '@/redux/reducers/signupCustomerReducer';

export const FETCH_SIGNUP_CUSTOMER_SUCCESS = 'FETCH_SIGNUP_CUSTOMER_SUCCESS';
export const FETCH_SIGNUP_CUSTOMER_FAILURE = 'FETCH_SIGNUP_CUSTOMER_FAILURE';

export const fetchSignupCustomerSuccess = (customer: SignupCustomerSliceDataType['signupCustomer']) => ({
  type: FETCH_SIGNUP_CUSTOMER_SUCCESS,
  payload: customer,
});

export const fetchSignupCustomerFailure = (error: SignupCustomerSliceDataType['error']) => ({
  type: FETCH_SIGNUP_CUSTOMER_FAILURE,
  payload: error,
});
