import { ProductSliceDataType } from '@/redux/reducers/productReducer';

export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';

export const fetchproductSuccess = (product: ProductSliceDataType['product_detail']) => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: product,
});

export const fetchproduct = () => ({
  type: FETCH_PRODUCT,
});
