import { ParseHttpCookie } from '@/libraries/browser-storage';
import { LogError } from '@/utils/dev-logs';
import { IncomingMessage } from 'http';

export const getAuthTokenfromcookie = (serverRequest?: IncomingMessage): 'not_login' | string => {
  const isServer = Boolean(typeof serverRequest !== 'undefined');
  let customer_token = 'not_login';

  try {
    let cookies = '';
    if (isServer && serverRequest?.headers) cookies = serverRequest.headers.cookie || '';
    else {
      try {
        if (typeof document !== undefined) cookies = document !== undefined ? document.cookie : '';
      } catch (err) {
        LogError('getAuthTokenfromcookie Method - Inner', isServer ? 'Server Error' : 'Unhandled Error', err);
      }
    }

    if (cookies !== '' && typeof cookies === 'string') {
      const cookiesJSON = ParseHttpCookie(cookies);
      if (cookiesJSON.customer_token !== undefined) customer_token = cookiesJSON.customer_token;
    }
  } catch (err) {
    console.log('error ====>>>>'+err)
    LogError('GetAuthToken Method - Outer', isServer ? 'Server Error' : 'Unhandled Error', err);
  }

  return customer_token;
};
