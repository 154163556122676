import { FETCH_CUSTOMER_PAYMENTS_SUCCESS, FETCH_CUSTOMER_PAYMENTS_FAILURE, CUSTOMER_PAYMENT_MARK_LOADING, CUSTOMER_PAYMENT_MARK_LOADING_DONE } from '@/redux/actions/customerActions';
import { HYDRATE } from 'next-redux-wrapper';
import { GetAllPaymentMethods_PaymentMethodDataType } from '@/services/customer';
import { AnyAction } from 'redux';

const initialState: CustomerPaymentsSliceDataType = {
  paymentMethods: [],
  loading: false,
  error: false,
};

export default function customerPaymentsMethods(state: CustomerPaymentsSliceDataType = initialState, action: AnyAction): CustomerPaymentsSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.customerPaymentsMethods };
    case CUSTOMER_PAYMENT_MARK_LOADING:
      return { ...state, loading: true };
    case CUSTOMER_PAYMENT_MARK_LOADING_DONE:
      return { ...state, loading: false };
    case FETCH_CUSTOMER_PAYMENTS_SUCCESS:
      return { ...state, loading: false, paymentMethods: action.payload };
    case FETCH_CUSTOMER_PAYMENTS_FAILURE:
      return { ...state, loading: false, paymentMethods: [], error: true };
    default:
      return state;
  }
}

export type CustomerPaymentsSliceDataType = {
  loading: boolean;
  error: boolean;
  paymentMethods: GetAllPaymentMethods_PaymentMethodDataType[];
};
