import { CartSliceDataType } from '@/redux/reducers/cartReducer';

export const FETCH_CART_STARTED = 'FETCH_CART_STARTED';
export const FETCH_CART_ENDED = 'FETCH_CART_ENDED';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
export const TRIGGER_CART_POPPER = 'TRIGGER_CART_POPPER';
export const FETCH_CART_SAVED_LATER_COUNT = 'FETCH_CART_SAVED_LATER_COUNT'
export const fetchCartStarted = () => ({
  type: FETCH_CART_STARTED,
});

export const fetchCartEnded = () => ({
  type: FETCH_CART_ENDED,
});

export const fetchCartSuccess = (cart: CartSliceDataType['cart']) => ({
  type: FETCH_CART_SUCCESS,
  payload: cart,
});

export const fetchCartFailure = (error: CartSliceDataType['error']) => ({
  type: FETCH_CART_FAILURE,
  payload: error,
});

export const triggerCartPopper = (trigger: boolean) => ({
  type: TRIGGER_CART_POPPER,
  payload: trigger,
});

export const fetchCartSaveLaterSuccess = (saveLaterObj:any) => ({
  type: FETCH_CART_SAVED_LATER_COUNT,
  payload: saveLaterObj,
});


