import { FETCH_RECENT_PRODUCTS, SAVE_RECENT_PRODUCTS, FETCH_RECENT_PRODUCTS_SUCESS, SAVE_RECENT_PRODUCTS_SUCESS } from '@/redux/actions/recentProductActions';
import { HYDRATE } from 'next-redux-wrapper';
import { ProductDataType } from '@/services/product';
import { AnyAction } from 'redux';

const initialState: RecentProductSliceDataType = {
  RecentProducts: [],
  loading: false,
  error: false,
};

function RecentProductsData(state: RecentProductSliceDataType = initialState, action: AnyAction): RecentProductSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.recentProducts };
    case FETCH_RECENT_PRODUCTS:
      return { ...state, loading: true };
    case FETCH_RECENT_PRODUCTS_SUCESS:
      return { ...state, loading: false, RecentProducts: action.payload };
    case SAVE_RECENT_PRODUCTS:
      return { ...state, loading: true };
    case SAVE_RECENT_PRODUCTS_SUCESS:
      return { ...state, loading: false, RecentProducts: action.payload };
    default:
      return state;
  }
}

export default RecentProductsData;

export type RecentProductSliceDataType = {
  loading: boolean;
  error: boolean;
  RecentProducts: ProductDataType[];
};
