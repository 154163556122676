const getExtensions = (sdp:any) => {
	const extensions = (('' + sdp).match(/extmap:\d+ [^\n|\r]+/g) || [])
		.map((x) => x.replace(/extmap:[^\s]+ /, ''))
	return [...new Set(extensions)].sort()
}

const createCounter = () => {
	let counter = 0
	return {
		increment: () => counter += 1,
		getValue: () => counter,
	}
}

// https://webrtchacks.com/sdp-anatomy/
// https://tools.ietf.org/id/draft-ietf-rtcweb-sdp-08.html
const constructDescriptions = ({mediaType, sdp, sdpDescriptors, rtxCounter}) => {
	if (!(''+sdpDescriptors)) {
		return
	}
	return sdpDescriptors.reduce((descriptionAcc, descriptor) => {
		const matcher = `(rtpmap|fmtp|rtcp-fb):${descriptor} (.+)`
		const formats = (sdp.match(new RegExp(matcher, 'g')) || [])
		if (!(''+formats)) {
			return descriptionAcc
		}
		const isRtxCodec = ('' + formats).includes(' rtx/')
		if (isRtxCodec) {
			if (rtxCounter.getValue()) {
				return descriptionAcc
			}
			rtxCounter.increment()
		}
		const getLineData = (x:any) => x.replace(/[^\s]+ /, '')
		const description = formats.reduce((acc, x) => {
			const rawData = getLineData(x)
			const data = rawData.split('/')
			const codec = data[0]
			const description = {}

			if (x.includes('rtpmap')) {
				if (mediaType == 'audio') {
					description.channels = (+data[2]) || 1
				}
				description.mimeType = `${mediaType}/${codec}`
				description.clockRates = [+data[1]]
				return {
					...acc,
					...description,
				}
			} else if (x.includes('rtcp-fb')) {
				return {
					...acc,
					feedbackSupport: [...(acc.feedbackSupport||[]), rawData],
				}
			} else if (isRtxCodec) {
				return acc // no sdpFmtpLine
			}
			return { ...acc, sdpFmtpLine: [...rawData.split(';')] }
		}, {})

		let shouldMerge = false
		const mergerAcc = descriptionAcc.map((x) => {
			shouldMerge = x.mimeType == description.mimeType
			if (shouldMerge) {
				if (x.feedbackSupport) {
					x.feedbackSupport = [
						...new Set([...x.feedbackSupport, ...description.feedbackSupport]),
					]
				}
				if (x.sdpFmtpLine) {
					x.sdpFmtpLine = [
						...new Set([...x.sdpFmtpLine, ...description.sdpFmtpLine]),
					]
				}
				return {
					...x,
					clockRates: [
						...new Set([...x.clockRates, ...description.clockRates]),
					],
				}
			}
			return x
		})
		if (shouldMerge) {
			return mergerAcc
		}
		return [...descriptionAcc, description]
	}, [])
}

const getCapabilities = (sdp:any) => {
	const videoDescriptors = ((/m=video [^\s]+ [^\s]+ ([^\n|\r]+)/.exec(sdp) || [])[1] || '').split(' ')
	const audioDescriptors = ((/m=audio [^\s]+ [^\s]+ ([^\n|\r]+)/.exec(sdp) || [])[1] || '').split(' ')
	const rtxCounter = createCounter()
	return {
		audio: constructDescriptions({
			mediaType: 'audio',
			sdp,
			sdpDescriptors: audioDescriptors,
			rtxCounter,
		}),
		video: constructDescriptions({
			mediaType: 'video',
			sdp,
			sdpDescriptors: videoDescriptors,
			rtxCounter,
		}),
	}
}

const getIPAddress = (sdp:any) => {
	const blocked = '0.0.0.0'
	const candidateEncoding = /((udp|tcp)\s)((\d|\w)+\s)((\d|\w|(\.|\:))+)(?=\s)/ig
	const connectionLineEncoding = /(c=IN\s)(.+)\s/ig
	const connectionLineIpAddress = ((sdp.match(connectionLineEncoding) || [])[0] || '').trim().split(' ')[2]
	if (connectionLineIpAddress && (connectionLineIpAddress != blocked)) {
		return connectionLineIpAddress
	}
	const candidateIpAddress = ((sdp.match(candidateEncoding) || [])[0] || '').split(' ')[2]
	return candidateIpAddress && (candidateIpAddress != blocked) ? candidateIpAddress : undefined
}

export default async function getWebRTCData(): Promise<Record<string, unknown> | null> {
	return new Promise(async (resolve) => {
		if (!window.RTCPeerConnection) {
			return resolve(null)
		}

		const config = {
			iceCandidatePoolSize: 1,
			iceServers: [
				{
					urls: [
						'stun:stun4.l.google.com:19302',
						'stun:stun3.l.google.com:19302',
						// 'stun:stun2.l.google.com:19302',
						// 'stun:stun1.l.google.com:19302',
						// 'stun:stun.l.google.com:19302',
					],
				},
			],
		}

		const connection = new RTCPeerConnection(config)
		connection.createDataChannel('')

		const options = { offerToReceiveAudio: 1, offerToReceiveVideo: 1 }

		const offer = await connection.createOffer(options as unknown as RTCOfferOptions)

		connection.setLocalDescription(offer)
		const { sdp } = offer || {}

		const sdpextensions = getExtensions(sdp)
		const codecsSdp = getCapabilities(sdp)

		let iceCandidate = ''
		let foundation = ''
		const giveUpOnIPAddress = setTimeout(() => {
			connection.removeEventListener('icecandidate', computeCandidate)
			connection.close()
			if (sdp) {
				return resolve({
					codecsSdp,
					sdpextensions,
					foundation,
					iceCandidate,
				})
			}
			return resolve(null)
		}, 3000)

		const computeCandidate = (event:any) => {
			const { candidate, foundation: foundationProp } = event.candidate || {}

			if (!candidate) {
				return
			}

			if (!iceCandidate) {
				iceCandidate = candidate
				foundation = (/^candidate:([\w]+)/.exec(candidate) || [])[1] || ''
			}

			const { sdp } = connection.localDescription || {}
			const address = getIPAddress(sdp)
			if (!address) {
				return
			}

			const knownInterface: Record<string, string> = {
				842163049: 'public interface',
				2268587630: 'WireGuard',
			}

			connection.removeEventListener('icecandidate', computeCandidate)
			clearTimeout(giveUpOnIPAddress)
			connection.close()
			return resolve({
				codecsSdp,
				sdpextensions,
				foundation: knownInterface[foundation] || foundation,
				foundationProp,
				iceCandidate,
				address,
				stunConnection: candidate,
			})
		}

		connection.addEventListener('icecandidate', computeCandidate)
	})
}


