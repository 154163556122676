import { AxiosGet, GenericFetchGet } from '@/libraries/request';
import { BASE_URL_FP_API, configDataType } from '@/services/config';
import { GetHolidays_ResponseType, GetNotificationSettingsbykey_ResponseType, GetSystemSettingByIdList_ResponseType, GetSystemSettingById_ResponseType } from '@/services/website-settings/types';

const setErrorMessage = (message: string) => ({ title: 'WebsiteSettings Service', message: message });

// API's
const GetHolidays_api = () => `${BASE_URL_FP_API}WebsiteSettings/GetHolidays`;
const GetSystemSettingByIdList_api = (settingids: string) => `${BASE_URL_FP_API}WebsiteSettings/GetSystemSettingByIdList/${settingids}`;
export const GetSystemSettingById_api = (settingid: string) => `${BASE_URL_FP_API}WebsiteSettings/GetSystemSettingById/${settingid}`;
const GetNotificationSettingsbykey_api = (key: string) => `${BASE_URL_FP_API}WebsiteSettings/GetNotificationSettingsbykey/${key}`;

// Methods
export const getHolidays = (configData: configDataType) => AxiosGet<GetHolidays_ResponseType>(GetHolidays_api(), configData, setErrorMessage('getHolidays method'));
export const getSystemSettingByIdList = (settingids: string, configData: configDataType) => AxiosGet<GetSystemSettingByIdList_ResponseType>(GetSystemSettingByIdList_api(settingids), configData, setErrorMessage('getSystemSettingByIdList method'));
export const getSystemSettingByIdList_Generic = (settingids: string, configData: configDataType) => GenericFetchGet<GetSystemSettingByIdList_ResponseType>(GetSystemSettingByIdList_api(settingids), configData, setErrorMessage('getSystemSettingByIdList_Generic method'));
export const getSystemSettingById = (settingid: string, configData: configDataType) => GenericFetchGet<GetSystemSettingById_ResponseType>(GetSystemSettingById_api(settingid), configData, setErrorMessage('getSystemSettingById method'));
export const getNotificationSettingsbykey = (key: string, configData: configDataType) => AxiosGet<GetNotificationSettingsbykey_ResponseType>(GetNotificationSettingsbykey_api(key), configData, setErrorMessage('getNotificationSettingsbykey method'));
