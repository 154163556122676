import { PlansSliceDataType } from '@/redux/reducers/plansReducer';

export const FETCH_PLAN_STARTED = 'FETCH_PLAN_STARTED';
export const FETCH_PLAN_ENDED = 'FETCH_PLAN_ENDED';
export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS';
export const FETCH_PLAN_FAILURE = 'FETCH_PLAN_FAILURE';

export const fetchPlansStarted = () => ({
  type: FETCH_PLAN_STARTED,
});

export const fetchPlansEnded = () => ({
  type: FETCH_PLAN_ENDED,
});

export const fetchPlansSuccess = (plans: PlansSliceDataType['plans']) => ({
  type: FETCH_PLAN_SUCCESS,
  payload: plans,
});

export const fetchPlansFailure = (error: PlansSliceDataType['error']) => ({
  type: FETCH_PLAN_FAILURE,
  payload: error,
});
