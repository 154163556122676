import { NotificationsSliceDataType } from '@/redux/reducers/notificationReducer';

export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILURE = 'FETCH_NOTIFICATION_FAILURE';

export const fetchNotificationSuccess = (notificationData: NotificationsSliceDataType) => ({
  type: FETCH_NOTIFICATION_SUCCESS,
  payload: notificationData,
});

export const fetchNotificationFailure = (notificationData: NotificationsSliceDataType) => ({
  type: FETCH_NOTIFICATION_FAILURE,
  payload: notificationData,
});
