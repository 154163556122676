import React, { Component } from 'react'
import {getSystemSettingsById} from 'components/shared/globalFunctions';
import Gconfig from "globalconfig"
import Router from 'next/router';
import { connect } from 'react-redux';
class CurrentlyDownComp extends React.Component {
    async componentDidMount() {
        window.history.pushState('', '', '/temporary-maintenance');
        this.settingDataInterval = setInterval(async() =>{
           let maintenance_enabled =  await getSystemSettingsById(Gconfig.system_settings.maintenanceEnabled);
               if(maintenance_enabled && maintenance_enabled.system_settings.system_settings[0].setting_value != '1' &&  window.location.href.indexOf('temporary-maintenance')>-1){
                    Router.replace('/')
                    window.location.reload();
               }
        },6000)
    }
    componentWillUnmount() {
        window.clearInterval(this.settingDataInterval)
    }
    render() {
        return (
            <div className="currentlydown-bg">
                <div className="pagecontent">
                {/* <img src={Gconfig.fashionpassImageStorage+"assets/img/fashionpass-logo.svg"}></img>
                <br/>
                <br/>
                {this.props.content &&<div dangerouslySetInnerHTML={{__html: this.props.content}}></div>}
                <br/>
                <br/>
                <p>The FashionPass Team</p> */}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        systemSettings: state.systemSettings,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        _getSystemSettings: () => dispatch(getSystemSettings())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CurrentlyDownComp);