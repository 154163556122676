import { StoreThunk } from '@/redux/store';
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import { populatePreferenceFormate } from '@/functions/customer-preferences';
import { getPreferences, postUpdatePreferences } from '@/services/customer';
import { GetCookie, SetCookie, ParseHttpCookie } from '@/libraries/browser-storage';
import { CookieKeys } from '@/constants/cookie-keys';
import { fetchCustomerPreferencesSuccess } from '@/redux/actions/customerPreferencesActions';
import { NotificationKeyCookieDataType } from '@/functions/dynamic-cookie-methods';
import { IncomingMessage } from 'http';
import { isEmpty } from '@/utils/object-methods';
import jsCookie from 'js-cookie';

export const updateCustomerPreferences = (newPrefData: NotificationKeyCookieDataType, key: string, isServer: object): StoreThunk => {
  return async (dispatch, getState) => {
    const customerPreferencesData = getState().customerPreferences;
    const customerData = getState().customer;

    const customer_token = getAuthTokenfromcookie(isServer);
    const processedCustomerPreferencesData = populatePreferenceFormate(customerPreferencesData, typeof newPrefData === 'string' ? newPrefData : JSON.stringify(newPrefData), key);

    if (customer_token !== undefined && customerData.customer !== 'not_login') {
      if(processedCustomerPreferencesData.preferences == null || processedCustomerPreferencesData.preferences == undefined || Object.keys(processedCustomerPreferencesData.preferences).length == 0) return;
      const response = await postUpdatePreferences({ preferences: processedCustomerPreferencesData.preferences || null }, { customerIP: true, guid: true, token: customer_token, contentType: 'application/json' });
      if (!isServer) SetCookie(CookieKeys.USER_FILTER_PREFERENCES, response?.customer_preferences || {});
      dispatch(fetchCustomerPreferencesSuccess(response?.customer_preferences || {}));
    } else {
      if (!isServer) SetCookie(CookieKeys.USER_FILTER_PREFERENCES, processedCustomerPreferencesData);
      dispatch(fetchCustomerPreferencesSuccess(processedCustomerPreferencesData));
    }
  };
};

export const getCustomerPreferences = (serverRequest?: IncomingMessage, fromLogin?: boolean): StoreThunk => {
  return async (dispatch, getState) => {
    const customerToken = getAuthTokenfromcookie(serverRequest);
    // const cookiePrefs = GetCookie(CookieKeys.USER_FILTER_PREFERENCES);
    let cookiePrefs = undefined;
    if (serverRequest && serverRequest.headers) {
      let cookies = serverRequest.headers.cookie || '';
      if (cookies != '' && typeof cookies === 'string') {
        const cookiesJSON = ParseHttpCookie(cookies);
        if (cookiesJSON.userFilterPreferences !== undefined) {
          cookiePrefs = JSON.parse(cookiesJSON.userFilterPreferences);
        }
      }
    } else {
      cookiePrefs = GetCookie(CookieKeys.USER_FILTER_PREFERENCES);
    }

    if (customerToken !== undefined && customerToken !== 'not_login') {
      const response = await getPreferences({ customerIP: true, guid: true, token: customerToken });

      if (response && response.success) {
        const fetchedPrefs = response.customer_preferences;
        let sizes_old: string[] = [];
        let sizes_new: string[] = [];

        if (cookiePrefs && cookiePrefs.preferences && cookiePrefs.preferences.sizes !== undefined) sizes_old = cookiePrefs.preferences.sizes.split(',');
        if (fetchedPrefs.preferences !== null) {
          if (fetchedPrefs.preferences.sizes !== undefined && isEmpty(cookiePrefs)) sizes_new = fetchedPrefs.preferences.sizes.split(',');
          else fetchedPrefs.preferences.sizes = '';

          sizes_new = sizes_new.concat(sizes_old);
          sizes_new = sizes_new.filter((item, index) => sizes_new.indexOf(item) === index);
          fetchedPrefs.preferences.sizes = sizes_new.toString();
        }

        SetCookie(CookieKeys.USER_FILTER_PREFERENCES, fetchedPrefs);
        dispatch(fetchCustomerPreferencesSuccess(fetchedPrefs));
        return response.customer_preferences;
      }
    }

    if (cookiePrefs !== undefined && cookiePrefs !== null) {
      dispatch(fetchCustomerPreferencesSuccess(cookiePrefs));
      return cookiePrefs;
    }
  };
};
