import { CustomerPreferences, CustomerSummaryDataType } from '@/services/customer';

export enum CookieKeys {
  IP_ADDRESS = 'ip_address',
  CUSTOMER_TOKEN = 'customer_token',
  CUSTOMER_IP = 'customer_ip',
  CUSTOMER_GUID = 'Customer_guid',
  CUSTOMER_DATA = 'customer_data',
  CLOSED_FOR_MAINTENANCE_POPUP = 'closedMaintenancePopup',
  BLACK_FRIDAY_POPUP_CLOSE = 'blackFridayPopupClose',
  USER_FILTER_PREFERENCES = 'userFilterPreferences',
  PLAN_SPOTS_RANDOM_NUMBER = 'planSpotsRamdomNumber',
  PLAN_SPOTS_RANDOM_NUMBER_TIME = 'planSpotsRamdomNumberTIme',
  USER_REFER_CODE = 'userReferCode',
  LOGIN_POPUP_DISABLE = 'loginPopupDisable',
  LOGIN_FAIL_ATTEMPTS = 'loginFailAttempts',
  FETCH_FAILED = 'fetchFailed',
  CACHE_COLLECTION_KEY = 'casheCollectionkey',
  SLIDER_MORE = 'sliderMore',
  OPEN_REVIEW_POPUP = 'openReviewPopup',
  LA_TIME = 'LATime',
  REQUEST_GUID = 'request_guid',
  FP_SIGNUP_LAST_ORDER = 'fp_signup_last_order',
  FP_SIGNUP_CUSTOMER_TOKEN = 'fp_signup_customer_token',
  FP_SIGNUP_EMAIL = 'fp_signup_email',
  FP_SIGNUP_BAD_CUSTOMER = 'fp_signup_bad_customer',
  FP_SIGNUP_LOGGEDIN_CUSTOMER = 'fp_signup_loggedin_customer',
  FP_SIGNUP_PLAN = 'fp_signup_plan',
  FP_SIGNUP_PASSWORD = 'fp_signup_password',
  FP_SIGNUP_FIRST_NAME = 'fp_signup_first_name',
  FP_SIGNUP_PLAN_ID = 'fp_signup_plan_id',
  BAD_CUSTOMER = 'bad_customer',
  REACTIVATE_PRICING_OFFER = 'reactivatePricingOffer',
  CUSTOMER_URL_EVENT = 'customerUrlEvent',
  HIDE_NEXT_ITEM_BOX = 'hideNextItemBox',
  PRICING_48HOUR_MINIMIZE = 'pricing48hourMinimize',
  FCM_TOKEN = 'fcm_token',
  IS_WEBGL_HASH_SUBMITTED= 'isWebGLhashSubmitted',
  COMPLETE_PROFILE_POPUP_SHOW= 'completeProfilePopupShow',
  SHOW_VERIFICATION_POPUP='verificationPopupShow',
  LAST_CUSTOMER_SUMMARY_FETCHED='lastCustomerSummaryFetchedTime',
  LOGIN_SUCCESS='loginSuccess'
}

export type CookieKeysType = {
  [CookieKeys.IP_ADDRESS]: string;
  [CookieKeys.CUSTOMER_TOKEN]: string;
  [CookieKeys.CUSTOMER_IP]: string;
  [CookieKeys.CUSTOMER_GUID]: string;
  [CookieKeys.CUSTOMER_DATA]: CustomerSummaryDataType | 'signOut';
  [CookieKeys.CLOSED_FOR_MAINTENANCE_POPUP]: string;
  [CookieKeys.BLACK_FRIDAY_POPUP_CLOSE]: boolean;
  [CookieKeys.USER_FILTER_PREFERENCES]: CustomerPreferences | Record<string, undefined>;
  [CookieKeys.PLAN_SPOTS_RANDOM_NUMBER]: number;
  [CookieKeys.PLAN_SPOTS_RANDOM_NUMBER_TIME]: string;
  [CookieKeys.USER_REFER_CODE]: string;
  [CookieKeys.LOGIN_POPUP_DISABLE]: string;
  [CookieKeys.LOGIN_FAIL_ATTEMPTS]: number;
  [CookieKeys.FETCH_FAILED]: boolean;
  [CookieKeys.CACHE_COLLECTION_KEY]: string;
  [CookieKeys.SLIDER_MORE]: string;
  [CookieKeys.OPEN_REVIEW_POPUP]: boolean;
  [CookieKeys.LA_TIME]: string;
  [CookieKeys.REQUEST_GUID]: string;
  [CookieKeys.FP_SIGNUP_LAST_ORDER]: string;
  [CookieKeys.FP_SIGNUP_CUSTOMER_TOKEN]: string;
  [CookieKeys.FP_SIGNUP_EMAIL]: string;
  [CookieKeys.FP_SIGNUP_BAD_CUSTOMER]: string;
  [CookieKeys.FP_SIGNUP_LOGGEDIN_CUSTOMER]: string;
  [CookieKeys.FP_SIGNUP_PLAN]: string;
  [CookieKeys.FP_SIGNUP_PLAN_ID]: string;
  [CookieKeys.BAD_CUSTOMER]: boolean;
  [CookieKeys.REACTIVATE_PRICING_OFFER]: boolean;
  [CookieKeys.CUSTOMER_URL_EVENT]: string;
  [CookieKeys.HIDE_NEXT_ITEM_BOX]: boolean;
  [CookieKeys.PRICING_48HOUR_MINIMIZE]: boolean;
  [CookieKeys.FCM_TOKEN]: string;
  [CookieKeys.IS_WEBGL_HASH_SUBMITTED]: string;
  [CookieKeys.COMPLETE_PROFILE_POPUP_SHOW]: string;
  [CookieKeys.SHOW_VERIFICATION_POPUP]: string;
  [CookieKeys.LAST_CUSTOMER_SUMMARY_FETCHED]: string;
  [CookieKeys.LOGIN_SUCCESS]: string;
};
