import { RecentProductSliceDataType } from '@/redux/reducers/recentProductReducer';

export const FETCH_RECENT_PRODUCTS = 'FETCH_RECENT_PRODUCTS';
export const SAVE_RECENT_PRODUCTS = 'SAVE_RECENT_PRODUCTS';
export const SAVE_RECENT_PRODUCTS_SUCESS = 'SAVE_RECENT_PRODUCTS_SUCESS';
export const FETCH_RECENT_PRODUCTS_SUCESS = 'FETCH_RECENT_PRODUCTS_SUCESS';

export const fetchRecentProducts = () => ({
  type: FETCH_RECENT_PRODUCTS,
});

export const saveRecentProducts = () => ({
  type: SAVE_RECENT_PRODUCTS,
});

export const fetchRecentProductsSucess = (recentProducts: RecentProductSliceDataType['RecentProducts']) => ({
  type: FETCH_RECENT_PRODUCTS_SUCESS,
  payload: recentProducts,
});

export const saveRecentProductsSucess = (recentProducts: RecentProductSliceDataType['RecentProducts']) => ({
  type: SAVE_RECENT_PRODUCTS_SUCESS,
  payload: recentProducts,
});
