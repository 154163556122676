import { FETCH_NOTIFICATION_SUCCESS, FETCH_NOTIFICATION_FAILURE } from '@/redux/actions/notificationActions';
import { HYDRATE } from 'next-redux-wrapper';
import { NotificationSettingsDataType } from '@/services/website-settings';
import { AnyAction } from 'redux';

const initialState: NotificationsSliceDataType = {
  showNotificationBar: false,
  notification: {},
  loading: true,
  error: false,
};

export default function notificationData(state: NotificationsSliceDataType = initialState, action: AnyAction): NotificationsSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.notification };
    case FETCH_NOTIFICATION_SUCCESS:
      return { ...state, loading: false, error: false, showNotificationBar: action.payload.showNotificationBar, notification: action.payload.notification };
    case FETCH_NOTIFICATION_FAILURE:
      return { ...state, loading: false, error: true, showNotificationBar: action.payload.showNotificationBar, notification: action.payload.notification };
    default:
      return state;
  }
}

export type NotificationsSliceDataType = {
  loading: boolean;
  error: boolean;
  showNotificationBar: boolean;
  notification: NotificationSettingsDataType | Record<string, undefined>;
};
