import { CustomerAddressesSliceDataType } from '@/redux/reducers/customerAddressesReducer';
import { CustomerPaymentsSliceDataType } from '@/redux/reducers/customerPaymentsReducer';
import { CustomerSliceDataType } from '@/redux/reducers/customerReducer';

export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';
export const FETCH_CUSTOMER_ADDRESSES_SUCCESS = 'FETCH_CUSTOMER_ADDRESSES_SUCCESS';
export const FETCH_CUSTOMER_ADDRESSES_FAILURE = 'FETCH_CUSTOMER_ADDRESSES_FAILURE';
export const FETCH_CUSTOMER_PAYMENTS_SUCCESS = 'FETCH_CUSTOMER_PAYMENTS_SUCCESS';
export const FETCH_CUSTOMER_PAYMENTS_FAILURE = 'FETCH_CUSTOMER_PAYMENTS_FAILURE';
export const CUSTOMER_ADDRESSES_MARK_LOADING = 'CUSTOMER_ADDRESSES_MARK_LOADING';
export const CUSTOMER_ADDRESSES_MARK_LOADING_DONE = 'CUSTOMER_ADDRESSES_MARK_LOADING_DONE';
export const CUSTOMER_PAYMENT_MARK_LOADING = 'CUSTOMER_PAYMENT_MARK_LOADING';
export const CUSTOMER_PAYMENT_MARK_LOADING_DONE = 'CUSTOMER_PAYMENT_MARK_LOADING_DONE';

export const customerAddressesMarkLoading = () => ({
  type: CUSTOMER_ADDRESSES_MARK_LOADING,
});

export const customerAddressesMarkLoadingDone = () => ({
  type: CUSTOMER_ADDRESSES_MARK_LOADING_DONE,
});

export const customerPaymentMarkLoading = () => ({
  type: CUSTOMER_PAYMENT_MARK_LOADING,
});

export const customerPaymentMarkLoadingDone = () => ({
  type: CUSTOMER_PAYMENT_MARK_LOADING_DONE,
});

export const fetchCustomerSuccess = (customer: CustomerSliceDataType['customer']) => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload: customer,
});

export const fetchCustomerFailure = (error: CustomerSliceDataType['error']) => ({
  type: FETCH_CUSTOMER_FAILURE,
  payload: error,
});

export const fetchCustomerAddressesSuccess = (addresses: CustomerAddressesSliceDataType['addresses']) => ({
  type: FETCH_CUSTOMER_ADDRESSES_SUCCESS,
  payload: addresses,
});

export const fetchCustomerAddressesFailure = (error: CustomerAddressesSliceDataType['error']) => ({
  type: FETCH_CUSTOMER_ADDRESSES_FAILURE,
  payload: error,
});

export const fetchCustomerPaymentsSuccess = (paymentsMethods: CustomerPaymentsSliceDataType['paymentMethods']) => ({
  type: FETCH_CUSTOMER_PAYMENTS_SUCCESS,
  payload: paymentsMethods,
});

export const fetchCustomerPaymentsFailure = (error: CustomerPaymentsSliceDataType['error']) => ({
  type: FETCH_CUSTOMER_PAYMENTS_FAILURE,
  payload: error,
});
