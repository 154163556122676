export { getHolidays } from '@/services/website-settings/services';
export type { GetHolidays_ResponseType } from '@/services/website-settings/types';
export type { HolidaysDataType } from '@/services/website-settings/types';

export { getNotificationSettingsbykey } from '@/services/website-settings/services';
export type { GetNotificationSettingsbykey_ResponseType } from '@/services/website-settings/types';
export type { NotificationSettingsDataType } from '@/services/website-settings/types';

export { getSystemSettingByIdList } from '@/services/website-settings/services';
export { getSystemSettingByIdList_Generic } from '@/services/website-settings/services';
export type { GetSystemSettingByIdList_ResponseType } from '@/services/website-settings/types';
export type { SystemSettingsType } from '@/services/website-settings/types';

export { getSystemSettingById } from '@/services/website-settings/services';
export type { GetSystemSettingById_ResponseType } from '@/services/website-settings/types';
