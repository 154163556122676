import { FETCH_CUSTOMER_BOARDS_SUCCESS, FETCH_CUSTOMER_BOARDS_FAILURE } from '@/redux/actions/customerBoardsActions';
import { HYDRATE } from 'next-redux-wrapper';
import { BoardDataType } from '@/services/customer';
import { AnyAction } from 'redux';

const initialState: CustomerBoardsSliceDataType = {
  customerBoards: [],
  loading: true,
  error: false,
};

export default function customerBoardsData(state: CustomerBoardsSliceDataType = initialState, action: AnyAction): CustomerBoardsSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.customerBoards };
    case FETCH_CUSTOMER_BOARDS_SUCCESS:
      return { ...state, loading: false, customerBoards: action.payload };
    case FETCH_CUSTOMER_BOARDS_FAILURE:
      return { ...state, loading: false, customerBoards: [], error: true };
    default:
      return state;
  }
}

export type CustomerBoardsSliceDataType = {
  loading: boolean;
  error: boolean;
  customerBoards: BoardDataType[];
};
