import { DerivedAPI } from '@/constants/global-config';
import { AxiosGet, GenericFetchGet } from '@/libraries/request';
import { BASE_URL_FP_API, configDataType, BASE_URL_COLLECTION_API } from '@/services/config';
import { GetCategory_ResponseType, GetCollectionPageLoadTime_ResponseType, GetCollectionSearchByString_ResponseType, GetProductsByHandle_ResponseType, GetTapeAndPasties_ResponseType, curatedCollectionType } from '@/services/collections/types';

const setErrorMessage = (message: string) => ({ title: 'Collection Service', message: message });

// API's
const TapeAndPasties_api = () => `${DerivedAPI.COLLECTION_API_HANDLE_URL}tape-and-pasties`;
const Category_api = (categoryID: string, depth: number) => `${BASE_URL_FP_API}Collections/Category/${categoryID}/${depth}`;
const CollectionPageLoadTime_api = (url: string, loadtime: number) => `${BASE_URL_FP_API}Collections/CollectionPageLoadTime?url=${url}&load_time=${loadtime}`;
const CollectionSearchByString_api = (collectionName: string, showHiddenItem: number) => `${DerivedAPI.COLLECTION_API_URL}${collectionName.toLowerCase()}&show_hidden_items=${showHiddenItem}`;
const CuratedCollection_api = (collectionName: string, num: number) => `${BASE_URL_FP_API}Collections/Category/${collectionName}/${num}`;

// Methods
export const getTapeAndPasties = (configData: configDataType) => AxiosGet<GetTapeAndPasties_ResponseType>(TapeAndPasties_api(), configData, setErrorMessage('getTapeAndPasties Method'));
export const getCategory = (categoryID: string, depth: number, configData: configDataType) => AxiosGet<GetCategory_ResponseType>(Category_api(categoryID, depth), configData, setErrorMessage('getCategory Method'));
export const getCollectionPageLoadTime = (url: string, loadtime: number, configData: configDataType) => AxiosGet<GetCollectionPageLoadTime_ResponseType>(CollectionPageLoadTime_api(url, loadtime), configData, setErrorMessage('getCollectionPageLoadTime Method'));
export const getCollectionSearchByString = (collectionName: string, showHiddenItem: number, configData: configDataType) => GenericFetchGet<GetCollectionSearchByString_ResponseType>(CollectionSearchByString_api(collectionName, showHiddenItem), configData, setErrorMessage('getCollectionSearchByString Method'));
export const getProductsByHandle = (url: string, noCache: boolean, configData: configDataType) => GenericFetchGet<GetProductsByHandle_ResponseType>(url, configData, setErrorMessage('getProductsByHandle Method'), { isGetProductsByHandle: true, noCache });
export const getCuratedCollection = (curated: string, num: number, configData: configDataType) => AxiosGet<curatedCollectionType>(CuratedCollection_api(curated, num), configData, setErrorMessage('get curated collection Method'));
