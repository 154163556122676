export { getAddRecentProduct } from '@/services/product/services';
export type { GetAddRecentProduct_ResponseType } from '@/services/product/types';

export { getRecentProducts } from '@/services/product/services';
export type { GetRecentProducts_ResponseType } from '@/services/product/types';

export { getProductInfo } from '@/services/product/services';
export type { GetProductInfo_ResponseType } from '@/services/product/types';
export type { ProductDataType } from '@/services/product/types';
export type { ProductDataType_Image } from '@/services/product/types';
export type { ProductDataType_Size } from '@/services/product/types';
export type { ProductDataType_ProductPairing } from '@/services/product/types';
export type { ProductDataType_CollectionSearch } from '@/services/product/types';
export type { ProductAttributeDataType } from '@/services/product/types';

export { getMultipleProductsInfo } from '@/services/product/services';
export type { GetMultipleProductsInfo_ResponseType } from '@/services/product/types';

export { getAllBrands } from '@/services/product/services';
export type { GetAllBrands_ResponseType } from '@/services/product/types';
export type { BrandDataType } from '@/services/product/types';
