import { FETCH_SALETAX_SUCCESS } from '@/redux/actions/saleTaxActions';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: SalesTaxSliceDataType = {
  saleTax: { tax: false, taxPrice: 0.0 },
  loading: true,
  error: false,
};

export default function saleTaxData(state: SalesTaxSliceDataType = initialState, action: AnyAction): SalesTaxSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.saleTax };
    case FETCH_SALETAX_SUCCESS:
      return { ...state, saleTax: action.payload, loading: false };
    default:
      return state;
  }
}

export type SalesTaxSliceDataType = {
  loading: boolean;
  error: boolean;
  saleTax: {
    tax: boolean;
    taxPrice: number;
  };
};
