import { CustomerPreferencesSliceDataType } from '@/redux/reducers/customerPreferencesReducer';

export const FETCH_CUSTOMER_PREFERENCES_SUCCESS = 'FETCH_CUSTOMER_PREFERENCES_SUCCESS';
export const FETCH_CUSTOMER_PREFERENCES_FAILURE = 'FETCH_CUSTOMER_PREFERENCES_FAILURE';

export const fetchCustomerPreferencesSuccess = (customerPreferences: CustomerPreferencesSliceDataType['customerPreferences']) => ({
  type: FETCH_CUSTOMER_PREFERENCES_SUCCESS,
  payload: customerPreferences,
});

export const fetchCustomerPreferencesFailure = (error: CustomerPreferencesSliceDataType['error']) => ({
  type: FETCH_CUSTOMER_PREFERENCES_FAILURE,
  payload: error,
});
