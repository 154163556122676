import { FETCH_CUSTOMER_SUCCESS, FETCH_CUSTOMER_FAILURE } from '@/redux/actions/customerActions';
import { HYDRATE } from 'next-redux-wrapper';
import { CustomerSummaryDataType } from '@/services/customer';
import { AnyAction } from 'redux';

const initialState: CustomerSliceDataType = {
  customer: 'not_login',
  loading: false,
  error: false,
};

export default function customerData(state: CustomerSliceDataType = initialState, action: AnyAction): CustomerSliceDataType {
  switch (action.type) {
    case HYDRATE:
      if (state.customer != action.payload.customer.customer) return { ...state, ...action.payload.customer };
      else return { ...state };
    case FETCH_CUSTOMER_SUCCESS:
      return { ...state, customer: action.payload, loading: false };
    case FETCH_CUSTOMER_FAILURE:
      return { ...state, loading: false, customer: 'not_login', error: true };
    default:
      return state;
  }
}

export type CustomerSliceDataType = {
  customer: CustomerSummaryDataType | 'not_login';
  loading: boolean;
  error: boolean;
};
