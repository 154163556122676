import { AxiosGet } from '@/libraries/request';
import { BASE_URL_FRAUD_API, configDataType } from '@/services/config';
import { FraudValidationCheckIP_ResponseType } from './types';

const setErrorMessage = (message: string) => ({ title: 'Fraud Validation Service', message: message });

// API's
const FraudValidationCheckIP_api = (ip: string) => `${BASE_URL_FRAUD_API}fraudvalidation/CheckIp?ipaddress=${ip}`;

// Methods
export const getFraudValidationCheckIP = (ip: string, configData: configDataType) => AxiosGet<FraudValidationCheckIP_ResponseType>(FraudValidationCheckIP_api(ip), configData, setErrorMessage('getFraudValidationCheckIP Method'));
