import { LocalStorageKeys, LocalStorageKeysType } from '@/constants/local-storage-keys';

export const RemoveFromLocalStorage = <T extends LocalStorageKeys>(key: T) => localStorage.removeItem(key);
export const SetInLocalStorage = <T extends LocalStorageKeys>(key: T, val: LocalStorageKeysType[T]) => localStorage.setItem(key, typeof val === 'string' ? val : JSON.stringify(val));
export const GetFromLocalStorage = <T extends LocalStorageKeys>(key: T, defaultVal?: LocalStorageKeysType[T]) => {
  const val = localStorage.getItem(key);
  if (val === null) return defaultVal;
  else {
    try {
      return JSON.parse(val) as LocalStorageKeysType[T];
    } catch (e) {
      return val as LocalStorageKeysType[T];
    }
  }
};
