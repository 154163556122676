import React from 'react';
// import ReactDOM from 'react-dom';
import {
    fetchCustomerAddressesSuccess, fetchCustomerAddressesFailure,
    fetchCustomerPaymentsSuccess, fetchCustomerPaymentsFailure,
    customerAddressesMarkLoadingDone, customerAddressesMarkLoading, customerPaymentMarkLoadingDone, customerPaymentMarkLoading, fetchCustomerSuccess
} from 'redux/actions/customerActions'
import jsHttpCookie from 'cookie';
import cookie from 'js-cookie';
import Gconfig from "globalconfig";
import { sendGenericEmail,changeStringToMD5} from 'components/shared/globalFunctions';
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import { isEmpty } from '@/utils/object-methods';
import { fetchCustomerPreferencesSuccess } from 'redux/actions/customerPreferencesActions';
import { fetchNotificationSuccess } from 'redux/actions/notificationActions';
import { fetchRenderingConfigSuccess } from 'redux/actions/renderingConfigActions';
import {sha256} from 'crypto-hash';
import jsCookie from 'js-cookie';

const getCustomerAddresses = () => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch) => {
            dispatch(customerAddressesMarkLoadingFunction())
            await fetch(Gconfig.fpAPI + `Customer/GetCustomerAddresses`, {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
            }).then(response => response.json()).then(data => {
                if (data.success) {
                    dispatch(fetchCustomerAddressesSuccess(data.addresses))
                } else {
                    dispatch(fetchCustomerAddressesFailure(data.addresses))
                }
            })
        }
    }
    else return async (dispatch) => {}
}

const getCustomerPaymentMethods = () => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch) => {
            dispatch(customerPaymentMarkLoadingFunction())
            await fetch(Gconfig.fpAPI + `Customer/GetAllPaymentMethods`, {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
            }).then(response => response.json()).then(data => {
                if (data.success) {
                    dispatch(fetchCustomerPaymentsSuccess(data.pay_methods))
                } else {
                    dispatch(fetchCustomerPaymentsFailure(data.pay_methods))

                }
            })
        }
    }
}
const getProvinceCode = (statetext) => {
    if (statetext == null)
        statetext = ''
    let states_hash = {
        'Alabama': 'AL',
        'Alaska': 'AK',
        'American Samoa': 'AS',
        'Arizona': 'AZ',
        'Arkansas': 'AR',
        'California': 'CA',
        'Colorado': 'CO',
        'Connecticut': 'CT',
        'Delaware': 'DE',
        'District Of Columbia': 'DC',
        'Federated States Of Micronesia': 'FM',
        'Florida': 'FL',
        'Georgia': 'GA',
        'Guam': 'GU',
        'Hawaii': 'HI',
        'Idaho': 'ID',
        'Illinois': 'IL',
        'Indiana': 'IN',
        'Iowa': 'IA',
        'Kansas': 'KS',
        'Kentucky': 'KY',
        'Louisiana': 'LA',
        'Maine': 'ME',
        'Marshall Islands': 'MH',
        'Maryland': 'MD',
        'Massachusetts': 'MA',
        'Michigan': 'MI',
        'Minnesota': 'MN',
        'Mississippi': 'MS',
        'Missouri': 'MO',
        'Montana': 'MT',
        'Nebraska': 'NE',
        'Nevada': 'NV',
        'New Hampshire': 'NH',
        'New Jersey': 'NJ',
        'New Mexico': 'NM',
        'New York': 'NY',
        'North Carolina': 'NC',
        'North Dakota': 'ND',
        'Northern Mariana Islands': 'MP',
        'Ohio': 'OH',
        'Oklahoma': 'OK',
        'Oregon': 'OR',
        'Palau': 'PW',
        'Pennsylvania': 'PA',
        'Puerto Rico': 'PR',
        'Rhode Island': 'RI',
        'South Carolina': 'SC',
        'South Dakota': 'SD',
        'Tennessee': 'TN',
        'Texas': 'TX',
        'Utah': 'UT',
        'Vermont': 'VT',
        'Virgin Islands': 'VI',
        'Virginia': 'VA',
        'Washington': 'WA',
        'West Virginia': 'WV',
        'Wisconsin': 'WI',
        'Wyoming': 'WY'
    }
    if (statetext.length > 2) {
        if(statetext == "District of Columbia") return statetext = "DC";
        return states_hash[statetext]; 
    }
    else {
        return statetext;
    }
}
const addCustomerShippingAddress = (addressData, _this) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            dispatch(customerAddressesMarkLoadingFunction())
            return fetch(Gconfig.fpAPI + 'Customer/AddAddress', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
                body: JSON.stringify(addressData)
            }).then(response => response.json()).then(async data => {
                if (data.success) {
                    jsCookie.remove('selectedAddressId')
                    if(data.address.addressAlreadyExist){
                        _this.setState({ disabled: false,reqError:'This address is already exist' })
                        dispatch(customerAddressesMarkLoadingFunction(true))
                        return
                    }
                    let addressUpdated;
                    if (_this.props.billing) {
                        addressUpdated = getstate().customerAddresses.addresses.map(function (address) {
                            if (addressData.make_default && !address.is_shipping) {
                                address.default_address = false;
                            }
                            return address
                        });
                    } else {
                        addressUpdated = getstate().customerAddresses.addresses.map(function (address) {
                            if(location.pathname.indexOf('/checkout')>-1){
                                if (address.is_shipping && address.default_address && addressData.make_default == false) {
                                    address.default_address = false;
                                    address.real_default_address = true;
                                }else if (address.is_shipping && address.real_default_address && addressData.make_default) {
                                    address.default_address = false;
                                    address.real_default_address = false;
                                }
                                else{
                                    address.default_address = false;
                                }
                            }else{
                                if (addressData.make_default && address.is_shipping) {
                                    address.default_address = false;
                                    
                                    var customer = getstate().customer.customer
                                    if(customer.zone !=  data.address.zone  ){
                                        customer.zone = data.address.zone
                                        dispatch(fetchCustomerSuccess(customer))
                                    }
                                } 
                               
                            }
                           
                            return address
                        });
                        if(location.pathname.indexOf('/checkout')>-1){
                            data.address.default_address = true
                            if (data.address.is_shipping && addressData.make_default) {
                                data.address.real_default_address = true
                            }else{
                                data.address.real_default_address = false
                            }
                        }
                        
                    }
                    
                    addressUpdated.push(data.address)
                    var customer = getstate().customer.customer
                    if(customer.zone !=  data.address.zone && data.address.real_default_address == true ){
                        customer.zone = data.address.zone
                        dispatch(fetchCustomerSuccess(customer))
                    }
                    

                    if (addressData.set_to_card) {
                        await _this.props._getCustomerPaymentMethods();
                        _this.setState({ defaultAddress: data.address })
                    }
                    _this.setState({ shippingpopup: false });
                    if(_this.props.billiingState)
                    _this.props.billiingState.setState({submitDataFalse:false})
                    
                    if(document.querySelector(".shippingRadio:checked"))
                    document.querySelector(".shippingRadio:checked").checked = false
                    dispatch(fetchCustomerAddressesSuccess(addressUpdated))
                    if(Gconfig.environment == 'production' && location.host == 'www.fashionpass.com'){
                        if(gtag){
                            gtag({
                                event: "add_shipping_info",
                                ecommerce: {
                                  currency: "USD",
                                  value: 0,
                                  items: []
                                }
                            });
                        }
                    }
                    return data;
                } else {
                    _this.setState({ disabled: false,reqError:data.message })
                    // document.getElementsByClassName("shippingAlertMessage")[0].innerHTML = data.message;
                    
                }
            })
        }
    }
}
const customerAddressesMarkLoadingFunction = (done) => {
    return async (dispatch) => {
        if (done) {
            dispatch(customerAddressesMarkLoadingDone());
        }
        else {
            dispatch(customerAddressesMarkLoading());
        }

    }

}
const customerPaymentMarkLoadingFunction = (done) => {

    return async (dispatch) => {
        if (done) {
            dispatch(customerPaymentMarkLoadingDone());
        }
        else {
            dispatch(customerPaymentMarkLoading());
        }

    }

}
const editCustomerShippingAddress = (address, _this, defaultAddress) => {
    let default_Address = defaultAddress
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            dispatch(customerAddressesMarkLoadingFunction())
            return fetch(Gconfig.fpAPI + 'Customer/EditShippingAddress', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
                body: JSON.stringify(address)
            }).then(response => response.json()).then(data => {
                if (data.success) {
                    let addressUpdated = getstate().customerAddresses.addresses.map(function (address) {
                        if (address.default_address && default_Address)
                            address.default_address = false
                        if (data.address.id == address.id) {
                            if(data.address.default_address){
                                data.address.real_default_address = true
                                address.default_address = false
                            }
                            address = data.address
                        }
                        return address;
                    });
                    var customer = getstate().customer.customer
                    if(customer.zone !=  data.address.zone && data.address.real_default_address == true ){
                        customer.zone = data.address.zone
                        dispatch(fetchCustomerSuccess(customer))
                    }
                    dispatch(fetchCustomerAddressesSuccess(addressUpdated))
                    if(_this != '')
                        _this.setState({ shippingpopup: false });
                    return data
                } else {
                    if(data.message == 'The zip code you supplied failed validation.'){
                        data.message = 'The zip code provided does not match the one on file with your bank.'
                    }
                    let paymentMethods = getstate().customerPaymentsMethods.paymentMethods
                    dispatch(fetchCustomerPaymentsSuccess(paymentMethods))
                    if(_this != '')
                        _this.setState({ disabled: false,reqError:data.message })
                    // document.getElementsByClassName("shippingAlertMessage")[0].innerHTML = data.message;
                }
            })
        }
    }
}
const addCustomerCard = (card, address_id, _this) => {
    card.address_id = parseInt(address_id)
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            dispatch(customerPaymentMarkLoadingFunction())
            fetch(Gconfig.fpAPI + 'Customer/AddPaymentMethod', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
                body: JSON.stringify(card)
            }).then(response => response.json()).then(data => {
                let customer = getstate().customer.customer
                _this.setState({ showaddresses: false });
                if (data.success) {
                    _this.setState({ billingPopup: false,cardSubmitting:false, msg: '' });
                    var paymentUpdated = getstate().customerPaymentsMethods.paymentMethods.map(function (paymentMethods) {
                        if (card.make_default == true) {
                            paymentMethods.default_card = 0;
                        }
                        return paymentMethods;
                    });
                    paymentUpdated.push(data.payment_method)
                    dispatch(fetchCustomerPaymentsSuccess(paymentUpdated))
                    if(Gconfig.environment == 'production' && location.host == 'www.fashionpass.com'){
                        if(gtag){
                            gtag({
                                event: "add_payment_info",
                                ecommerce: {
                                  currency: "USD",
                                  value: 0,
                                  items: []
                                }
                            });
                        }
                    }
                    if(_this.props.setPaymentErrorClick != undefined){
                        _this.props.setPaymentErrorClick();
                    }
                } else {
                    if(data && data.roundtrip && cookie.get('bad_customer') == undefined){
                        cookie.set('bad_customer',true)
                        cookie.set('bad_customer_id',customer && customer.customer_id)
                    }
                    let paymentMethods = getstate().customerPaymentsMethods.paymentMethods
                    dispatch(fetchCustomerPaymentsSuccess(paymentMethods))
                    if(data.message == 'The zip code you supplied failed validation.'){
                        data.message = 'The zip code provided does not match the one on file with your bank.'
                    }
                    _this.setState({ reqError: data.message,cardSubmitting:false });
                }

            })
        }
    }
}

const getCustomerPoints = () => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            return await fetch(Gconfig.fpAPI + `Customer/GetCreditPoints`, {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid}
            }).then(response => response.json()).then(data => {
                if (data.success) {
                    var customer = getstate().customer.customer
                    customer.customer_points = data.credit_points
                    dispatch(fetchCustomerSuccess(customer))
                    return data
                } else {
                    console.log(data)
                }
            })
        }
    }
    else {
        return async (dispatch, getstate) => {}
    }
}
const editCustomerBilling = (address, _this) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            dispatch(customerPaymentMarkLoadingFunction())
            fetch(Gconfig.fpAPI + 'Customer/EditPaymentMethod', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
                body: JSON.stringify(address)
            }).then(response => response.json()).then(data => {
                console.log(data)
                if (data.success) {
                    var addressUpdated = getstate().customerPaymentsMethods.paymentMethods.map(function (payment) {
                        if (data.payment_method.id == payment.id) {
                            payment = data.payment_method
                        }
                        return payment;
                    });
                    dispatch(fetchCustomerPaymentsSuccess(addressUpdated))
                    _this.setState({ billingpopup: false });
                }
            })
        }
    }
}
const setBillingAddressToCard = (idObj, _this) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            dispatch(customerPaymentMarkLoadingFunction())
            fetch(Gconfig.fpAPI + 'Customer/UpdateBillingAddress', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip , 'guid': request_guid},
                body: JSON.stringify(idObj)
            }).then(response => response.json()).then(data => {
                if (data.success) {
                    _this.setState({ address_id: 0, msg: '', inputChk: false, billingPopup: false });
                    _this.disableButton()
                    let paymentUpdated = []
                    paymentUpdated = getstate().customerPaymentsMethods.paymentMethods.filter(function (paymentMethods) {
                        if (idObj.payment_method_id != paymentMethods.id)
                            return paymentMethods;
                    });
                    paymentUpdated.push(data.pay_method)
                    dispatch(fetchCustomerPaymentsSuccess(paymentUpdated))
                }else{
                    let paymentMethods = getstate().customerPaymentsMethods.paymentMethods
                    dispatch(fetchCustomerPaymentsSuccess(paymentMethods))
                    if(data.message == 'The zip code you supplied failed validation.'){
                        data.message = 'The zip code provided does not match the one on file with your bank.'
                    }
                    _this.setState({loading:false,msg:data.message})
                }

            })
        }
    }
}
const deleteCustomerShippingAddress = (id) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            return fetch(Gconfig.fpAPI + `Customer/RemoveShippingAddress/${id}`, {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
            }).then(response => response.json()).then(data => {
                if (data.success) {
                    let addressUpdated = getstate().customerAddresses.addresses.filter(function (address) {
                        if (address.id != id)
                            return address
                    });
                    dispatch(fetchCustomerAddressesSuccess(addressUpdated))
                    return data;
                }
            })
        }
    }
}
const verify_address = (settings) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'FedEx/VerifyAddress', {
            method: 'POST',
            headers:{ 'Content-Type': 'application/json','X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify(settings)
        }).then((response) => {
            if(response.status === 200){
                return response.json();     
            }else{
                let body = `<p>Application: Website<br /><br />${settings.zip} User having issue on verify address</p>`
                let emailContent = {
                    "Subject": 'User issue on verify address',
                    "Body": body,
                    "ToAddresses": ['log7cetch@gmail.com'],
                }
                // sendGenericEmail(emailContent)
                console.log("SOMETHING WENT WRONG ON Verify Address ->>>>>>>>>>> issue here >>>>>>>>>>>!")
                location.reload();
            }
        }).then(data => {
            return data
        }).catch(err => {
            let body = `<p>Application: Website<br /><br />${settings.zip} User having issue on verify address</p>`
                let emailContent = {
                    "Subject": 'User issue on verify address',
                    "Body": body,
                    "ToAddresses": ['log7cetch@gmail.com'],
                }
                // sendGenericEmail(emailContent)
                console.log("SOMETHING WENT WRONG ON Verify Address ->>>>>>>>>>> issue here >>>>>>>>>>>!",err)
                location.reload();
        });
    }
}

const getCustomerReferCode = () => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            await fetch(Gconfig.fpAPI + 'Customer/GetCustomerReferCode', {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
            }).then(response => response.json()).then(data => {
                if (data.success == true) {
                    let customer = getstate().customer.customer
                    customer.customer_refer_code = data?.refer_code?.coupon_id
                    cookie.set('userReferCode', data?.refer_code?.coupon_id)
                    dispatch(fetchCustomerSuccess(customer))
                    return data.refer_code
                } else {
                    console.log(data)
                }
            })
        }
    }
    else return async (dispatch, getstate) => {}
}

const getCustomerDetailInfo = () => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'Customer/GetCustomerInfo', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

const updateProfile = (data) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'Customer/UpdateProfile', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

const updateBasicProfile = (data) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'Customer/UpdateProfileFields ', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

const setDefaultShippingAddress = (obj) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            return fetch(Gconfig.fpAPI + `Customer/SetDefaultShippingAddress/${obj.id}/${obj.city}/${obj.state}/${obj.zip}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            }).then(response => response.json()).then(data => {
                if (data.success) {
                    let customerAddresses = []
                    getstate().customerAddresses.addresses.find(address => {
                        if (address.id == obj.id) {
                            address.default_address = true
                            customerAddresses.push(address)

                            var customer = getstate().customer.customer
                            if(customer.zone != data.message) { // message contains updated zone
                                customer.zone = data.message
                                dispatch(fetchCustomerSuccess(customer))
                            }

                        } else {
                            address.default_address = false
                            customerAddresses.push(address)
                        }
                    })
                    dispatch(fetchCustomerAddressesSuccess(customerAddresses))
                    return data
                } else {
                    return data
                }
            })
        }
    }
}

const setDefaultPaymentMethod = (data) => {
    let postData = {
        payment_method_id: data.id,
        customer_stripe_id: data.stripe_id,
        stripe_card_id: data.stripe_card_id
    }
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            return fetch(Gconfig.fpAPI + 'Customer/SetDefaultPaymentMethod/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
                body: JSON.stringify(postData)
            }).then(response => response.json()).then(resData => {
                if (resData.success == true) {
                    let updatedPayMethods = []
                    let defaultCard = {}
                    getstate().customerPaymentsMethods.paymentMethods.filter((payMethods) => {
                        if (payMethods.id == parseInt(postData.payment_method_id)) {
                            payMethods.default_card = 1
                            updatedPayMethods.push(payMethods)
                            defaultCard=payMethods
                        }
                        if (payMethods.id != parseInt(postData.payment_method_id)) {
                            payMethods.default_card = 0
                            updatedPayMethods.push(payMethods)
                        }
                    })
                    let customer = getstate().customer.customer;
                    if(customer){
                        customer.default_payment_method = defaultCard
                        dispatch(fetchCustomerSuccess(customer))
                    }
                    dispatch(fetchCustomerPaymentsSuccess(updatedPayMethods))
                    return resData
                } else {
                    return resData
                }
            })
        }
    }
}

const updatePaymentMethodInfo = (updateCardData) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            return fetch(Gconfig.fpAPI + 'Customer/EditPaymentMethod', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
                body: JSON.stringify(updateCardData)
            }).then(response => response.json()).then(resData => {
                if (resData.success == true) {
                    let paymentCardArray = getstate().customerPaymentsMethods.paymentMethods
                    paymentCardArray.filter(cardData => {
                        if (updateCardData.payment_method_id == cardData.id) {
                            cardData.name_on_card = updateCardData.name_on_card
                            cardData.expiry = updateCardData.exp_month + updateCardData.exp_year.slice(2, 4)
                            cardData.address1 = updateCardData.address_line1
                            cardData.address2 = updateCardData.address_line2
                            cardData.city = updateCardData.address_city
                            cardData.state = updateCardData.address_state
                            cardData.zip = updateCardData.address_zip
                            cardData.billing_address_id = updateCardData.address_id
                        }
                    })
                    dispatch(fetchCustomerPaymentsSuccess(paymentCardArray))
                    return resData
                } else {
                    return resData
                }
            })
        }
    }
}

const removePaymentMethod = (data) => {
    let removeData = {
        payment_method_id: data.id,
        customer_stripe_id: data.stripe_id,
        stripe_card_id: data.stripe_card_id
    }
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return async (dispatch, getstate) => {
            return fetch(Gconfig.fpAPI + 'Customer/RemovePaymentMethod', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
                body: JSON.stringify(removeData)
            }).then(response => response.json()).then(resData => {
                if (resData.success == true) {
                    let updatedPayMethods = getstate().customerPaymentsMethods.paymentMethods.filter((payMethods) => {
                        if (payMethods.id != removeData.payment_method_id) {
                            return payMethods
                        }
                    })
                    dispatch(fetchCustomerPaymentsSuccess(updatedPayMethods))
                }
                return resData
            })
        }
    }
}

const updatePassword = async (oldPass, newPass) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    let shaNewPassword = await sha256(newPass)
    let shaOldPassword = await sha256(oldPass)
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'customer/UpdatePassword', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid},
            body: JSON.stringify({
                old_password: changeStringToMD5(oldPass)+'###'+shaOldPassword,
                new_password: shaNewPassword
            })
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

const insertCustomerInformation = (data) => {
    // let customer_ip = cookie.get('customer_ip')
    return fetch(Gconfig.banjoAPI + 'Customer_data_endpoint/InsertCustomerRelatedInformation', {
        method: 'POST',
        // headers:{ 'customer_ip':customer_ip },
        body: JSON.stringify(data)
    }).then((response) => {
        if(response.status === 200){
            return response.json();     
        }else{
            let body = `<p>Application: Website<br /><br />${data.email} User having issue on adding user Data</p>`
            let emailContent = {
                "Subject": 'User issue on adding user Data',
                "Body": body,
                "ToAddresses": ['log7cetch@gmail.com'],
            }
            // sendGenericEmail(emailContent)
            console.log("SOMETHING WENT WRONG ON Adding profile Data ->>>>>>>>>>> issue here >>>>>>>>>>>!")
            // location.reload();
            return true
        }
    }).then(data => {
        return true
    }).catch(err => {
        let body = `<p>Application: Website<br /><br />${data.email} User having issue on adding user Data</p>`
        let emailContent = {
            "Subject": 'User issue on adding user Data',
            "Body": body,
            "ToAddresses": ['log7cetch@gmail.com'],
        }
        // sendGenericEmail(emailContent)
        console.log("SOMETHING WENT WRONG ON Adding profile Data ->>>>>>>>>>> issue here >>>>>>>>>>>!")
        return true
            // location.reload();
    });
}

const addPaymentMethodWithAddress = (data,token) => {
    let customer_token = getAuthTokenfromcookie();
    if(token)
    customer_token = token
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    var _headers = { 'Content-Type': 'application/json' };
    if (!data.guest_customer) _headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid };
    return fetch(Gconfig.fpAPI + 'Customer/AddPaymentMethodWithAddress/', {
        method: 'POST',
        headers: _headers,
        body: JSON.stringify(data)
    }).then(response => response.json()).then(resData => {
        return resData
    })
}
const stripeChargeGiftCoupon = (data) => {
    // let customer_ip = cookie.get('customer_ip')
    return fetch(Gconfig.banjoAPI + '/Stripe_api/create_charge/', {
        method: 'POST',
        // headers: { 'customer_ip':customer_ip },
        body: JSON.stringify(data)
    }).then(response => response.json()).then((response) => {
        return response;
    })
}

const addGiftCoupon = (data) => {
    var formData = new FormData();
    // let customer_ip = cookie.get('customer_ip')
    Object.entries(data).map((value, key) => formData.append(value[0], value[1]));
    return fetch(Gconfig.banjoAPI + '/Ajaxactions/coupon_create_gift/', {
        method: 'POST',
        body: formData,
        // headers: { 'customer_ip':customer_ip },
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
    }).then(response => response.json()).then((response) => {
        return response;
    }).catch((e) => console.log(e));
}

function getCustomerCardError(email, stripeId) {
    // let customer_ip = cookie.get('customer_ip')
    return fetch(Gconfig.banjoAPI + 'interactions/get_customer_card_error?email='+email+'&customerId='+stripeId, {
        method: 'GET',
        // headers: { 'customer_ip':customer_ip }
    }).then(response => response.json()).then(data => {
        return data
    }).catch(err => {
        return err
    })
}
const setCustomerPreferences = async (data) => {
    ////////THIS FUNCTION IS NOT USED ANYWHERE AND IS TOTTALY USELESS BE CAREFUL
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    var _data = null
    if (customer_token != undefined) {
        await fetch(Gconfig.fpAPI + `Customer/UpdatePreferences`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            _data = data;
        })
    }
    return _data;
}
// ITEM REMOVED - getCustomerPreferences - Created new one at @/redux/thunks/customerPreferencesThunks location
// ITEM REMOVED - populatePreferenceFormate - Created new one at @/functions/customer-preferences location
// ITEM REMOVED - updateCustomerPreferences - Created new one at @/redux/thunks/customerPreferencesThunks location

function fedExLocationEmail(customerId) {
    // let customer_ip = cookie.get('customer_ip')
    fetch(Gconfig.banjoAPI + 'fedex/fedexLocationEmail/' + customerId, { method: 'POST'})
}

// ITEM REMOVED - hideNotificationBar - Created new one at @/redux/thunks/notificationThunks location
// ITEM REMOVED - getNotificationMessage - Created new one at @/redux/thunks/notificationThunks location

async function getReturnOrderById(orderObj) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip');
    let request_guid = cookie.get('request_guid')
    const formData = new FormData();
    formData.append('customerEmail', orderObj.customerEmail);
    formData.append('orderName', orderObj.orderName);

    let url = 'Customer/GetOrderByOrdernameAndEmail'
    if(orderObj.orderName) {
        let order_id = orderObj.orderName.replace('#', '')
        order_id = order_id - 1000;
        if(order_id >= Gconfig.returnV2OrderId) {
            url = 'Customer/GetOrderByOrdernameAndEmailV2'
        }
    }

    // if(window.location.pathname.indexOf('new_returns') >-1) {
    //     url = 'Customer/GetOrderByOrdernameAndEmailV2'
        
    // }

    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + url, {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: formData,
            mimeType: "multipart/form-data",
        }).then(response => response.json()).then(async data => {
                return data
        })
    }
}

function uploadProductImage(uploadImage,folderType) {
    const formData = new FormData();
    formData.append('images', uploadImage);
    formData.append('folder_type', folderType);
    return fetch(Gconfig.fpAPI + "Content/UploadImages",{
        method: "POST",
        body: formData,
    }).then(async(resp)=> await resp.json()).then(data => {
        return data;
    })
}

const getCustomerInfoWithoutAuth = (email) => {
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    // if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'Customer/GetAnyCustomerInfo/?email='+email, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json','X-Request-For':customer_ip, 'guid': request_guid }
        }).then(response => response.json()).then(data => {
            return data
        })
    // }
}

function submitRetunItems(data, isReturnV2) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    
    // var formData = new FormData();
    // data.map((data,ind) => {
    //     let obj = data
    //     delete  obj['prod_obj']
    //     data['image_url'] = ''
    //     Object.entries(obj).map((value, key) => formData.append(value[0], value[1]));
    // })
    // console.log('form data', formData)

    data.length > 0 && data.map((val,ind) => {
        if(val.image_url && val.image_url != "") {
            let url = val.image_url.split('/');
            let imagename = url[url.length - 1]
            let foldername =  url[url.length - 2]
            data[ind].image_url = foldername+'/'+imagename;
        }
    })

    let query = "Invoice/AddInvoiceItemReturnedList"
    if(isReturnV2){
        query = "Invoice/AddInvoiceItemReturnedListV2"
    }
    return fetch(Gconfig.fpAPI + query,{
        method: "POST",
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json','X-Request-For':customer_ip, 'guid': request_guid} ,
        body: JSON.stringify(data),
        // method: 'POST',
        // body: formData,
        // contentType: false,
        // mimeType: "multipart/form-data",
    }).then(resp => resp.json()).then(data => {
        return data;
    })
}


function getPolicyRefundType() { 
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Refund/GetRefundReturnAll', {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + customer_token, 'Content-Type': 'application/json','X-Request-For':customer_ip, 'guid': request_guid} ,
    }).then(response => response.json()).then(data => {
        return data
    })
}

function reasonTitle(reason) {
    reason = reason && reason.toLowerCase();
    if(reason == 'toosmall') return 'Too Small';
    else if(reason == 'toobig') return 'Too Big';
    else if(reason == 'changedmymind') return 'Changed my mind';
    else if(reason == 'damaged') return 'Damaged';
    else if(reason == 'other') return 'Other';        
}

const returnConfirmationEmailHTML = async (order, returnedItemArr, refundSummaryObject, loggedinCustomer, email, guestCustomer, returnDays, order_no, lastDate, fedex_url, isCurrentlyBorowing, customerRetunLabel) => {
  
    let customerObj = ''
    let customerSubscription = false
    
    if(loggedinCustomer.customer == 'not_login') {
        customerObj =  guestCustomer
        if(guestCustomer && guestCustomer.tags.indexOf('plan') > -1)
            customerSubscription = true
        else
            customerSubscription = false
    }
    else {
        customerObj = loggedinCustomer.customer 
        if(loggedinCustomer.customer.has_subscription) 
            customerSubscription = true
        else
            customerSubscription = false  
    }

    let emailHTML = `
        <div class="top-image">
            <div class="top-image-width">
                <img src="${Gconfig.fashionpassImageStorage}assets/img/fashionpass-logo.png" width="305px" />
            </div>
            <div class="heading-logo">RENT. WEAR. 'GRAM. REPEAT. </div>
            <div class="return-confirmation parent">
                <div>
                    <div class="heading font-weight-bold"> RETURN FORM CONFIRMATION <span class="order-number font-weight-bold ml-5"> #${order_no}</span> </div>
                    <div class="child">
                        <div class="check-icon">
                            <div style="text-align: center; padding-bottom: 10px;">
                                <img src="${Gconfig.fashionpassImageStorage}assets/img/CheckCircle-Solid.png" width="40px" height="40px" />
                            </div>
                            <div class="bold-small" style="margin-top: 15px;">Thank you, your return form has been successfully submitted! </div>
                            <div class="simple-small" style="text-align: center; font-size:14px">A confirmation email has been sent to <a class="ml-3" style="color:#222; text-decoration:none" > ${email} </a> </div>
                        </div>
                        <div class="importent-note mt-10">
                            ${!customerSubscription ? `<div class="danger-note bold"> Return must be scanned at a Fedex Ground location by ${lastDate} to qualify for a refund. </div>` : `<div class="danger-note bold"> ${isCurrentlyBorowing == true ? 'Items must be returned with your current rental order' : 'Items must be returned with your next rental order' }</div>`}
                            <div class="simple-big mt-10">
                                <span class="bold mr-3">IMPORTANT </span> 
                                All items must be <span class="bold ml-3"> unworn with the tags on.</span>
                            </div>
                            <div class="simple-big width-ninty">All items sent back outside of this process will not be processed and cannot be recovered.</div>
                        </div>
                        <div class="list">
                            <div class="bold-small p-0 mobile-heading" style="text-align: left;"> RETURN CHECKLIST </div>
                            <div class="mobile-marker mt-10">
                                <div>
                                    <div class="simple-small">
                                        <span class="bold-small mr-3">1. Need a return label? </span>
                                         ${isCurrentlyBorowing == false && customerSubscription ? 'Please use the return label from your next order.' : 'View & print your return label below.'}
                                         ${isCurrentlyBorowing == false && customerSubscription ? '' : `<i><br/>Note, If you are an active FashionPass member, use the pre-paid return label included with your rental order to return your purchased item(s) along with your rentals.</i>`}
                                    </div>
                                </div>
                                ${isCurrentlyBorowing == false && customerSubscription ? '' : 
                                    `<div class="div-button">
                                        ${ customerRetunLabel && customerRetunLabel != "" &&  customerRetunLabel != undefined && customerRetunLabel != null && `<a href="${customerRetunLabel}" target="_blank" class="button"> PRINT RETURN LABEL </a>` }
                                    </div>` }
                            </div>
                            <div class="mobile-marker">
                                <div class="simple-small">
                                    <span class="bold-small mr-3">2. Pack your return.</span>
                                    Place the items you want to return in the package of your choice. Make sure that your items are 
                                    <u class="ml-3"> unworn and still have the tags on.</u>
                                </div>
                            </div>
                            <div class="mt-10 mobile-marker">
                                <div class="simple-small">
                                    <span class="bold-small mr-3">3. Drop off your package.</span>
                                    Drop off your package at a Fedex Ground location to ensure a smooth return,
                                    <span class="bold-small-danger bold ml-3 mr-3"> DO NOT </span>
                                    <span class="simple-small-danger mr-3"> drop your package into a FedEx Express drop box. </span>
                                    Dropping your package into a FedEx Express dropbox will delay your return by at least a week.
                                </div>
                            </div>
                            <div class="div-button">
                               ${fedex_url && `<a href="${fedex_url}" class="button"> FIND A DROPOFF LOCATION </a>` }
                            </div>
                            <div class="mt-10 mobile-marker">
                                <div class="simple-small">
                                    <b class="mr-3" >4.</b> 
                                    After we receive your return, it will be inspected and processed by our returns team You will recieve a follow up email with your approved refund amount. Please allow up to 14 days to process your return.
                                </div>
                            </div>
                        </div>

                        <div class="desktop">
                            <div class="return-container">
                                <h4> <b> ${returnedItemArr && returnedItemArr.length == 1 ? 'RETURN ITEM' : 'RETURN ITEMS'} (${returnedItemArr && returnedItemArr.length}) </b> </h4>
                                ${
                                returnedItemArr && returnedItemArr.length > 0 && 
                                returnedItemArr.map((rItem, ind) => {
                                let val = rItem.prod_obj;
                                let discountPrice = val && Math.floor((val.sku.retail - ((val.sku.retail * val.discount_rate) / 100)));
                                return( 
                                    `<div class="row-itembox">
                                        <div class="desktop-flex">
                                            <div class="row-itembox-flex">
                                                <div class="cartImageArea">
                                                    <a> <img class="blurFilter desktop-picture" src=${Gconfig.productImageUrl + (val.product.images[0].product_image_url + "?profile=c")} /> </a>
                                                </div>
                                                <div class="width-100">
                                                    <div class="cartInfoArea-flex">
                                                        <div class="cartInfoArea">
                                                            <span class="item-name bold mr-10"> ${val.product.product_title} </span>
                                                            <span class="item-name"> ${val.product.vendor} </span>
                                                                ${!customerSubscription ?
                                                                (val.type && val.type.indexOf('New') > -1  ?
                                                                `<span class="new-tag"> NEW </span>` : `<span class="new-tag"> LIKE-NEW </span>`) : ''
                                                                }
                                                        </div>
                                                        <div class="qty-price"> <span> QTY:1 </span> </div>
                                                        <div class="price-div">
                                                            ${ val.sku.retail == discountPrice ?
                                                                `<b> $${val.sku.retail} </b>` :  
                                                                `<div>
                                                                    <div class="old-price bold">$${val.sku.retail}</div>
                                                                    <div class="discount-price bold">$${discountPrice}</div>
                                                                </div>
                                                            `}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="size" style="margin-top: 20px;">SIZE: ${val.sku.size}</div>
                                                        <div class="mt-10 width-80">
                                                            <span class="refund-head-desktop"> Refund Reason: </span>
                                                            <span> ${ reasonTitle(rItem.reason && rItem.reason)}${rItem.image_description ? ', '+rItem.image_description : ''}${rItem.damage_description ? ', '+rItem.damage_description : ''} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`
                                    )
                                    }).join('')
                                }
                                <div class="text"> REFUND SUMMARY </div>
                                    <div class="width-table">
                                        <div class="width-table-flex">
                                            <span class="for bold-text bold">Subtotal Refund</span>
                                            <span class="amount bold-text bold">$${parseFloat(refundSummaryObject.total_item_refund).toFixed(2)} </span>
                                        </div>
                                        <div class="width-table-flex">
                                            <span class="for"> Total Tax Refund </span>
                                            <span class="amount"> ${refundSummaryObject.taxPrice && refundSummaryObject.taxPrice == 0 ? '$0.00' : `$${parseFloat(refundSummaryObject.taxPrice).toFixed(2)}`} </span>
                                        </div>
                                        ${refundSummaryObject.return_shipping_fee > 0  ? `<div class="width-table-flex">
                                            <span class="for"> Return Shipping fee </span>
                                            <span class="amount"> -$${parseFloat(refundSummaryObject.return_shipping_fee).toFixed(2)} </span>
                                        </div>` : ''}
                                        ${refundSummaryObject.promo_amount > 0 ? `<div class="width-table-flex">
                                            <span class="for text-redd"> Promo Code </span>
                                            <span class="amount text-redd"> -$${parseFloat(refundSummaryObject.promo_amount).toFixed(2)} </span>
                                        </div>` : ''}
                                        ${refundSummaryObject.monthly_discount > 0 ? `<div class="width-table-flex">
                                            <span class="for text-redd"> Monthly Discount </span>
                                            <span class="amount text-redd"> -$${parseFloat(refundSummaryObject.monthly_discount).toFixed(2)} </span>
                                        </div>` : ''}

                                        ${refundSummaryObject.account_credit > 0 ? `<div class="width-table-flex  mt-10">
                                            <span class="for bold "> Estimated Account Credit Refund* </span>
                                            <span class="amount bold"> ${refundSummaryObject.account_credit == 0 ? '$0.00' : `$${parseFloat(refundSummaryObject.account_credit).toFixed(2)}`} </span> 
                                        </div>` : ''}

                                        <div class="text-center mt-5 font13">*This is an estimate Actual refund amount is subject to applied discounts, tax, shipping costs, and item condition.</div>
                                        <div class="text-center bold"> Note, items returned without tags <i> and protective liners attached </i> <b style="margin-left: 3px; margin-right: 3px;" > WILL NOT </b> be eligible for a refund. </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mobile">
                            <div class="return-container">
                                <h4><b> ${returnedItemArr && returnedItemArr.length == 1 ? 'RETURN ITEM' : 'RETURN ITEMS'} (${returnedItemArr && returnedItemArr.length}) </b></h4>
                                ${
                                returnedItemArr && returnedItemArr.length > 0 && 
                                returnedItemArr.map((rItem, ind) => {
                                let val = rItem.prod_obj;
                                let discountPrice = val && Math.floor((val.sku.retail - ((val.sku.retail * val.discount_rate) / 100)));
                                return( 
                                    `<div class="row-itembox">
                                        <div class="row-itembox-flex">
                                            <div class="cartImageArea">
                                                <img class="blurFilter" src=${Gconfig.productImageUrl + (val.product.images[0].product_image_url + "?profile=c")} />
                                            </div>
                                            <div class="pad-left-5">
                                                <div class="cartInfoArea">
                                                    <span class="item-name bold text-black"> ${val.product.product_title} </span>
                                                    <span class="item-name text-black"> ${val.product.vendor} </span>
                                                    ${!customerSubscription ?
                                                        (val.type && val.type.indexOf('New') > -1  ?
                                                        `<span class="new-tag text-black">NEW</span>` : `<span class="new-tag text-black">LIKE-NEW</span>`) : ''
                                                    }
                                                    <span class="size text-black">SIZE: ${val.sku.size} </span>
                                                </div>
                                                <div class="qty-price">
                                                    <div class="text-black"> COLOR: ${val.sku.color} </div>
                                                    <div class="text-black"> QTY:1 </div>
                                                    <div class="mt-10">
                                                        ${ val.sku.retail == discountPrice ?
                                                            `<b class="text-black"> $${val.sku.retail} </b>` :   
                                                            `<div>
                                                                <div class="old-price bold text-black">$${val.sku.retail}</div>
                                                                <div class="discount-price bold">$${discountPrice}</div>
                                                            </div>`
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-10">
                                            <span class="refund-head-mobile f11 text-black">Refund Reason:</span>
                                            <span class="f11 text-black" > ${reasonTitle(rItem.reason && rItem.reason)}${rItem.image_description ? ', '+rItem.image_description : ''}${rItem.damage_description ? ', '+rItem.damage_description : ''} </span>
                                        </div>
                                    </div>`
                                   )
                                  }).join('')
                                }
                            </div>
                            <div class="return-container">
                                <p class="text"> REFUND SUMMARY</p>
                                <div class="width-table">
                                    <div class="width-table-flex">
                                        <span class="for bold-text bold f11 text-black">Subtotal Refund </span>
                                        <span class="amount bold-text bold f11 text-black">$${parseFloat(refundSummaryObject.total_item_refund).toFixed(2)} </span>
                                    </div>
                                    <div class="width-table-flex">
                                        <span class="for f11 text-black"> Total Tax Refund </span>
                                        <span class="amount f11 text-black"> ${refundSummaryObject.taxPrice && refundSummaryObject.taxPrice == 0 ? '$0.00' : `$${parseFloat(refundSummaryObject.taxPrice).toFixed(2)}` } </span>
                                    </div>
                                    ${customerSubscription && refundSummaryObject.refundMethod == 1 ? `<div class="width-table-flex">
                                        <span class="for f11 text-black"> Bonus Account Credit </span>
                                        <span class="amount f11 text-black"> $5.00 </span>
                                    </div>` : ''}
                                    ${refundSummaryObject.promo_amount > 0 ? `<div class="width-table-flex">
                                        <span class="for f11 text-redd"> Promo Code </span>
                                        <span class="amount f11 text-redd"> -$${parseFloat(refundSummaryObject.promo_amount).toFixed(2)} </span>
                                    </div>` : ''}
                                    ${refundSummaryObject.monthly_discount > 0 ? `<div class="width-table-flex">
                                        <span class="for f11 text-redd"> Monthly Discount </span>
                                        <span class="amount f11 text-redd"> -$${parseFloat(refundSummaryObject.monthly_discount).toFixed(2)} </span>
                                    </div>` : ''}
                                    ${customerSubscription && refundSummaryObject.refundMethod == 0 && refundSummaryObject.account_credit > 0 ?`<div class="width-table-flex mt-10">
                                        <span class="for bold f11 text-black"> Estimated Account Credit Refund* </span>
                                        <span class="amount bold f11 text-black"> ${refundSummaryObject.account_credit == 0 ? '$0.00' : `$${parseFloat(refundSummaryObject.account_credit).toFixed(2)}`} </span>
                                    </div>` : ''}
                                    <div class="width-table-flex  ${customerSubscription && refundSummaryObject.refundMethod == 1 ? "mt-10" : ""} ${customerSubscription && refundSummaryObject.refundMethod == 0 && refundSummaryObject.account_credit == 0 ? 'mt-10' : ''}  ${!customerSubscription && refundSummaryObject.refundMethod == 0 ? "mt-10" : ""} ">
                                        <span class="for bold f11 text-black"> ${refundSummaryObject.refundMethod == 1 ? `Estimated Account Credit Refund*` : `Estimated Refund to ${refundSummaryObject.paymentMethod ? refundSummaryObject.paymentMethod.brand : ''} ending in ${refundSummaryObject.paymentMethod ? refundSummaryObject.paymentMethod.last4 : ''}*`} </span>
                                        <span class="amount bold f11 text-black"> ${refundSummaryObject.refundMethod == 1 ? `$${ parseFloat((refundSummaryObject.totalPrice + 5 + refundSummaryObject.account_credit)).toFixed(2)}` :  `$${ parseFloat(refundSummaryObject.totalPrice).toFixed(2)}`} </span>
                                    </div>
                                    <div class="text-center mt-5 f11 text-black">*This is an estimate Actual refund amount is subject to applied discounts, tax, shipping costs, and item condition </div>
                                    <div class="text-center bold text-black f11 "> Note, items returned without tags <i> and protective liners attached </i>  <b style="margin-left: 3px; margin-right: 3px;"> WILL NOT </b> be eligible for a refund. </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>`
    
        return emailHTML
}
function addCustomerContact(obj) {
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Customer/AddCustomerContact', {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: { 'Content-Type': 'application/json','X-Request-For':customer_ip, 'guid': request_guid},
    }).then(response => response.json()).then(data => {
        return data
    })
}

async function setCustomerPhoneNumber(obj) {
    let customer_token = cookie.get('fp_signup_customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if(customer_token != undefined){
        return fetch(Gconfig.fpAPI + 'Customer/setCustomerPhone', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid},
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}
async function checkCustomerCard(obj) {
    let customer_token = cookie.get('fp_signup_customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if(customer_token != undefined){
        return fetch(Gconfig.fpAPI + 'Customer/checkCustomerCard', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid},
            body: JSON.stringify(obj),
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

async function addCustomerNewCard(obj) {
    let customer_token = cookie.get('fp_signup_customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if(customer_token != undefined){
        return fetch(Gconfig.fpAPI + 'stripe/addnewcard_Pricing', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid},
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

async function createCustomerMembership(obj) {
    let customer_token = cookie.get('fp_signup_customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if(customer_token != undefined){
        return fetch(Gconfig.fpAPI + 'customer/CreateMembership', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid},
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

async function getCustomerCredit(customerId) {
    let customer_token = cookie.get('fp_signup_customer_token')
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    customer_token = customer_token && customer_token.replaceAll('"','')
    if(customer_token != undefined){
        return fetch(Gconfig.fpAPI + 'Customer/getCustomerCredit?customerId='+customerId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid},
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

function getCustomerInfoByEmail(obj) {
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + `customer/getcustomerinfobyemail?email=${obj.email}&isRequireLastOrder=${obj.isRequireLastOrder}`, {
        method: 'GET',
        headers: {'X-Request-For':customer_ip,'Authorization': 'Bearer eyJBdXRob3IiOiJGYXNoaW9uUGFzcyIsImFsZyI6IkhTMjU2In0.e30.oUQGjCS2S_jycg4PZnFK4uQ81DsNFX-N1m81Dfahi6o', 'guid': request_guid}
    }).then(response => response.json()).then(data => {
        return data
    })
}
async function checkCustomerEmail(obj) {
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'customer/checkemail_Pricing', {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: { 'Content-Type': 'application/json','X-Request-For':customer_ip, 'guid': request_guid },
    }).then(response => response.json()).then(data => {
        return data
    })
}
async function getCustomerDiscountEligibility() {
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let customer_token = getAuthTokenfromcookie();
    if(customer_token != undefined){
    return fetch(Gconfig.fpAPI + 'Customer/GetCustomerDiscountEligibility', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid},
    }).then(response => response.json()).then(data => {
        return data
    })}
}
async function crossEmailbytemplate(email,tempId) {
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Customer/SGEmailbytemplate?email='+email+'&templateId='+tempId, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','X-Request-For':customer_ip, 'guid': request_guid}
    })
}
// ITEM REMOVED - checkIpForBadCustomer - Created new one at @/functions/customer-functions location

async function sendFraudEmail(data) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        fetch(Gconfig.fpAPI + 'Customer/SendEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify(data)
        })
    }
}
async function emailBlockedCustomerByCookie(customerId) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let badCustomerId= cookie.get('bad_customer_id')
    if(badCustomerId == undefined)
        badCustomerId=0
    return fetch(Gconfig.fraudAPI + 'FraudValidation/BlockedByCookieEmail?customerId='+customerId+'&blockCookieCustomerId='+badCustomerId, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip},
    }).then(response => response.json()).then(data => {
        // console.log(data,'-------------->>>>>>>>>>>>>>email data')
        return data
    })
}

async function checkMatchIpForBadCustomer(customer_id,ip) {
    return fetch(Gconfig.fraudAPI + 'FraudValidation/CheckMatchingIp?customerid='+customer_id+'&ipAddress='+ip, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    }).then(response => response.json()).then(data => {
        return data
    })
}
async function refundCreditToAccount(obj, userToken) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    if(userToken)
        customer_token = userToken
    return fetch(Gconfig.fpAPI + 'Customer/AddAccountCreditsByCustomer', {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
    }).then(response => response.json()).then(data => {
        return data
    })
}
const confirmationKlavioData=(data,refundSummaryObject,orderInfo)=>{
    let return_items=[];
    let refund_summry=[];
    let orders_data=[];
    let klavio_object={}
    let loggedinCustomer=orderInfo.customer
    let guestCustomer=orderInfo.guestCustomer
    let customer_subscription = false
    let customerObj = ''
    let total_estimate_amount=0;
    let monthly_discount=0
    let promo_code=0
    let bonus_account_credit=0
    let red_message=''
    let return_label_message=''
    let return_label_link=''
    let estimate_account_message='';
    let estimate_acount_message_no_refund='';
    let total_estimate_amount_no_refund=0;
    let pick_ur_return=''
    let total_tax_refund=refundSummaryObject.taxPrice == 0 ? `${parseFloat(0).toFixed(2)}` : `${parseFloat(refundSummaryObject.taxPrice).toFixed(2)}`
    let return_shipping_fee = refundSummaryObject.return_shipping_fee
    //subscribe condition
    if(loggedinCustomer.customer == 'not_login') {
        customerObj =  guestCustomer
        if(guestCustomer && guestCustomer.tags.indexOf('plan') > -1)
            customer_subscription = true
        else
            customer_subscription = false
    }
    else {
        customerObj = loggedinCustomer.customer 
        if(loggedinCustomer.customer.has_subscription) 
            customer_subscription = true
        else
            customer_subscription = false  
    }

    //pick your return
    if( customer_subscription)
    {
        pick_ur_return='Include any items you want to return along with your rentals in the included shipping bag.'
    }
    else{
        pick_ur_return='Place the items you want to return in the package of your choice.'
    }

    //return_label_link
    if(!(orderInfo.isCurrentlyBorowing == false && customer_subscription ))
    {
    if(orderInfo.customerRetunLabel && orderInfo.customerRetunLabel != "" && orderInfo.customerRetunLabel != undefined && orderInfo.customerRetunLabel != null)  
    {
        return_label_link=orderInfo.customerRetunLabel
    }
    }

    //return_label_message
    if(orderInfo.isCurrentlyBorowing == false && customer_subscription )
    {
        return_label_message= 'Please use the return label from your next order.' 
    }
    else{
        return_label_message='View & print your return label below. <i><br/>Note, If you are an active FashionPass member, use the pre-paid return label included with your rental order to return your purchased item(s) along with your rentals.</i>'
    }

    //monthly_discount_condition
    if(refundSummaryObject.monthly_discount>0)
    {
        monthly_discount=parseFloat(refundSummaryObject.monthly_discount).toFixed(2)
    }

    //promo_amount
    if(refundSummaryObject.promo_amount > 0)
    {
        promo_code=parseFloat(refundSummaryObject.promo_amount).toFixed(2)
    }

    //bonus credit
    if(customer_subscription && refundSummaryObject.refundMethod == 1 )
    {
        bonus_account_credit=`${parseFloat(5).toFixed(2)}`
    }


    // important_message
    if (customer_subscription) {
        if (orderInfo.order && orderInfo.order.last_rental_order) {
        if (orderInfo.isCurrentlyBorowing) {
            red_message= `Return must be sent back with your current rental order`;
        } else {
            red_message =`Items must be sent back with your next rental order`;
        }
        }
        else{
            red_message =`Items must be sent back with your next rental order`; 
        }
    } else {
        red_message=`Return must be scanned at a FedEx Ground location by ${orderInfo.lastDate} to qualify for a refund`;
    }

    //estimate_amount condition
    if((refundSummaryObject.refundMethod == 1 && refundSummaryObject.account_credit > 0)){
        estimate_acount_message_no_refund="Estimated Account Credit Refund*"
        total_estimate_amount_no_refund=refundSummaryObject.account_credit == 0 ? parseFloat(0).toFixed(2) : parseFloat(refundSummaryObject.account_credit).toFixed(2)
    }

    // if(refundSummaryObject.refundMethod == 1){
    //     estimate_account_message="Estimated Account Credit Refund*"
    //     total_estimate_amount=refundSummaryObject.refundMethod == 1 ? `${ parseFloat((refundSummaryObject.totalPrice + 5 + refundSummaryObject.account_credit)).toFixed(2)}` :  `${ parseFloat(refundSummaryObject.totalPrice).toFixed(2)}`
    // }
    // else{
    //     total_estimate_amount=refundSummaryObject.refundMethod == 1 ? `${ parseFloat((refundSummaryObject.totalPrice + 5 + refundSummaryObject.account_credit)).toFixed(2)}` :  `${ parseFloat(refundSummaryObject.totalPrice).toFixed(2)}`
    //     estimate_account_message=`Estimated Refund to ${refundSummaryObject.paymentMethod ? refundSummaryObject.paymentMethod.brand : ""} ending in ${refundSummaryObject.paymentMethod ? refundSummaryObject.paymentMethod.last4 : ""}*`
    // }


    data?.forEach((itemArr, index) => {
        let val = itemArr.prod_obj;
        let returnItems={
            img_url : Gconfig.productImageUrl + (val.product.images[0].product_image_url + "?profile=c"),
            product_title : val.product?.product_title,
            vendor:val.product?.vendor,
            before_price:val.sku.retail,
            after_price : val && Math.floor((val.sku.retail - ((val.sku.retail * val.discount_rate) / 100))),
            return_item_type:!customer_subscription?  (val.type && val.type.indexOf('New') > -1  ?
             'NEW' :  'LIKE-NEW') : '',
            discount_price : val && Math.floor((val.sku.retail - ((val.sku.retail * val.discount_rate) / 100))),
            size:val.sku.size,
            reason:reasonTitle(itemArr.reason && itemArr.reason),
            reason_description: itemArr?.image_description,
            reason_damage_description:itemArr?.damage_description,
            QTY:1,
        }
        return_items.push(returnItems)
    });
    let refundSummary={
        sub_total_refund:parseFloat(refundSummaryObject.total_item_refund).toFixed(2),
        total_tax_refund,
        estimate_account_message,
        total_estimate_amount,
        monthly_discount,
        promo_code,
        bonus_account_credit,
        estimate_acount_message_no_refund,
        total_estimate_amount_no_refund,
        return_shipping_fee
    }
    let ordersData={
        email:orderInfo?.email,
        red_message,
        fedex_url:orderInfo?.fedexurl,
        last_date:orderInfo?.lastDate,
        order_no:orderInfo?.customerOrderno,
        customer_subscription,
        total_return_item:data?.length,
        return_label_link,
        return_label_message,
        pick_ur_return
        }
    refund_summry.push(refundSummary);
    orders_data.push(ordersData);

    klavio_object={
        return_items,
        refund_summry,
        orders_data,
    }

let klavio_json={
    "data": {
        "type": "event",
        "attributes": {
            "profile": {
                "email": orderInfo?.email,
            },
            "metric": {
                "name": "Return Confirmation"
            },
            "properties": klavio_object,
            
        }
}

}



 let customer_token = getAuthTokenfromcookie();
     let customer_ip = cookie.get('customer_ip')
     let request_guid = cookie.get('request_guid')
     if (customer_token != undefined) {
         return fetch(Gconfig.fpAPI + 'Klaviyo/performaction_live?case=CreateEvent', {
             method: 'POST',
             headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
             body: JSON.stringify(klavio_json)
         }).then(response => response.json()).then(data => {
             console.log(data)
         })
     }
 }

 async function authenticateAgainstPhone(phone_no,code,smsDisable) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let query = 'Customer/AuthenticateAgainstPhoneNumber?PhoneNumber='+phone_no+'&isManual=true'+'&isDisableSMS='+smsDisable+"&Webhook=true"
    if(code != undefined){
        query = 'Customer/AuthenticateAgainstPhoneNumber?PhoneNumber='+phone_no+'&VerificationCode='+code+'&isDisableSMS='+smsDisable+"&Webhook=true"
    }
    return await fetch(Gconfig.fpAPI + query, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
    }).then(response => response.json()).then(data => {
        return data;
    });
}
    
async function authenticateAgainstEmail(email,code) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let query = 'Customer/AuthenticateAgainstEmail?email='+email+'&isManual=true'
    if(code != undefined)
        query = 'Customer/AuthenticateAgainstEmail?email='+email+'&VerificationCode='+code
    return await fetch(Gconfig.fpAPI + query, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
    }).then(response => response.json()).then(data => {
        return data;
    });
}

async function updateCustomerEmailAndPhone(data) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'Customer/UpdateCustomerEmail_PhoneNumber', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            return data
        })
    }
}

async function checkCustomerVerificationPopupShow() {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let query = 'Customer/IsCustomerVarified'
    return await fetch(Gconfig.fpAPI + query, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
    }).then(response => response.json()).then(data => {
        return data;
    });
}

function sendGenricKlavioEventEmail(kalivyo_json_obj) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = cookie.get('customer_ip')
    let request_guid = cookie.get('request_guid')
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + 'Klaviyo/performaction_live?case=CreateEvent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify(kalivyo_json_obj)
        }).then(response => response.json()).then(data => {
            console.log(data)
        })
    }
}

function updateCustomeProfileInfo(data) {
    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Customer/UpdateProfileInfo',{
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip, 'guid': request_guid },
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        return data
    })
}

function updateCustomerSizeInfo(data) {
    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Customer/UpdateCustomerMeasurement',{
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip, 'guid': request_guid },
        body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
        return data
    })
}

function sendCancelActivatedEmail(customerId) {
    let customer_token = getAuthTokenfromcookie();
    return fetch(Gconfig.fpAPI + `Stripe/SendCancelActivatedEmailFromBanjo?customer_id=${customerId}&sent_status=1`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token },
    }).then(response => response.json()).then(data => {
        console.log(data)
    })
}

async function fireSmsVerificationSub(email, sms_sub) {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let query = `Customer/UpdateCustomerSmsKlaviyo?cust_email=${email}&sms_sub=${sms_sub}&fire_sms=true`
    return await fetch(Gconfig.fpAPI + query, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
        // body:{}
        
    }).then(response => response.json()).then(data => {
        return data;
    });
}

export {
    getCustomerAddresses, getProvinceCode, getCustomerPaymentMethods,
    addCustomerShippingAddress, addCustomerCard, editCustomerShippingAddress,
    customerAddressesMarkLoadingFunction, getCustomerPoints, editCustomerBilling, setBillingAddressToCard,
    deleteCustomerShippingAddress, verify_address, getCustomerReferCode, getCustomerDetailInfo, updateProfile, updatePassword,
    insertCustomerInformation, updateBasicProfile, setDefaultShippingAddress, setDefaultPaymentMethod, removePaymentMethod, updatePaymentMethodInfo,
    addPaymentMethodWithAddress, stripeChargeGiftCoupon, addGiftCoupon, getCustomerCardError, setCustomerPreferences, 
    fedExLocationEmail ,getReturnOrderById, uploadProductImage,getCustomerInfoWithoutAuth, submitRetunItems, getPolicyRefundType, returnConfirmationEmailHTML, addCustomerContact,
    setCustomerPhoneNumber, checkCustomerCard, addCustomerNewCard, createCustomerMembership, getCustomerCredit, getCustomerInfoByEmail, checkCustomerEmail,getCustomerDiscountEligibility,
    crossEmailbytemplate,sendFraudEmail,emailBlockedCustomerByCookie,checkMatchIpForBadCustomer,refundCreditToAccount,confirmationKlavioData,authenticateAgainstPhone,authenticateAgainstEmail,
    updateCustomerEmailAndPhone, checkCustomerVerificationPopupShow, sendGenricKlavioEventEmail, updateCustomeProfileInfo, updateCustomerSizeInfo, fireSmsVerificationSub, sendCancelActivatedEmail
};
