import { FETCH_SUBCATEGORIES_SUCCESS, FETCH_SUBCATEGORIES_STARTED, FETCH_SUBCATEGORIES_FAILD, FETCH_MAINCAT_SUCCESS, FETCH_MAINCAT_STARTED, LOAD_ALL_COMPLETED, CLEARERROR } from '@/redux/actions/categoryActions';
import { HYDRATE } from 'next-redux-wrapper';
import { CategoryDataType, Category_SubCategoryDataType } from '@/services/collections';
import { AnyAction } from 'redux';

const initialState: CategorySliceDataType = {
  error: false,
  currentCatId: null,
  currentCat: null,
  currentSubCats: [],
  mainCatLoading: true,
  subcatLoading: true,
};

const catsData =
  (namespace: CategoriesNamespace) =>
  (state: CategorySliceDataType = initialState, action: AnyAction): CategorySliceDataType => {
    switch (action.type) {
      case HYDRATE:
        return { ...state, ...action.payload['cats' + namespace] };
      case namespace + '-' + FETCH_SUBCATEGORIES_STARTED:
        return { ...state, currentCatId: action.payload, subcatLoading: true };
      case namespace + '-' + FETCH_SUBCATEGORIES_SUCCESS:
        return { ...state, currentSubCats: action.payload, subcatLoading: false };
      case namespace + '-' + FETCH_SUBCATEGORIES_FAILD:
        return { ...state, error: action.payload, subcatLoading: false, mainCatLoading: false };
      case namespace + '-' + FETCH_MAINCAT_STARTED:
        return { ...state, mainCatLoading: true, subcatLoading: true };
      case namespace + '-' + FETCH_MAINCAT_SUCCESS:
        return { ...state, currentCat: action.payload, currentSubCats: action.payload.sub_categories, mainCatLoading: false, subcatLoading: false };
      case namespace + '-' + LOAD_ALL_COMPLETED:
        return { ...state, subcatLoading: false, mainCatLoading: false };
      case namespace + '-' + CLEARERROR:
        return { ...state, error: false };
      default:
        return state;
    }
  };

export default catsData;

export type CategoriesNamespace = 'Def' | 'Desktopmenu' | 'Mobilemenu';
export type CategorySliceDataType = {
  error: boolean;
  currentCatId: null | number;
  currentCat: null | CategoryDataType;
  currentSubCats: Category_SubCategoryDataType[];
  mainCatLoading: boolean;
  subcatLoading: boolean;
};
