import { FETCH_GUEST_SUCCESS } from '@/redux/actions/guestCustomerAction';
import { PostWaitlistCustomerPricingDataCustomerType } from '@/services/customer/types';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: GuestCustomerSliceDataType = {
  guestCustomer: {},
  loading: false,
  error: false,
};

export default function guestCustomerData(state: GuestCustomerSliceDataType = initialState, action: AnyAction): GuestCustomerSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.guestCustomer };
    case FETCH_GUEST_SUCCESS:
      return { ...state, guestCustomer: action.payload, loading: false };
    default:
      return state;
  }
}

export type GuestCustomerSliceDataType = {
  loading: boolean;
  error: boolean;
  guestCustomer: GuestCustomerDataType | Record<string, undefined>;
};
export type GuestCustomerDataType = {
  hasData: boolean;
  token: string;
  customerData: PostWaitlistCustomerPricingDataCustomerType;
  customer: {
    id: string;
    company: string;
    full_name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    make_default: boolean;
    email: string;
    phone: string;
    password: string;
  };
};
