import { UPDATE_DESKTOP_GRID_SUCCESS, UPDATE_MOBILE_GRID_SUCCESS } from '@/redux/actions/productGridViewSelectedAction';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: any = {
  desktopGridShowItem: 4,
  mobileGridShowItem: 2
};

function productData(state:any = initialState, action: AnyAction): any {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.gridView};
    case UPDATE_DESKTOP_GRID_SUCCESS:
      return { ...state, loading: false, desktopGridShowItem: action.payload };
    case UPDATE_MOBILE_GRID_SUCCESS:
        return { ...state, loading: false, mobileGridShowItem: action.payload };
    default:
      return state;
  }
}

export default productData;

