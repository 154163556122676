import { FETCH_SYSTEM_SETTING_SUCCESS, FETCH_SYSTEM_SETTING_FAILURE } from '@/redux/actions/systemSettingsActions';
import { HYDRATE } from 'next-redux-wrapper';
import { SystemSettingsType } from '@/services/website-settings';
import { AnyAction } from 'redux';

const initialState: SystemSettingsSliceDataType = {
  systemSettings: [],
  loading: true,
  error: false,
};

export default function systemSettingsData(state: SystemSettingsSliceDataType = initialState, action: AnyAction): SystemSettingsSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.systemSettings };
    case FETCH_SYSTEM_SETTING_SUCCESS:
      return { ...state, loading: false, systemSettings: action.payload };
    case FETCH_SYSTEM_SETTING_FAILURE:
      return { ...state, loading: false, systemSettings: [], error: true };
    default:
      return state;
  }
}

export type SystemSettingsSliceDataType = {
  loading: boolean;
  error: boolean;
  systemSettings: SystemSettingsType[];
};

export type SystemSettingsFpPlanDataType = {
  accessory: string;
  clothing: string;
  earlyCheckin: string;
  firstMonthDiscount: string;
  itemSwapRatio: string;
  items: string;
  monthlyPurchaseCredit: string;
  planName: string;
  planStripeId: string;
  planStripeIdNew: string;
  planStripeName: string;
  planStripePrice: string;
  planStripePriceNew: string;
  planWebName: string;
  price: string;
  stripeName: string;
  swapItems: string;
};
