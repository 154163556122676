import { combineReducers } from 'redux';
import customer_reducer from '@/redux/reducers/customerReducer';
import product_reducer from '@/redux/reducers/productReducer';
import customer_cart from '@/redux/reducers/cartReducer';
import recentProduct_reducer from '@/redux/reducers/recentProductReducer';
import productReview_reducer from '@/redux/reducers/productReviewReducer';
import plans_reducer from '@/redux/reducers/plansReducer';
import customer_addresses from '@/redux/reducers/customerAddressesReducer';
import customer_payments_methods from '@/redux/reducers/customerPaymentsReducer';
import customer_shipping_methods from '@/redux/reducers/shippingMethodReducer';
import catsData from '@/redux/reducers/categoryReducer';
import collectionPage from '@/redux/reducers/collectionPageReducer';
import systemSettings from '@/redux/reducers/systemSettingsReducer';
import customerPreferencesData from '@/redux/reducers/customerPreferencesReducer';
import renderingConfigData from '@/redux/reducers/renderingConfigReducer';
import spotsReducer from '@/redux/reducers/spotsReducer';
import customerBoards from '@/redux/reducers/customerBoardsReducer';
import notification from '@/redux/reducers/notificationReducer';
import saleTax from '@/redux/reducers/saleTaxReducer';
import guestCustomer from '@/redux/reducers/guestCustomerReducer';
import signupCustomer from '@/redux/reducers/signupCustomerReducer';
import serversideReload from '@/redux/reducers/checkServersideReloadReducer';
import brandData from '@/redux/reducers/brandReducer';
import gridView from '@/redux/reducers/productGridViewSelectedReducer';
import lastRentalReturnOrder from '@/redux/reducers/lastRentalReturnOrderReducers';

const rootReducer = combineReducers({
  customer: customer_reducer,
  product: product_reducer,
  recentProducts: recentProduct_reducer,
  productReviews: productReview_reducer,
  cart: customer_cart,
  currentPlans: plans_reducer,
  customerAddresses: customer_addresses,
  customerPaymentsMethods: customer_payments_methods,
  renderingConfig: renderingConfigData,
  shippingMethods: customer_shipping_methods,
  catsDef: catsData('Def'),
  catsDesktopmenu: catsData('Desktopmenu'),
  catsMobilemenu: catsData('Mobilemenu'),
  collectionPage: collectionPage,
  systemSettings: systemSettings,
  customerPreferences: customerPreferencesData,
  spots_left: spotsReducer,
  customerBoards: customerBoards,
  notification: notification,
  saleTax: saleTax,
  guestCustomer: guestCustomer,
  signupCustomer: signupCustomer,
  serversideReload: serversideReload,
  brand: brandData,
  gridView: gridView,
  lastRentalReturnOrder: lastRentalReturnOrder
});

export default rootReducer;
