import { FETCH_CART_SUCCESS, FETCH_CART_FAILURE, FETCH_CART_STARTED, FETCH_CART_ENDED, TRIGGER_CART_POPPER, FETCH_CART_SAVED_LATER_COUNT } from '@/redux/actions/cartActions';
import { HYDRATE } from 'next-redux-wrapper';
import { CartDataType } from '@/services/cart';
import { AnyAction } from 'redux';

const initialState: CartSliceDataType = {
  cart: [],
  loading: true,
  error: false,
  triggerHeaderCartPopper: false,
  savedLaterCount:{}
};

export default function cartData(state: CartSliceDataType = initialState, action: AnyAction): CartSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.cart };
    case FETCH_CART_STARTED:
      return { ...state, loading: true };
    case FETCH_CART_ENDED:
      return { ...state, loading: false };
    case FETCH_CART_SUCCESS:
      return { ...state, cart: action.payload, loading: false };
    case FETCH_CART_FAILURE:
      return { ...state, loading: false, cart: action.payload, error: true };
    case FETCH_CART_SAVED_LATER_COUNT:
      return { ...state, savedLaterCount: action.payload};
    case TRIGGER_CART_POPPER:
      return { ...state, triggerHeaderCartPopper: action.payload };
    default:
      return state;
  }
}

export type CartSliceDataType = {
  loading: boolean;
  error: boolean;
  cart: CartDataType | [];
  triggerHeaderCartPopper: boolean;
  savedLaterCount: any
};
