var Gconfig = {
    "contentSettings": {
        "desktopMenu": "menu-desktop",
        'mobileMenu': 'menu-mobile',
        "subscribedDesktopMenu": "menu-subscribed-desktop",
        "subscribedMobileMenu": "menu-subscribed-mobile",
        "productCategoryParent": "product-categories-tree"
    },
    "fpPixel": '1481434321942616',
    // "collectionAPIURL": "https://beta.thefashionpass.com/product/search/?",
    //"fpAPI": "http://fpapi.fashionpass.co/fpapi/api/v1/",
    //"fpAPI": "http://localhost:52246/api/v1/",
    // "fashionpassWaitList": "https://waitlist.thefashionpass.com/",
    "banjoReview": "https://review.thefashionpass.com/reviewhaute/",
    "fashionpassS3Storage": "https://fashionpass.s3-us-west-1.amazonaws.com/",
    "fashionpassImageStorage":"https://images.fashionpass.com/", // Getting from SIRV to switch s3 comment this line and uncommit open below code.
//    "fashionpassImageStorage":"https://fashionpass.s3-us-west-1.amazonaws.com/",
    "instagram": "https://www.instagram.com/fashionpass/",
    "facebook": "https://www.facebook.com/fashionpass/",
    "pinterest": "https://www.pinterest.com/thefashionpass/",
    "twitter": "https://twitter.com/fashionpass/",
    "tiktok": "https://www.tiktok.com/@fashionpass",
    
    "collection_handle" : {
        "clothing": "clothing",
        "accessories": "accessories"
    },
    
    "plan": [
        { price: 89, planTitle: 'Socialite', title: 'SOCIALITE', planName: 'Gold', stripeName: 'hauteandborrowed_gold', clothing: 2, accessory: 1 },
        { price: 119, planTitle: 'Trendsetter', title: 'TRENDSETTER', planName: 'Platinum', stripeName: 'hauteandborrowed_Platinum', clothing: 3, accessory: 2 },
        { price: 149, planTitle: 'Wanderlust', title: 'WANDERLUST', planName: 'Wanderlust', stripeName: 'hauteandborrowed_Wanderlust', clothing: 4, accessory: 3 }
    ],
    "giftFixedAmounts": [
        { price: 50, amountTitle: '$50.00' },
        { price: 75, amountTitle: '$75.00' },
        { price: 100, amountTitle: '$100.00' },
        { price: 200, amountTitle: '$200.00' }
    ],
    "nonRegisteredGiftCardUser": {
        name: 'test',
        stripe_id: 'test',
    },
    "webSiteTagList": [
        "Bridal",
        "Brunch",
        "Casual",
        "Date Night",
        "Festival",
        "Going Out",
        "Vacation",
        "Wedding Guest",
        "Work",
        "Black Tie",
        // "Black Tie Optional",
        "Cocktail",
        // "Closet Staples",
        // "Daytime",
        // "Formal",
        // "Night Out",
    ],
    "colorConfig": {
        "white": "#FFFFFF",
        "black": "#000000",
        "blue": "#85D2E6",
        "brown": "#B5775B",
        "gray": "#CBCBCB",
        "green": "#E2F265",
        "neutral": "#EEDFD8",
 
        "orange": "#FFAF00",
        "pink": "#FFAAD9",
        "purple": "#D7B7FF",
        "red": "#E53946",
        "yellow": "#FFEFAF"
    },

    "paterns_prints": [
        "tropical",
        "floral",
        "embellished-and-sequined",
        "plaid",
        "animal-print",
        "tie-dye-ombre",
        "stars",
        "fruit",
        "gold",
        "silver",
        "sequin",
        "striped",
        "metallic"
    ],
    "otherFilter": ["Boat Neck","Cowl Neck","Crew Neck","Halter","Mock Neck","Off The Shoulder","One Shoulder","Scoop Neck",
        "Square Neck","Strapless","Tie Front","Turtlenecks","V Neck","Sweetheart Neckline","Short Sleeve","Long Sleeve","Sleeveless","Strapless",
        "Off The Shoulder","One Shoulder","Mini", "Midi", "Maxi"],
    
    "neckLineList": ["Boat Neck","Cowl Neck","Crew Neck","Halter","Mock Neck","Off The Shoulder","One Shoulder","Scoop Neck","Square Neck","Strapless","Tie Front","Turtlenecks","V Neck","Sweetheart Neckline"],
    "sleeveList": ["Short Sleeve","Long Sleeve","Sleeveless","Strapless","Off The Shoulder","One Shoulder"],
    "lengthList":["Mini", "Midi", "Maxi"],
    "noProductAlertRedirectTime": 1500,
    
    "plansDiscountedAmount" : 0
}
Gconfig.system_settings={};
Gconfig.static_pages={};
switch(process.env.NEXT_PUBLIC_APP_ENV) {
  case 'X':
    Gconfig.contentSettings.indexSliders= process.env.NEXT_PUBLIC_INDEX_SLIDERS_IDS_X;
    Gconfig.contentSettings.addedItem1= process.env.NEXT_PUBLIC_ADDED_ITEM1_IDS_X;
    Gconfig.contentSettings.addedItem2= process.env.NEXT_PUBLIC_ADDED_ITEM2_IDS_X;
    Gconfig.contentSettings.additionalItem= process.env.NEXT_PUBLIC_ADDITIONAL_ITEM_IDS_X;
    Gconfig.contentSettings.homePageId= process.env.NEXT_PUBLIC_HOME_PAGE_IDS_X;
    Gconfig.contentSettings.howItWorksPageId= process.env.NEXT_PUBLIC_HIW_IDS_X;
    Gconfig.contentSettings.freeStuffPageId= process.env.NEXT_PUBLIC_FREESTUFF_PAGE_IDS_X;
    Gconfig.ccParent= process.env.NEXT_PUBLIC_CUSTOMER_CARE_PARENT_X;
    Gconfig.fpAPI= process.env.NEXT_PUBLIC_FPAPI_X;
    Gconfig.collectionAPI= process.env.NEXT_PUBLIC_COLLECTIONAPI_X;
    Gconfig.waitlistURL= process.env.NEXT_PUBLIC_WAITLISTURL_X ;
    Gconfig.nonRegisteredGiftCardUser.customer_id= process.env.NEXT_PUBLIC_GUEST_USER_ID_X;
    Gconfig.nonRegisteredGiftCardUser.email_id= process.env.NEXT_PUBLIC_GUEST_USER_EMAIL_X;
    Gconfig.system_settings.customerCreadit= process.env.NEXT_PUBLIC_CUSTOMER_CREDIT_IDS_X;
    Gconfig.system_settings.earlyCheckin= process.env.NEXT_PUBLIC_EARLY_CHECKIN_IDS_X;
    Gconfig.system_settings.addedItem= process.env.NEXT_PUBLIC_ADDED_ITEM1_IDS_X;
    Gconfig.system_settings.addedItem2= process.env.NEXT_PUBLIC_ADDED_ITEM2_IDS_X;
    Gconfig.system_settings.AdditionalItem= process.env.NEXT_PUBLIC_ADDITIONAL_ITEM_IDS_X;
    Gconfig.system_settings.WaitlistEnableDisable= process.env.NEXT_PUBLIC_WAITLIST_ENABLE_DISABLE_IDS_X;
    Gconfig.system_settings.sizeTogleId=process.env.NEXT_PUBLIC_SIZE_TOGLE_X;
    Gconfig.system_settings.stylistPick=process.env.NEXT_PUBLIC_STYLIST_PIC_TAG_ID_X;
    Gconfig.system_settings.bestSeller=process.env.NEXT_PUBLIC_BEST_SELLER_TAG_ID_X;
    Gconfig.system_settings.maintenanceEnabled=process.env.NEXT_PUBLIC_MAINTENANCE_ENABLED_X;
    Gconfig.system_settings.maintenanceMessage=process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE_X;
    Gconfig.system_settings.pinkbarMessageToggle=process.env.NEXT_PUBLIC_PINKBAR_MESSAGE_TOGGLE_X;
    Gconfig.system_settings.cutoffTime=process.env.NEXT_PUBLIC_CUTOFF_TIME_X;
    Gconfig.system_settings.checkinTime=process.env.NEXT_PUBLIC_CHECKIN_TIME_X;
    Gconfig.collectionCategoryAccessoriesID= process.env.NEXT_PUBLIC_COLLECTION_CATEGORY_ACCESSORIES_ID_X;
    Gconfig.collectionCategoryOccasionsID= process.env.NEXT_PUBLIC_COLLECTION_CATEGORY_OCCASION_ID_X;
    Gconfig.static_pages.termsOfService=process.env.NEXT_PUBLIC_TERMS_OF_SERVICE_ID_X;
    Gconfig.static_pages.privacyPolicy=process.env.NEXT_PUBLIC_PRIVACY_POLICY_ID_X;
    Gconfig.banjoAPI=process.env.NEXT_PUBLIC_BANJOAPI_X;
    Gconfig.banjoURL= process.env.NEXT_PUBLIC_BANJOURL_X;
    Gconfig.hautApi= process.env.NEXT_PUBLIC_HAUTEAPI_X;
    Gconfig.system_settings.winbackOfferMonths= process.env.NEXT_PUBLIC_WINBACK_OFFER_MONTHS_X;
    Gconfig.utilityAPI= process.env.NEXT_PUBLIC_UTILITYAPI_X
    Gconfig.contentSettings.faqPageID= process.env.NEXT_PUBLIC_FAQ_PAGE_X
    Gconfig.system_settings.homeImageLink = process.env.NEXT_PUBLIC_HOME_IMAGE_LINK_X;
    Gconfig.environment = process.env.NEXT_PUBLIC_ENVIRONMENT_X;
    Gconfig.system_settings.fp_exclusive = process.env.NEXT_PUBLIC_FP_EXCLUSIVE_TAG_ID_X;
    Gconfig.reviewApi = process.env.NEXT_PUBLIC_REVIEW_API_X
    Gconfig.system_settings.flash_sale = process.env.NEXT_PUBLIC_FLASH_SALE_TAG_ID_X;
    Gconfig.system_settings.dynamic_url = process.env.NEXT_PUBLIC_DYNAMIC_URL_X;
    Gconfig.system_settings.nonsub_discount_days = process.env.NEXT_PUBLIC_NONSUB_TIMELINE_DAYS_X;
    Gconfig.smbAPI= process.env.NEXT_PUBLIC_SMBAPI_X;
    Gconfig.fp_plans = process.env.NEXT_PUBLIC_FASHIONPASS_PLANS_X;
    Gconfig.system_settings.req_carrier_notes_zipcode = process.env.NEXT_PUBLIC_REQ_CARRIER_NOTES_ZIPCODE_X;
    Gconfig.fraudAPI = process.env.NEXT_PUBLIC_FRAUD_API_X;
    Gconfig.system_settings.homepageImageSetting = process.env.NEXT_PUBLIC_HOMEPAGE_HERO_IMAGE_SETTING_X;
    Gconfig.system_settings.rent_occasion = process.env.NEXT_PUBLIC_RENT_OCCASION_X;
    Gconfig.ProductGrayImageId = process.env.NEXT_PUBLIC_PRODUCT_GRAY_IMAGE_ID_X;
    Gconfig.newArrivalTagId = process.env.NEXT_PUBLIC_NEW_ARRIVAL_TAG_ID_X;
    Gconfig.system_settings.loginCaptchaRetries = process.env.NEXT_PUBLIC_LOGIN_CAPTCHA_RETRIES_X;
    Gconfig.system_settings.utmMarketingCoupenId = process.env.NEXT_PUBLIC_UTM_MARKETING_COUPENID_X;
    Gconfig.system_settings.abTestingData = process.env.NEXT_PUBLIC_AB_DATA_ID_X;
    Gconfig.returnV2OrderId = process.env.NEXT_PUBLIC_RETURN_V2_ORDERID_X;
    Gconfig.productImageUrl = Gconfig.fashionpassS3Storage+process.env.NEXT_PUBLIC_PRODUCT_IMAGE_URL_X;
    Gconfig.finalSaleTag = process.env.NEXT_PUBLIC_FINAL_SALE_TAG_ID_X;
    Gconfig.shipmentAPI= process.env.NEXT_PUBLIC_SHIPMENT_API_X;
    Gconfig.system_settings.favLeftBarDataId = process.env.NEXT_PUBLIC_FAV_LEFT_BAR_DATA_ID_X;
    Gconfig.system_settings.dynamicPromoCodeSetting = process.env.NEXT_PUBLIC_DYNAMIC_PROMO_CODE_SETTING_ID_X;
    Gconfig.system_settings.clearCacheMobileApp = process.env.NEXT_PUBLIC_CLEAR_CACHE_MOBILE_APP_X;
    Gconfig.system_settings.homeFavSliderTagId = process.env.NEXT_PUBLIC_HOME_FAV_SLIDER_TAG_ID_X;
    Gconfig.system_settings.highRatedSliderTagId = process.env.NEXT_PUBLIC_HIGH_RATED_SLIDER_TAG_ID_X;
    Gconfig.system_settings.featuredBrandSettingId = process.env.NEXT_PUBLIC_FEATURED_BRAND_SETTING_ID_X;
    Gconfig.system_settings.backInStockSliderTagId = process.env.NEXT_PUBLIC_BACK_IN_STOCK_SLIDER_TAG_ID_X;
    Gconfig.customCategoryId = process.env.NEXT_PUBLIC_CUSTOM_CATEGORY_ID_X;
    Gconfig.entityAPI = process.env.NEXT_PUBLIC_ENTITY_API_X;
    Gconfig.system_settings.trendingSearchId = process.env.NEXT_PUBLIC_TRENDING_SEARCH_ID_X;
    Gconfig.system_settings.disableSMSVerificationId = process.env.NEXT_PUBLIC_DISABLE_SMS_VERIFICATION_SETTING_ID_X;
    Gconfig.system_settings.autoHideThresholdID = process.env.NEXT_PUBLIC_AUTO_HIDE_THRESHOLD_SETTING_ID_X;
    Gconfig.clothingCategoryId = process.env.NEXT_PUBLIC_CLOTHING_CATEGORY_ID_X;
    Gconfig.accessoryCategoryId = process.env.NEXT_PUBLIC_ACCESSORY_CATEGORY_ID_X;
    Gconfig.brandCategoryId = process.env.NEXT_PUBLIC_BRANDS_CATEGORY_ID_X;
    Gconfig.smartBanjo=process.env.NEXT_PUBLIC_SMARTBANJO_X;
    Gconfig.adminEmail=process.env.NEXT_PUBLIC_ADMIN_EMAIL_X;
    break;
  case "Y":
    Gconfig.contentSettings.indexSliders= process.env.NEXT_PUBLIC_INDEX_SLIDERS_IDS_Y;
    Gconfig.contentSettings.addedItem1= process.env.NEXT_PUBLIC_ADDED_ITEM1_IDS_Y;
    Gconfig.contentSettings.addedItem2= process.env.NEXT_PUBLIC_ADDED_ITEM2_IDS_Y;
    Gconfig.contentSettings.additionalItem= process.env.NEXT_PUBLIC_ADDITIONAL_ITEM_IDS_Y;
    Gconfig.contentSettings.homePageId= process.env.NEXT_PUBLIC_HOME_PAGE_IDS_Y;
    Gconfig.contentSettings.howItWorksPageId= process.env.NEXT_PUBLIC_HIW_IDS_Y;
    Gconfig.contentSettings.freeStuffPageId= process.env.NEXT_PUBLIC_FREESTUFF_PAGE_IDS_Y;
    Gconfig.ccParent= process.env.NEXT_PUBLIC_CUSTOMER_CARE_PARENT_Y;
    Gconfig.fpAPI= process.env.NEXT_PUBLIC_FPAPI_Y;
    Gconfig.collectionAPI= process.env.NEXT_PUBLIC_COLLECTIONAPI_Y;
    Gconfig.waitlistURL= process.env.NEXT_PUBLIC_WAITLISTURL_Y ;
    Gconfig.nonRegisteredGiftCardUser.customer_id= process.env.NEXT_PUBLIC_GUEST_USER_ID_Y;
    Gconfig.nonRegisteredGiftCardUser.email_id= process.env.NEXT_PUBLIC_GUEST_USER_EMAIL_Y;
    Gconfig.system_settings.customerCreadit= process.env.NEXT_PUBLIC_CUSTOMER_CREDIT_IDS_Y;
    Gconfig.system_settings.earlyCheckin= process.env.NEXT_PUBLIC_EARLY_CHECKIN_IDS_Y;
    Gconfig.system_settings.addedItem= process.env.NEXT_PUBLIC_ADDED_ITEM1_IDS_Y;
    Gconfig.system_settings.addedItem2= process.env.NEXT_PUBLIC_ADDED_ITEM2_IDS_Y;
    Gconfig.system_settings.AdditionalItem= process.env.NEXT_PUBLIC_ADDITIONAL_ITEM_IDS_Y;
    Gconfig.system_settings.WaitlistEnableDisable= process.env.NEXT_PUBLIC_WAITLIST_ENABLE_DISABLE_IDS_Y;
    Gconfig.system_settings.sizeTogleId=process.env.NEXT_PUBLIC_SIZE_TOGLE_Y;
    Gconfig.system_settings.stylistPick=process.env.NEXT_PUBLIC_STYLIST_PIC_TAG_ID_Y;
    Gconfig.system_settings.bestSeller=process.env.NEXT_PUBLIC_BEST_SELLER_TAG_ID_Y;
    Gconfig.system_settings.maintenanceEnabled=process.env.NEXT_PUBLIC_MAINTENANCE_ENABLED_Y;
    Gconfig.system_settings.maintenanceMessage=process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE_Y;
    Gconfig.system_settings.pinkbarMessageToggle=process.env.NEXT_PUBLIC_PINKBAR_MESSAGE_TOGGLE_Y;
    Gconfig.system_settings.cutoffTime=process.env.NEXT_PUBLIC_CUTOFF_TIME_Y;
    Gconfig.system_settings.checkinTime=process.env.NEXT_PUBLIC_CHECKIN_TIME_Y;
    Gconfig.collectionCategoryAccessoriesID= process.env.NEXT_PUBLIC_COLLECTION_CATEGORY_ACCESSORIES_ID_Y;
    Gconfig.collectionCategoryOccasionsID= process.env.NEXT_PUBLIC_COLLECTION_CATEGORY_OCCASION_ID_Y;
    Gconfig.static_pages.termsOfService=process.env.NEXT_PUBLIC_TERMS_OF_SERVICE_ID_Y;
    Gconfig.static_pages.privacyPolicy=process.env.NEXT_PUBLIC_PRIVACY_POLICY_ID_Y;
    Gconfig.banjoAPI=process.env.NEXT_PUBLIC_BANJOAPI_Y;
    Gconfig.banjoURL= process.env.NEXT_PUBLIC_BANJOURL_Y;
    Gconfig.hautApi= process.env.NEXT_PUBLIC_HAUTEAPI_Y;
    Gconfig.system_settings.winbackOfferMonths= process.env.NEXT_PUBLIC_WINBACK_OFFER_MONTHS_Y;
    Gconfig.utilityAPI= process.env.NEXT_PUBLIC_UTILITYAPI_Y
    Gconfig.contentSettings.faqPageID= process.env.NEXT_PUBLIC_FAQ_PAGE_Y
    Gconfig.system_settings.homeImageLink = process.env.NEXT_PUBLIC_HOME_IMAGE_LINK_Y;
    Gconfig.environment = process.env.NEXT_PUBLIC_ENVIRONMENT_Y;
    Gconfig.system_settings.fp_exclusive = process.env.NEXT_PUBLIC_FP_EXCLUSIVE_TAG_ID_Y;
    Gconfig.reviewApi = process.env.NEXT_PUBLIC_REVIEW_API_Y
    Gconfig.system_settings.flash_sale = process.env.NEXT_PUBLIC_FLASH_SALE_TAG_ID_Y;
    Gconfig.system_settings.dynamic_url = process.env.NEXT_PUBLIC_DYNAMIC_URL_Y;
    Gconfig.system_settings.nonsub_discount_days = process.env.NEXT_PUBLIC_NONSUB_TIMELINE_DAYS_Y;
    Gconfig.smbAPI= process.env.NEXT_PUBLIC_SMBAPI_Y;
    Gconfig.fp_plans = process.env.NEXT_PUBLIC_FASHIONPASS_PLANS_Y;
    Gconfig.system_settings.req_carrier_notes_zipcode = process.env.NEXT_PUBLIC_REQ_CARRIER_NOTES_ZIPCODE_Y;
    Gconfig.fraudAPI = process.env.NEXT_PUBLIC_FRAUD_API_Y;
    Gconfig.system_settings.homepageImageSetting = process.env.NEXT_PUBLIC_HOMEPAGE_HERO_IMAGE_SETTING_Y;
    Gconfig.system_settings.rent_occasion = process.env.NEXT_PUBLIC_RENT_OCCASION_Y;
    Gconfig.ProductGrayImageId = process.env.NEXT_PUBLIC_PRODUCT_GRAY_IMAGE_ID_Y;
    Gconfig.newArrivalTagId = process.env.NEXT_PUBLIC_NEW_ARRIVAL_TAG_ID_Y;
    Gconfig.system_settings.loginCaptchaRetries = process.env.NEXT_PUBLIC_LOGIN_CAPTCHA_RETRIES_Y;
    Gconfig.system_settings.utmMarketingCoupenId = process.env.NEXT_PUBLIC_UTM_MARKETING_COUPENID_Y;
    Gconfig.system_settings.abTestingData = process.env.NEXT_PUBLIC_AB_DATA_ID_Y;
    Gconfig.returnV2OrderId = process.env.NEXT_PUBLIC_RETURN_V2_ORDERID_Y;
    Gconfig.productImageUrl = Gconfig.fashionpassS3Storage+process.env.NEXT_PUBLIC_PRODUCT_IMAGE_URL_Y;
    Gconfig.finalSaleTag = process.env.NEXT_PUBLIC_FINAL_SALE_TAG_ID_Y;
    Gconfig.shipmentAPI= process.env.NEXT_PUBLIC_SHIPMENT_API_Y;
    Gconfig.system_settings.favLeftBarDataId = process.env.NEXT_PUBLIC_FAV_LEFT_BAR_DATA_ID_Y;
    Gconfig.system_settings.dynamicPromoCodeSetting = process.env.NEXT_PUBLIC_DYNAMIC_PROMO_CODE_SETTING_ID_Y;
    Gconfig.system_settings.clearCacheMobileApp = process.env.NEXT_PUBLIC_CLEAR_CACHE_MOBILE_APP_Y;
    Gconfig.system_settings.homeFavSliderTagId = process.env.NEXT_PUBLIC_HOME_FAV_SLIDER_TAG_ID_Y;
    Gconfig.system_settings.highRatedSliderTagId = process.env.NEXT_PUBLIC_HIGH_RATED_SLIDER_TAG_ID_Y;
    Gconfig.system_settings.featuredBrandSettingId = process.env.NEXT_PUBLIC_FEATURED_BRAND_SETTING_ID_Y;
    Gconfig.system_settings.backInStockSliderTagId = process.env.NEXT_PUBLIC_BACK_IN_STOCK_SLIDER_TAG_ID_Y;
    Gconfig.customCategoryId = process.env.NEXT_PUBLIC_CUSTOM_CATEGORY_ID_Y;
    Gconfig.entityAPI = process.env.NEXT_PUBLIC_ENTITY_API_Y;
    Gconfig.system_settings.trendingSearchId = process.env.NEXT_PUBLIC_TRENDING_SEARCH_ID_Y;
    Gconfig.system_settings.disableSMSVerificationId = process.env.NEXT_PUBLIC_DISABLE_SMS_VERIFICATION_SETTING_ID_Y;
    Gconfig.system_settings.autoHideThresholdID = process.env.NEXT_PUBLIC_AUTO_HIDE_THRESHOLD_SETTING_ID_Y;
    Gconfig.clothingCategoryId = process.env.NEXT_PUBLIC_CLOTHING_CATEGORY_ID_Y;
    Gconfig.accessoryCategoryId = process.env.NEXT_PUBLIC_ACCESSORY_CATEGORY_ID_Y;
    Gconfig.brandCategoryId = process.env.NEXT_PUBLIC_BRANDS_CATEGORY_ID_Y;
    Gconfig.smartBanjo=process.env.NEXT_PUBLIC_SMARTBANJO_Y;
    Gconfig.adminEmail=process.env.NEXT_PUBLIC_ADMIN_EMAIL_Y;
    break;

  default:
    Gconfig.contentSettings.indexSliders= process.env.NEXT_PUBLIC_INDEX_SLIDERS_IDS;
    Gconfig.contentSettings.addedItem1= process.env.NEXT_PUBLIC_ADDED_ITEM1_IDS;
    Gconfig.contentSettings.addedItem2= process.env.NEXT_PUBLIC_ADDED_ITEM2_IDS;
    Gconfig.contentSettings.additionalItem= process.env.NEXT_PUBLIC_ADDITIONAL_ITEM_IDS;
    Gconfig.contentSettings.homePageId= process.env.NEXT_PUBLIC_HOME_PAGE_IDS;
    Gconfig.contentSettings.howItWorksPageId= process.env.NEXT_PUBLIC_HIW_IDS;
    Gconfig.contentSettings.freeStuffPageId= process.env.NEXT_PUBLIC_FREESTUFF_PAGE_IDS;
    Gconfig.ccParent= process.env.NEXT_PUBLIC_CUSTOMER_CARE_PARENT;
    Gconfig.fpAPI= process.env.NEXT_PUBLIC_FPAPI;
    Gconfig.collectionAPI= process.env.NEXT_PUBLIC_COLLECTIONAPI;
    Gconfig.waitlistURL= process.env.NEXT_PUBLIC_WAITLISTURL ;
    Gconfig.nonRegisteredGiftCardUser.customer_id= process.env.NEXT_PUBLIC_GUEST_USER_ID;
    Gconfig.nonRegisteredGiftCardUser.email_id= process.env.NEXT_PUBLIC_GUEST_USER_EMAIL;
    Gconfig.system_settings.customerCreadit= process.env.NEXT_PUBLIC_CUSTOMER_CREDIT_IDS;
    Gconfig.system_settings.earlyCheckin= process.env.NEXT_PUBLIC_EARLY_CHECKIN_IDS;
    Gconfig.system_settings.addedItem= process.env.NEXT_PUBLIC_ADDED_ITEM1_IDS;
    Gconfig.system_settings.addedItem2= process.env.NEXT_PUBLIC_ADDED_ITEM2_IDS;
    Gconfig.system_settings.AdditionalItem= process.env.NEXT_PUBLIC_ADDITIONAL_ITEM_IDS;
    Gconfig.system_settings.WaitlistEnableDisable= process.env.NEXT_PUBLIC_WAITLIST_ENABLE_DISABLE_IDS;
    Gconfig.system_settings.sizeTogleId=process.env.NEXT_PUBLIC_SIZE_TOGLE;
    Gconfig.system_settings.stylistPick=process.env.NEXT_PUBLIC_STYLIST_PIC_TAG_ID;
    Gconfig.system_settings.bestSeller=process.env.NEXT_PUBLIC_BEST_SELLER_TAG_ID;
    Gconfig.system_settings.maintenanceEnabled=process.env.NEXT_PUBLIC_MAINTENANCE_ENABLED;
    Gconfig.system_settings.maintenanceMessage=process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE;
    Gconfig.system_settings.pinkbarMessageToggle=process.env.NEXT_PUBLIC_PINKBAR_MESSAGE_TOGGLE;
    Gconfig.system_settings.cutoffTime=process.env.NEXT_PUBLIC_CUTOFF_TIME;
    Gconfig.system_settings.checkinTime=process.env.NEXT_PUBLIC_CHECKIN_TIME;
    Gconfig.collectionCategoryAccessoriesID= process.env.NEXT_PUBLIC_COLLECTION_CATEGORY_ACCESSORIES_ID;
    Gconfig.collectionCategoryOccasionsID= process.env.NEXT_PUBLIC_COLLECTION_CATEGORY_OCCASION_ID;
    Gconfig.static_pages.termsOfService=process.env.NEXT_PUBLIC_TERMS_OF_SERVICE_ID;
    Gconfig.static_pages.privacyPolicy=process.env.NEXT_PUBLIC_PRIVACY_POLICY_ID;
    Gconfig.banjoAPI=process.env.NEXT_PUBLIC_BANJOAPI;
    Gconfig.banjoURL= process.env.NEXT_PUBLIC_BANJOURL;
    Gconfig.hautApi= process.env.NEXT_PUBLIC_HAUTEAPI;
    Gconfig.system_settings.winbackOfferMonths= process.env.NEXT_PUBLIC_WINBACK_OFFER_MONTHS;
    Gconfig.utilityAPI= process.env.NEXT_PUBLIC_UTILITYAPI
    Gconfig.contentSettings.faqPageID= process.env.NEXT_PUBLIC_FAQ_PAGE
    Gconfig.system_settings.homeImageLink = process.env.NEXT_PUBLIC_HOME_IMAGE_LINK;
    Gconfig.environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
    Gconfig.system_settings.fp_exclusive = process.env.NEXT_PUBLIC_FP_EXCLUSIVE_TAG_ID;
    Gconfig.reviewApi = process.env.NEXT_PUBLIC_REVIEW_API
    Gconfig.system_settings.flash_sale = process.env.NEXT_PUBLIC_FLASH_SALE_TAG_ID;
    Gconfig.system_settings.dynamic_url = process.env.NEXT_PUBLIC_DYNAMIC_URL;
    Gconfig.system_settings.nonsub_discount_days = process.env.NEXT_PUBLIC_NONSUB_TIMELINE_DAYS;
    Gconfig.smbAPI= process.env.NEXT_PUBLIC_SMBAPI;
    Gconfig.fp_plans = process.env.NEXT_PUBLIC_FASHIONPASS_PLANS;
    Gconfig.system_settings.req_carrier_notes_zipcode = process.env.NEXT_PUBLIC_REQ_CARRIER_NOTES_ZIPCODE;
    Gconfig.fraudAPI = process.env.NEXT_PUBLIC_FRAUD_API;
    Gconfig.system_settings.homepageImageSetting = process.env.NEXT_PUBLIC_HOMEPAGE_HERO_IMAGE_SETTING;
    Gconfig.system_settings.rent_occasion = process.env.NEXT_PUBLIC_RENT_OCCASION;
    Gconfig.ProductGrayImageId = process.env.NEXT_PUBLIC_PRODUCT_GRAY_IMAGE_ID;
    Gconfig.newArrivalTagId = process.env.NEXT_PUBLIC_NEW_ARRIVAL_TAG_ID;
    Gconfig.system_settings.loginCaptchaRetries = process.env.NEXT_PUBLIC_LOGIN_CAPTCHA_RETRIES;
    Gconfig.system_settings.utmMarketingCoupenId = process.env.NEXT_PUBLIC_UTM_MARKETING_COUPENID;
    Gconfig.system_settings.abTestingData = process.env.NEXT_PUBLIC_AB_DATA_ID;
    Gconfig.returnV2OrderId = process.env.NEXT_PUBLIC_RETURN_V2_ORDERID;
    Gconfig.productImageUrl = Gconfig.fashionpassImageStorage+process.env.NEXT_PUBLIC_PRODUCT_IMAGE_URL;
    Gconfig.finalSaleTag = process.env.NEXT_PUBLIC_FINAL_SALE_TAG_ID;
    Gconfig.shipmentAPI= process.env.NEXT_PUBLIC_SHIPMENT_API;
    Gconfig.system_settings.favLeftBarDataId = process.env.NEXT_PUBLIC_FAV_LEFT_BAR_DATA_ID;
    Gconfig.system_settings.dynamicPromoCodeSetting = process.env.NEXT_PUBLIC_DYNAMIC_PROMO_CODE_SETTING_ID;
    Gconfig.system_settings.clearCacheMobileApp = process.env.NEXT_PUBLIC_CLEAR_CACHE_MOBILE_APP;
    Gconfig.system_settings.homeFavSliderTagId = process.env.NEXT_PUBLIC_HOME_FAV_SLIDER_TAG_ID;
    Gconfig.system_settings.highRatedSliderTagId = process.env.NEXT_PUBLIC_HIGH_RATED_SLIDER_TAG_ID;
    Gconfig.system_settings.featuredBrandSettingId = process.env.NEXT_PUBLIC_FEATURED_BRAND_SETTING_ID;
    Gconfig.system_settings.backInStockSliderTagId = process.env.NEXT_PUBLIC_BACK_IN_STOCK_SLIDER_TAG_ID;
    Gconfig.customCategoryId = process.env.NEXT_PUBLIC_CUSTOM_CATEGORY_ID;
    Gconfig.entityAPI = process.env.NEXT_PUBLIC_ENTITY_API;
    Gconfig.system_settings.trendingSearchId = process.env.NEXT_PUBLIC_TRENDING_SEARCH_ID;
    Gconfig.system_settings.disableSMSVerificationId = process.env.NEXT_PUBLIC_DISABLE_SMS_VERIFICATION_SETTING_ID;
    Gconfig.system_settings.autoHideThresholdID = process.env.NEXT_PUBLIC_AUTO_HIDE_THRESHOLD_SETTING_ID;
    Gconfig.clothingCategoryId = process.env.NEXT_PUBLIC_CLOTHING_CATEGORY_ID;
    Gconfig.accessoryCategoryId = process.env.NEXT_PUBLIC_ACCESSORY_CATEGORY_ID;
    Gconfig.brandCategoryId = process.env.NEXT_PUBLIC_BRANDS_CATEGORY_ID;
    Gconfig.smartBanjo=process.env.NEXT_PUBLIC_SMARTBANJO;
    Gconfig.adminEmail=process.env.NEXT_PUBLIC_ADMIN_EMAIL;
}
Gconfig.paterns_printsURL = Gconfig.fashionpassImageStorage + "assets/patterns/"
Gconfig.collectionAPIURL = Gconfig.collectionAPI + "collections/SearchByString/?";
Gconfig.collectionAPIHandleURL = Gconfig.collectionAPI + "collections/SearchByHandle/";
Gconfig.collectionAPIHandleURLDynamic = Gconfig.fpAPI + "collections/SearchByHandle/";
Gconfig.collectionAPIURLDynamic = Gconfig.collectionAPI + "collections/SearchByString/";
Gconfig.vendor_imageURI = Gconfig.fashionpassImageStorage + "vendor_images/";
export default Gconfig