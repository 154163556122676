import { Fp_Plans, SystemSettings } from '@/constants/global-config';
import { StoreThunk } from '@/redux/store';
import { getSystemSettingByIdList, getSystemSettingByIdList_Generic } from '@/services/website-settings';
import { fetchSystemSetttingsSuccess } from '@/redux/actions/systemSettingsActions';
import { AddNewSystemSettingsAndRemoveDuplicateOnes } from '@/functions/system-settings-functions';

export const getSystemSettings = (cache?: boolean, server?: boolean, nonSub?: boolean): StoreThunk => {
  return async (dispatch, getState) => {
    const systemSettingsData = getState().systemSettings;

    const forcefullyUseCacheCondition = true;
    let settingIDs = [SystemSettings.CUSTOMER_CREDIT, SystemSettings.EARLY_CHECKIN, SystemSettings.ADDED_ITEM, SystemSettings.ADDED_ITEM_2, SystemSettings.ADDITIONAL_ITEM, SystemSettings.WAITLIST_ENABLE_DISABLE, SystemSettings.SIZE_TOGGLE_ID, SystemSettings.PINKBAR_MESSAGE_TOGGLE, SystemSettings.WINBACK_OFFER_MONTHS, SystemSettings.HOME_IMAGE_LINK, SystemSettings.NON_SUB_DISCOUNT_DAYS, SystemSettings.DYNAMIC_URL, SystemSettings.HOMEPAGE_IMAGE_SETTING, Fp_Plans, SystemSettings.AB_DATA_ID, SystemSettings.LOGIN_CAPTCHA_RETRIES, SystemSettings.FAV_LEFT_BAR_DATA_ID, SystemSettings.CLEAR_CACHE_MOBILE_APP,SystemSettings.HOME_FAV_SLIDER_TAG_ID,SystemSettings.BACK_IN_STOCK_SLIDER_TAG_ID, SystemSettings.TRENDING_SEARCHS_ID,SystemSettings.DISABLE_SMS_VERIFICATION_ID, SystemSettings.AUTO_HIDE_THRESHOLD_ID ].join(',');
    if (server || !nonSub) settingIDs = [SystemSettings.PINKBAR_MESSAGE_TOGGLE, SystemSettings.DYNAMIC_URL, SystemSettings.NON_SUB_DISCOUNT_DAYS, SystemSettings.SIZE_TOGGLE_ID, SystemSettings.RENT_OCCASION, SystemSettings.HOMEPAGE_IMAGE_SETTING, Fp_Plans, SystemSettings.AB_DATA_ID, SystemSettings.LOGIN_CAPTCHA_RETRIES, SystemSettings.FAV_LEFT_BAR_DATA_ID, SystemSettings.CLEAR_CACHE_MOBILE_APP,SystemSettings.TRENDING_SEARCHS_ID,SystemSettings.DISABLE_SMS_VERIFICATION_ID, SystemSettings.AUTO_HIDE_THRESHOLD_ID, SystemSettings.WINBACK_OFFER_MONTHS ].join(',');

    if (cache || forcefullyUseCacheCondition) {
      const response = await getSystemSettingByIdList_Generic(settingIDs, {});
      const processedSystemSettings = AddNewSystemSettingsAndRemoveDuplicateOnes(systemSettingsData, response);
      dispatch(fetchSystemSetttingsSuccess(processedSystemSettings));
    } else {
      const response = await getSystemSettingByIdList(settingIDs, {});
      const processedSystemSettings = AddNewSystemSettingsAndRemoveDuplicateOnes(systemSettingsData, response);
      dispatch(fetchSystemSetttingsSuccess(processedSystemSettings));
    }
  };
};

export const getSystemSettingByID = (settingIds: string): StoreThunk => {
  return async (dispatch, getState) => {
    const systemSettingsData = getState().systemSettings;
    const response = await getSystemSettingByIdList_Generic(settingIds, {});
    if (response) dispatch(fetchSystemSetttingsSuccess([...systemSettingsData.systemSettings, ...response.system_settings.system_settings]));
  };
};

export const getMaintenanceSystemSettings = (): StoreThunk => {
  return async (dispatch, getState) => {
    const systemSettingsData = getState().systemSettings;

    const settingIDs = [SystemSettings.MAINTENANCE_MESSAGE, SystemSettings.MAINTENANCE_ENABLED].join(',');
    const response = await getSystemSettingByIdList(settingIDs, {});

    const processedSystemSettings = AddNewSystemSettingsAndRemoveDuplicateOnes(systemSettingsData, response);
    dispatch(fetchSystemSetttingsSuccess(processedSystemSettings));
  };
};

export const getSpecificSystemSettingByID = async (settingIds: string)  => {
    const response = await getSystemSettingByIdList_Generic(settingIds, {})
    return response;
};