import { FETCH_PRODUCT_DATA_SUCCESS, FETCH_LEFTBAR_DATA_SUCCESS, FETCH_PAGINATION_DATA_SUCCESS, FETCH_FILTER_DATA_SUCCESS, FETCH_COLLECTION_PAGE_FAILURE } from '@/redux/actions/collectionPageActions';
import { HYDRATE } from 'next-redux-wrapper';
import { CategoryDataType, GetProductsByHandleDropdownFilterSetsDataType, GetProductsByHandleResultItemDataType, GetProductsByHandleTagListsDataType, GetProductsByHandle_ResponseType } from '@/services/collections';
import { AnyAction } from 'redux';

const initialState: CollectionPageSliceDataType = {
  loading: false,
  error: false,
  productData: { data: {}, loading: false },
  leftBarData: { data: {}, loading: false },
  paginationData: { data: {}, loading: false },
  filterData: { data: {}, loading: false },
};

export default function collectionPageData(state: CollectionPageSliceDataType = initialState, action: AnyAction): CollectionPageSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.collectionPage };
    case FETCH_PRODUCT_DATA_SUCCESS:
      return { ...state, productData: { data: action.payload, loading: false }, loading: false };
    case FETCH_FILTER_DATA_SUCCESS:
      return { ...state, filterData: { data: action.payload, loading: false }, loading: false };
    case FETCH_PAGINATION_DATA_SUCCESS:
      return { ...state, paginationData: { data: action.payload, loading: false }, loading: false };
    case FETCH_LEFTBAR_DATA_SUCCESS:
      return { ...state, leftBarData: { data: action.payload, loading: false }, loading: false };
    case FETCH_COLLECTION_PAGE_FAILURE:
      // return { ...state, loading: false, collectionPage: '', error: true };
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

export type CollectionPageSliceDataType = {
  loading: boolean;
  error: boolean;
  productData: { data: CollectionPageProductDataType; loading: boolean };
  leftBarData: { data: LeftBarDataType; loading: boolean };
  paginationData: { data: PaginationDataType; loading: boolean };
  filterData: { data: filterDataType; loading: boolean };
};

export type CollectionPageProductDataType = GetProductsByHandleResultItemDataType[] | Record<string, undefined>;

export type LeftBarDataType = {
  bar?: LeftBarDataType_Bar[];
  currentCat?: LeftBarDataType_currentCat | null;
  custPart?: LeftBarDataType_currentCat | null;
  dynamicName?: string;
  finalMessageNoSizeText?: string;
  loadTime?: number;
  oldResponse?: GetProductsByHandle_ResponseType | null;
  virtual_collection?: string | null;
};
export type LeftBarDataType_Bar = {
  WebsiteText: string;
  Handle: string;
  Level: number;
  bold: boolean;
  subs: LeftBarDataType_Bar[];
  type: string;
  catID: number;
  in_stock: boolean;
};
export type LeftBarDataType_currentCat = {
  cat: CategoryDataType | null;
  parent: CategoryDataType[];
  level: number;
  type: string;
  PPrefix: string;
  ParentLine: string;
};

export type PaginationDataType = {
  current_page?: number;
  total_Items?: number;
  total_pages?: number;
  query?: string;
  filterHit?: boolean;
  filterUrlHit?: boolean;
  soldOutMessage?: string;
  clearCall?: boolean;
  noFoundClear?: boolean;
  lastResultNotFound?: boolean;
  showSoldOut?: boolean;
};
export type filterDataType = {
  sizes?: { size: string; selected: boolean; index: number }[];
  colors?: { color: string; selected: boolean; paterns: boolean }[];
  brands?: { brand: string; brand_id: string; selected: boolean }[];
  occasion?: GetProductsByHandleTagListsDataType[];
  filters?: string;
  filter?: GetProductsByHandleDropdownFilterSetsDataType[];
  sort?: {name: string, selected: boolean, index: number}[];
  season?: {name: string, selected: boolean, index: number}[];
  otherFilter?: GetProductsByHandleTagListsDataType[];
};
