import { CustomerBoardsSliceDataType } from '@/redux/reducers/customerBoardsReducer';

export const FETCH_CUSTOMER_BOARDS_SUCCESS = 'FETCH_CUSTOMER_BOARDS_SUCCESS';
export const FETCH_CUSTOMER_BOARDS_FAILURE = 'FETCH_CUSTOMER_BOARDS_FAILURE';

export const fetchCustomerBoardsSuccess = (customerBoards: CustomerBoardsSliceDataType['customerBoards']) => ({
  type: FETCH_CUSTOMER_BOARDS_SUCCESS,
  payload: customerBoards,
});

export const fetchCustomerBoardsFailure = (error: CustomerBoardsSliceDataType['error']) => ({
  type: FETCH_CUSTOMER_BOARDS_FAILURE,
  payload: error,
});
