import { FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT } from '@/redux/actions/productActions';
import { HYDRATE } from 'next-redux-wrapper';
import { ProductDataType } from '@/services/product';
import { AnyAction } from 'redux';

const initialState: ProductSliceDataType = {
  product_detail: {},
  loading: false,
  error: false,
};

function productData(state: ProductSliceDataType = initialState, action: AnyAction): ProductSliceDataType {
  switch (action.type) {
    case HYDRATE:
      if (Object.keys(state.product_detail).length > 0) return { ...state };
      else return { ...state, ...action.payload.product };
    case FETCH_PRODUCT_SUCCESS:
      return { ...state, loading: false, product_detail: action.payload };
    case FETCH_PRODUCT:
      return { ...state, loading: true };
    default:
      return state;
  }
}

export default productData;

export type ProductSliceDataType = {
  loading: boolean;
  error: boolean;
  product_detail: ProductDataType | Record<string, undefined>;
};
