import { FETCH_CUSTOMER_PREFERENCES_SUCCESS, FETCH_CUSTOMER_PREFERENCES_FAILURE } from '@/redux/actions/customerPreferencesActions';
import { HYDRATE } from 'next-redux-wrapper';
import { CustomerPreferences } from '@/services/customer';
import { AnyAction } from 'redux';

const initialState: CustomerPreferencesSliceDataType = {
  customerPreferences: {},
  loading: true,
  error: false,
};

export default function customerPreferencesData(state: CustomerPreferencesSliceDataType = initialState, action: AnyAction): CustomerPreferencesSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.customerPreferences };
    case FETCH_CUSTOMER_PREFERENCES_SUCCESS:
      return { ...state, loading: false, customerPreferences: action.payload };
    case FETCH_CUSTOMER_PREFERENCES_FAILURE:
      return { ...state, loading: false, customerPreferences: {}, error: true };
    default:
      return state;
  }
}

export type CustomerPreferencesSliceDataType = {
  loading: boolean;
  error: boolean;
  customerPreferences: CustomerPreferences | Record<string, undefined>;
};
