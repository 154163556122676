import { CustomerPreferencesSliceDataType } from '@/redux/reducers/customerPreferencesReducer';
import { CustomerPreferences } from '@/services/customer';
import { isEmpty } from '@/utils/object-methods';

export const populatePreferenceFormate = (CustomerPreferencesOldData: CustomerPreferencesSliceDataType, newPrefData: string, key: string) => {
  const processedPreferencesData: CustomerPreferences | Record<string, undefined> = CustomerPreferencesOldData.customerPreferences;
  // processedPreferencesData.preferences = {};
  if(processedPreferencesData.preferences === null || processedPreferencesData.preferences === undefined){
    processedPreferencesData.preferences = {};
  }

  let found = false;

  if (CustomerPreferencesOldData.customerPreferences.preferences !== undefined && !isEmpty(CustomerPreferencesOldData.customerPreferences.preferences)) {
    Object.keys(CustomerPreferencesOldData.customerPreferences.preferences || {}).filter((item, index) => {
      if (processedPreferencesData.preferences) {
        if (item !== key) processedPreferencesData.preferences[item] = CustomerPreferencesOldData.customerPreferences.preferences ? CustomerPreferencesOldData.customerPreferences.preferences[item] : '';
        newPrefData = newPrefData == '-1'?'':newPrefData
        if (item === key && newPrefData !== '-1') {
          processedPreferencesData.preferences[item] = newPrefData;
          found = true;
        }
      }
    });
  }

  if (newPrefData !== '-1' && !found && processedPreferencesData.preferences) processedPreferencesData.preferences[key] = newPrefData;
  return processedPreferencesData;
};
