const DISABLE_ALL_INFO_LOGS: boolean = false;
const DISABLE_ALL_WARNING_LOGS: boolean = false;
const DISABLE_ALL_ERROR_LOGS: boolean = false;

export const LogInfo = (title?: string, message?: string) => (DISABLE_ALL_INFO_LOGS ? undefined : console.log(`[INFO] ${title} - ${message}`));
export const LogWarning = (title?: string, message?: string, warning?: any) => (DISABLE_ALL_WARNING_LOGS ? undefined : console.warn('[WARNING] :', { title, message, warning }));
export const LogError = (title?: string, message?: string, error?: any) => {
  if (DISABLE_ALL_ERROR_LOGS) return;
  console.error('[ERROR] :', { title, message, error });
  throw new Error(title);
};
