import { FETCH_SHIPPING_METHODS_SUCCESS, FETCH_SHIPPING_METHODS_FAILURE } from '@/redux/actions/shippingMethodActions';
import { HYDRATE } from 'next-redux-wrapper';
import { ShippingMethodsDataType } from '@/services/customer';
import { AnyAction } from 'redux';

const initialState: ShippingMethodsSliceDataType = {
  shippingMethods: [],
  loading: false,
  error: false,
};

export default function shippingMethods(state: ShippingMethodsSliceDataType = initialState, action: AnyAction): ShippingMethodsSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.shippingMethods };
    case FETCH_SHIPPING_METHODS_SUCCESS:
      return { ...state, loading: false, shippingMethods: action.payload };
    case FETCH_SHIPPING_METHODS_FAILURE:
      return { ...state, loading: false, shippingMethods: [], error: true };
    default:
      return state;
  }
}

export type ShippingMethodsSliceDataType = {
  loading: boolean;
  error: boolean;
  shippingMethods: ShippingMethodsDataType[];
};
