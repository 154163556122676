import { FETCH_SPOTS_SUCCESS } from '@/redux/actions/spotsActions';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';

const initialState: SpotsSliceDataType = {
  spots: { total: 9, socialite: 6, trensetter: 1, wonderlust: 2 },
  loading: false,
  error: false,
};

export default function customerData(state: SpotsSliceDataType = initialState, action: AnyAction): SpotsSliceDataType {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.spotsReducer };
    case FETCH_SPOTS_SUCCESS:
      return { ...state, spots: action.payload, loading: false };
    default:
      return state;
  }
}

export type SpotsSliceDataType = {
  loading: boolean;
  error: boolean;
  spots: SpotsDataType;
};
export type SpotsDataType = {
  total: number;
  socialite: number;
  trensetter: number;
  wonderlust: number;
};
