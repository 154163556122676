import { CollectionPageProductDataType, CollectionPageSliceDataType, filterDataType, LeftBarDataType, PaginationDataType } from '@/redux/reducers/collectionPageReducer';

export const FETCH_PRODUCT_DATA_SUCCESS = 'FETCH_PRODUCT_DATA_SUCCESS';
export const FETCH_COLLECTION_PAGE_FAILURE = 'FETCH_COLLECTION_PAGE_FAILURE';
export const FETCH_LEFTBAR_DATA_SUCCESS = 'FETCH_LEFTBAR_DATA_SUCCESS';
export const FETCH_FILTER_DATA_SUCCESS = 'FETCH_FILTER_DATA_SUCCESS';
export const FETCH_PAGINATION_DATA_SUCCESS = 'FETCH_PAGINATION_DATA_SUCCESS';

export const fetchProductDataSuccess = (productData: CollectionPageProductDataType) => ({
  type: FETCH_PRODUCT_DATA_SUCCESS,
  payload: productData,
});

export const fetchFilterDataSuccess = (filterData: filterDataType) => ({
  type: FETCH_FILTER_DATA_SUCCESS,
  payload: filterData,
});

export const fetchPaginationDataSuccess = (productData: PaginationDataType) => ({
  type: FETCH_PAGINATION_DATA_SUCCESS,
  payload: productData,
});

export const fetchLeftBarDataSuccess = (leftBarData: LeftBarDataType) => ({
  type: FETCH_LEFTBAR_DATA_SUCCESS,
  payload: leftBarData,
});

export const fetchCollectionPageFailure = (error: CollectionPageSliceDataType['error']) => ({
  type: FETCH_COLLECTION_PAGE_FAILURE,
  //   payload: collectionPage,
});
