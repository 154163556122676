import React from 'react';
// import ReactDOM from 'react-dom';
import fetch from 'isomorphic-unfetch';
import { updateCustomerTags } from './globalFunctions';
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import { fetchCustomerSuccess } from "redux/actions/customerActions"
import Gconfig from "globalconfig"
import moment from 'moment'
import jsCookie from 'js-cookie';


function proceed_pause(data) {
    // let customer_ip = jsCookie.get('customer_ip')
    // return fetch(Gconfig.banjoAPI + 'Customer_data_endpoint/paused_servey/?email=' + data.email + '&nps_value=' + data.nps_value + '&survey_ids=' + data.survey_ids + '&other_text=' + data.other_text + '&additional_coments=' + data.additional_coments + '&not_enough_items_text=' + data.not_enough_items_text+ '&checkedOffer=' + data.checkedOffer+ '&offerAccepted=' + data.offerAccepted + '&winbackamount=' + data.winbackamount  +'&nextBilling='+ data.nextBilling + '&beforeBilling='+ data.beforeBilling, {
    //     method: 'GET',
    //     // headers: { 'customer_ip': customer_ip }
    // }).then(response => response.json()).then(resData => {
    //     return resData
    // })

    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    
    // return fetch(Gconfig.fpAPI + 'customer/paused_survey', {
        return fetch(Gconfig.banjoAPI + 'Customer_data_endpoint/paused_servey', {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip},
        // headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip},
        body: JSON.stringify(data),
        // headers: { 'customer_ip': customer_ip }
    }).then(response => response.json()).then(resData => {
        console.log('pause survey sending obj' , data , 'pause survey responce obj' , resData)
        return resData
    })
}
function sentUserCreditEmail(email) {
    // let customer_ip = jsCookie.get('customer_ip')
    var obj = {"subject":'Winback worked for '+email,"email":email};
    return fetch(Gconfig.banjoAPI + 'Customer_data_endpoint/Send_Email_UserCredit', {
        method: 'POST',
        // headers: { 'customer_ip': customer_ip },
        body: JSON.stringify(obj)
    }).then(() => {
        // createEventZendDesk(email, 'Customer selected winback', 'Customer selected winback', '')
    })
}
function getPauseInformation(email) {
    return fetch(Gconfig.banjoAPI + 'Customer_data_endpoint/get_pause_information/?email=' + email)
        .then(response => response.json()).then(data => {
            return data
        })
}

function insertPauseInformation(elligible_date, email) {
    let currentDate = moment.utc(Date.now()).format('YYYY-MM-DD')
    let elligibleDate = moment.utc(elligible_date).format('YYYY-MM-DD')
    return fetch(Gconfig.banjoAPI + 'Customer_data_endpoint/insert_pause_information/?cust_email=' + email + '&current_date=' + currentDate + '&elligible_date=' + elligibleDate)
        .then(response => response.text()).then(data => {
            return data
        })
}

function getBillingInfo(billingEndDate) {
    let day, date;
    day = moment.utc(billingEndDate).format('dddd')
    date = moment.utc(billingEndDate).format('M/D/YYYY')
    return day + ' ' + date
}

function getPreviousBillingDate(billingEndDate) {
    let day, date;
    day = moment.utc(billingEndDate).subtract(1, 'days').format('dddd')
    date = moment.utc(billingEndDate).subtract(1, 'days').format('M/D/YYYY')
    return day + ' ' + date
}

function updateTags(add, remove) {
    return async (dispatch, getstate) => {
        return updateCustomerTags(add, remove).then(response => response.json()).then(data => {
            if (data.success == true) {
                let customer = getstate().customer
                customer.customer.tag_list = data.tags;
                // console.log('Customer tags updated after -------->>>>>>>>',data.tags)
                dispatch(fetchCustomerSuccess(customer.customer))
                return data
            } else {
                return data
            }
        }).catch((error) => {
            console.warn(error)
            return {success:false}
        })
    }
}

function getEGiftCodeCash(coupon, cus_id) {
    // let customer_ip = jsCookie.get('customer_ip')
    return async (dispatch, getstate) => {
        return fetch(Gconfig.banjoAPI + 'Ajaxactions/get_gift_coupon/', {
            method: 'POST',
            // headers: { 'customer_ip': customer_ip },
            body: JSON.stringify({
                "coupon": coupon
            })
        }).then(response => response.json()).then(res => {
            if (res != 0) {
                let obj = {
                    customer_id: cus_id,
                    amount: res.gift_value,
                    coupon: res.coupon_id
                }
                return fetch(Gconfig.banjoAPI + 'Customers_interaction/AddGiftCardCredit', {
                    method: 'POST',
                    // headers: { 'customer_ip': customer_ip },
                    body: JSON.stringify(obj)
                }).then(resData => resData.json()).then(data => {
                    if (data.status == 'success') {
                        let customer = getstate().customer.customer
                        customer.customer_points += parseFloat(res.gift_value)
                        dispatch(fetchCustomerSuccess(customer))
                        return res
                    } else {
                        return res
                    }
                })
            } else {
                return res
            }
        })
    }
}

// function retry_checkin(lastOrderId) {
//     return fetch(Gconfig.banjoAPI + 'ping/checkin_auto_cron?orderid=' + lastOrderId + '&orders_auto_checkin=0&is_early=false&orderpage=1')
//     .then(response => response.json()).then(data => {
//         return data
//     })
// }

function retry_checkin(lastOrderId,isEarlyCheckin) {
    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let dataObj = {
        'ordername': lastOrderId,
        "sendemail": true,
        "isearly":isEarlyCheckin,
        "isCustomer":true
    }
    return fetch(Gconfig.shipmentAPI + 'checkin/SingleCheckIn',{
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip, 'guid': request_guid },
        body: JSON.stringify(dataObj)
    }).then(response => response.json()).then(data => {
        return data
    })
}

function retry_payment(dataObj) {
    // let customer_ip = jsCookie.get('customer_ip')
    // return fetch(Gconfig.banjoAPI + 'interactions/pay_failed_invoices_with_late_fee', {
    return fetch(Gconfig.fpAPI  + 'customer/ReconcilePaymentFailed', {    
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        // headers: { 'customer_ip': customer_ip },
        body: JSON.stringify(dataObj)
    }).then(response => response.json()).then(data => {
        console.log('ReconcilePaymentFailed send', dataObj)
        console.log('ReconcilePaymentFailed recieve', data)
        return data
    })
}

function AddWinbackAttemptInfo(dataObj) {
    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'reward/InsertWinbackAttempt', {
        method: 'POST',
        body: JSON.stringify(dataObj),
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip, 'guid': request_guid },
    }).then(response => response.json()).then(data => {
        return data
    })
}

function UpdateWinbackAttemptInfo(dataObj) {
    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'reward/UpdateWinbackAttempt', {
        method: 'POST',
        body: JSON.stringify(dataObj),
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip, 'guid': request_guid },
    }).then(response => response.json()).then(data => {
        return data
    })
}

function getWinbackAttemptInfo(customerID){
    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + `reward/GetWinbackAttemptByCustomerID/${customerID}`, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip, 'guid': request_guid}
    }).then(response => response.json()).then(data => {
        // console.log('data  ===> ' , data)
        return data
    })
}

function deleteWinbackAttemptInfo(attemptID){
    let token = getAuthTokenfromcookie()
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + `reward/DeleteWinbackAttempt/${attemptID}`, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token,'X-Request-For':customer_ip, 'guid': request_guid}
    }).then(response => response.json()).then(data => {
        return data
    })
}

function getCustomerPerorderSpend(StripeID){
    return fetch(Gconfig.utilityAPI + `stripe/getcustomerspend?stripe_id=${StripeID}`, {
        method: 'GET',
    }).then(response => response.json()).then(data => {
        // console.log('data  ===> ' , data)
        return data
    })
}

function updateWinbackCredit(winbackAmount) {
    return async (dispatch, getstate) => {
        let customer = getstate().customer
        customer.customer.winback_credit = winbackAmount;
        dispatch(fetchCustomerSuccess(customer.customer))
    }
}

function getPauseSurveyQuestions() {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    if (customer_token != undefined) {
        return fetch(Gconfig.fpAPI + `Customer/GetSurveylistData`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, },
        }).then(response => response.json()).then(async data => {
            return data
        })
    }
}
function updateAttributesLocally(updatedAttributes) {
    return async (dispatch, getstate) => {
        let customer = getstate().customer
        customer.customer.attributes.attributes = updatedAttributes;
        dispatch(fetchCustomerSuccess(customer.customer))
    }
}

function getSurveyQuestionsBySurveyId(id) {
    let token = getAuthTokenfromcookie()
    return fetch(Gconfig.smbAPI + `Customer/GetSurveyQuestionWithDetail?survey_type_id=${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    }).then(async (response) => await response.json()).then(data => {
        return data;
    }).catch(err => {
        console.log(err)
    })
}

function addEditSurveyResponse(data) {
    let token = getAuthTokenfromcookie()
    return fetch(`${Gconfig.fpAPI}Customer/AddEditSurveyResponse`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(data)
    }).then(async (response) => await response.json()).then(data => {
        return data;
    }).catch(err => {
        console.log(err)
    })
}

const getAllSurveyTypes= async () => {
    let token = getAuthTokenfromcookie()
    return fetch(Gconfig.smbAPI + `Customer/GetSurveyTypeById?surveyTypeId=0&statusType=All`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    }).then(async (response) => await response.json()).then(data => {
        return data;
    }).catch(err => {
        console.log(err)
    })
}
    
export {
    proceed_pause, getPauseInformation, insertPauseInformation, getBillingInfo,
    getPreviousBillingDate, updateTags, getEGiftCodeCash, retry_checkin, retry_payment,sentUserCreditEmail, 
    getWinbackAttemptInfo, UpdateWinbackAttemptInfo, AddWinbackAttemptInfo, deleteWinbackAttemptInfo, getCustomerPerorderSpend, updateWinbackCredit,
    getPauseSurveyQuestions, updateAttributesLocally, getSurveyQuestionsBySurveyId, addEditSurveyResponse, getAllSurveyTypes

}