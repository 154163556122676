import { ShippingMethodsSliceDataType } from '@/redux/reducers/shippingMethodReducer';

export const FETCH_SHIPPING_METHODS_SUCCESS = 'FETCH_SHIPPING_METHODS_SUCCESS';
export const FETCH_SHIPPING_METHODS_FAILURE = 'FETCH_SHIPPING_METHODS_FAILURE';

export const fetchShippingMethodSuccess = (methods: ShippingMethodsSliceDataType['shippingMethods']) => ({
  type: FETCH_SHIPPING_METHODS_SUCCESS,
  payload: methods,
});

export const fetchShippingMethodFailure = (error: ShippingMethodsSliceDataType['error']) => ({
  type: FETCH_SHIPPING_METHODS_FAILURE,
  payload: error,
});
